import React, { useState, useRef } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from './AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';
import LanguageDropdown from '../LanguageDropdown';
import defaultExplanations from './explanations';

import { useWhileLoop } from './useWhileLoop';

const WhileLoop = ({ language, subTopicId }) => {
  const [speakingLang, setSpeakingLang] = useState("en-US");
  const [translatedExplanations, setTranslatedExplanations] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [count, setCount] = useState(0);
  const [maxCount, setMaxCount] = useState(5);

  const [inputCount, setInputCount] = useState(count);
  const [inputMaxCount, setInputMaxCount] = useState(maxCount);

  const [breakpoints, setBreakpoints] = useState([]);

  const editorCardRef = useRef(null);

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runWhileLoop
  } = useWhileLoop(
    speakingLang,
    translatedExplanations,
    language,
    count,
    maxCount,
    breakpoints
  );

  const handleUpdateVariables = () => {
    setCount(inputCount);
    setMaxCount(inputMaxCount);

    updateVariables(inputCount, inputMaxCount);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6" ref={editorCardRef}>
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"While Loop Example"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            isRunning={isRunning}
            breakpoints={breakpoints}
            setBreakpoints={setBreakpoints}
            onOpenModal={() => {
              setInputCount(count);
              setInputMaxCount(maxCount);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <div className="d-flex justify-content-center position-relative">
              <h3 className="section-title text-center flex-grow-1">Canvas</h3>
              <LanguageDropdown
                onLanguageChange={setSpeakingLang}
                onTranslate={setTranslatedExplanations}
                selectedProgrammingLang={language}
                defaultExplanations={defaultExplanations}
                isRunning={isRunning} 
                setIsTranslating={setIsTranslating} 
              />
            </div>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runWhileLoop}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
              isTranslating={isTranslating}
              editorCardRef={editorCardRef}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        count={inputCount}
        maxCount={inputMaxCount}
        setCount={setInputCount}
        setMaxCount={setInputMaxCount}
        onSave={handleUpdateVariables}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default WhileLoop;

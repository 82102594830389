import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        The Bubble Sort algorithm repeatedly compares adjacent elements in a list and swaps them if they are in the wrong order. The process is repeated until the list is sorted:
        <ul style={{paddingLeft: '15px'}}>
            <li>
                Outer loop runs for the length of the list.
            </li>
            <li>
                Inner loop compares adjacent elements and swaps them if necessary, <b>bubbling</b> the largest unsorted element to the end of the list in each iteration.
            </li>
            <li>
                The process continues, progressively sorting the list by reducing the range of the inner loop
            </li>
        </ul>
      </div>
      <div id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}><span>N<sup>2</sup></span></span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Education:</b> Commonly used to teach sorting algorithms and understand algorithmic complexity.</li>
            <li><b>Small Datasets:</b> Sorting small or nearly sorted datasets where efficiency is less critical.</li>
            <li><b>Visual Demonstrations:</b> Bubble Sort is popular for visualizing sorting algorithms due to its simplicity and step-by-step comparisons.</li>
            <li><b>Data Cleaning:</b> Rearrange small data sets or enforce order in simple applications where performance is not a concern.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

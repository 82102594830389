// Helper function to generate memory addresses (for visualization)
function generateMemoryAddress() {
    return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
}

class BinarySearchTreeNode {
    constructor(data, address) {
      this.data = data;
      this.left = null;
      this.right = null;
      this.address = address;
    }
}
  
class BinarySearchTreeForPython {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    ) => {
        await highlightLine(10);
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        setHighlightedVariables(['data']);
        await customSleep(10);
        setHighlightedVariables([]);

        await highlightLine(11);
        await customSleep(11);

        await highlightSecondCallingLine(11);
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinarySearchTreeNode(data, nodeAddress);
        await highlightLine(1);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        setHighlightedSingleNodeVariables([`${newNode.address}`]);
        await customSleep(1);
        setHighlightedSingleNodeVariables([]);

        await highlightLine(2);
        setHighlightedVariables(['data']);
        setHighlightSingleNodeDataPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(2);
        setHighlightedVariables([]);
        setHighlightSingleNodeDataPart([]);

        await highlightLine(3);
        setHighlightSingleNodeLeftPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(3);
        setHighlightSingleNodeLeftPart([]);

        await highlightLine(4);
        setHighlightSingleNodeRightPart([`${newNode.address}`]);
        await customSleep(4, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: 'None'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: 'None'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        setHighlightedNodeVariables([`${newNode.address}`]);
        await highlightLine(11, "Returning from the Node constructor method.");
        await customSleep(11);
        setHighlightSingleNodeRightPart([]);
        setHighlightedNodeVariables([]);
    
        await highlightLine(12);
        setHighlightedRootVariables(['root']);
        await customSleep(12);
        setHighlightedRootVariables([]);
        if (this.root === null) {
            await highlightLine(13);
            setHighlightedRootVariables(['root']);
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'None' }
            }));
            setHighlightNodeAddress([`${newNode.address}`]);

            await customSleep(null,1200);
            // Delete single node variable
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));
            await customSleep(13);
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            await highlightLine(14);
            await customSleep(14);
            
            await highlightLine(15);
            let current = this.root;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current.address}
            }));
            setHighlightedRootVariables(['current']);
            await customSleep(15);
            setHighlightedRootVariables([]);

            while (true) {
                await highlightLine(16);
                await customSleep(16);

                await highlightLine(17);
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                setHighlightedRootVariables(['parent']);
                await customSleep(17);
                setHighlightedRootVariables([]);

                await highlightLine(18);
                setHighlightedVariables(['data']);
                setHighlightedRootVariables(['current']);
                setHighlightNodeAddress([`${current.address}`]);
                setHighlightDataPart([`${current.address}`]);
                await customSleep(18);
                setHighlightedVariables([]);
                setHighlightedRootVariables([]);
                setHighlightNodeAddress([]);
                setHighlightDataPart([]);
                if (data < current.data) {
                    await highlightLine(19);
                    setHighlightedRootVariables(['current']);
                    setHighlightLeftPart([`${current.address}`]);
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'None'
                        }
                    }));
                    await customSleep(19);
                    setHighlightedRootVariables([]);
                    setHighlightLeftPart([]);

                    await highlightLine(20);
                    setHighlightedRootVariables(['current']);
                    await customSleep(20);
                    setHighlightedRootVariables([]);
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        await highlightLine(21);
                        setHighlightedRootVariables(['parent']);
                        setHighlightLeftPart([`${parent.address}`]);
                        parent.left = newNode;
                        setHighlightNodeAddress([`${newNode.address}`]);
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'None'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        await customSleep(21);
                        setHighlightLeftPart();
                        setHighlightNodeAddress([]);
                        setHighlightedRootVariables([]);

                        await highlightLine(22);
                        await customSleep(22);

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    await highlightLine(23);
                    await customSleep(23);

                    await highlightLine(24);
                    setHighlightedRootVariables(['current']);
                    setHighlightRightPart([`${current.address}`]);
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'None'
                        }
                    }));
                    await customSleep(24);
                    setHighlightedRootVariables([]);
                    setHighlightRightPart([]);

                    await highlightLine(25);
                    setHighlightedRootVariables(['current']);
                    await customSleep(25);
                    setHighlightedRootVariables([]);
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        await highlightLine(26);
                        setHighlightedRootVariables(['parent']);
                        setHighlightRightPart([`${parent.address}`]);
                        setHighlightNodeAddress([`${newNode.address}`]);
                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'None', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        await customSleep(26);
                        setHighlightRightPart([]);
                        setHighlightNodeAddress([]);
                        setHighlightedRootVariables([]);

                        await highlightLine(27);
                        await customSleep(27);

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };

}

export const binarySearchTreePython = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables,
    setHighlightedVariables,
    setHighlightedRootVariables,
    setHighlightedNodeVariables,
    setHighlightedSingleNodeVariables,
    setHighlightSingleNodeLeftPart,
    setHighlightSingleNodeDataPart,
    setHighlightSingleNodeRightPart,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  ) => {
    let binarySearchTree = new BinarySearchTreeForPython();
  
    await highlightLine(31);
    await customSleep(31);

    await highlightCallingLine(31);
    await binarySearchTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(31, "Returning from the add method.");
    await customSleep(31);
    
    await highlightLine(32);
    await customSleep(32);

    await highlightCallingLine(32);
    await binarySearchTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(32, "Returning from the add method.");
    await customSleep(32);
    
    await highlightLine(33);
    await customSleep(33);

    await highlightCallingLine(33);
    await binarySearchTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(33, "Returning from the add method.");
    await customSleep(33);

    await highlightLine(34);
    await customSleep(34);

    await highlightCallingLine(34);
    await binarySearchTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(34, "Returning from the add method.");
    await customSleep(34);

    await highlightLine(35);
    await customSleep(35);

    await highlightCallingLine(35);
    await binarySearchTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(35, "Returning from the add method.");
    await customSleep(35);

    await highlightLine(36);
    await customSleep(36);

    await highlightCallingLine(36);
    await binarySearchTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(36, "Returning from the add method.");
    await customSleep(36);

    await highlightLine(37);
    await customSleep(37);

    await highlightCallingLine(37);
    await binarySearchTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(37, "Returning from the add method.");
    await customSleep(37);
};


class BinarySearchTreeForJava {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    ) => {
        await highlightLine(17);
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        setHighlightedVariables(['data']);
        await customSleep(17);
        setHighlightedVariables([]);

        await highlightLine(18);
        await customSleep(18);

        await highlightSecondCallingLine(18);
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinarySearchTreeNode(data, nodeAddress);
        await highlightLine(4);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        setHighlightedSingleNodeVariables([`${newNode.address}`]);
        await customSleep(4);
        setHighlightedSingleNodeVariables([]);

        await highlightLine(5);
        setHighlightedVariables(['data']);
        setHighlightSingleNodeDataPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(5);
        setHighlightedVariables([]);
        setHighlightSingleNodeDataPart([]);

        await highlightLine(6);
        setHighlightSingleNodeLeftPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep();
        setHighlightSingleNodeLeftPart([]);

        setHighlightSingleNodeRightPart([`${newNode.address}`]);
        await customSleep(6, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: 'null'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: 'null'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        setHighlightedNodeVariables([`${newNode.address}`]);
        await highlightLine(18, "Returning from the Node constructor method.");
        await customSleep(18);
        setHighlightSingleNodeRightPart([]);
        setHighlightedNodeVariables([]);
    
        await highlightLine(19);
        setHighlightedRootVariables(['root']);
        await customSleep(19);
        setHighlightedRootVariables([]);
        if (this.root === null) {
            await highlightLine(20);
            setHighlightedRootVariables(['root']);
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'null' }
            }));
            setHighlightNodeAddress([`${newNode.address}`]);

            await customSleep(null, 1200);
            // Delete single node variable
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));
            await customSleep(20);
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            await highlightLine(21);
            await customSleep(21);
            
            await highlightLine(22);
            let current = this.root;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current.address}
            }));
            setHighlightedRootVariables(['current']);
            await customSleep(22);
            setHighlightedRootVariables([]);

            while (true) {
                await highlightLine(23);
                await customSleep(23);

                await highlightLine(24);
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                setHighlightedRootVariables(['parent']);
                await customSleep(24);
                setHighlightedRootVariables([]);

                await highlightLine(25);
                setHighlightedVariables(['data']);
                setHighlightedRootVariables(['current']);
                setHighlightNodeAddress([`${current.address}`]);
                setHighlightDataPart([`${current.address}`]);
                await customSleep(25);
                setHighlightedVariables([]);
                setHighlightedRootVariables([]);
                setHighlightNodeAddress([]);
                setHighlightDataPart([]);
                if (data < current.data) {
                    await highlightLine(26);
                    setHighlightedRootVariables(['current']);
                    setHighlightLeftPart([`${current.address}`]);
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'null'
                        }
                    }));
                    await customSleep(26);
                    setHighlightedRootVariables([]);
                    setHighlightLeftPart([]);

                    await highlightLine(27);
                    setHighlightedRootVariables(['current']);
                    await customSleep(27);
                    setHighlightedRootVariables([]);
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        await highlightLine(28);
                        setHighlightedRootVariables(['parent']);
                        setHighlightLeftPart([`${parent.address}`]);
                        parent.left = newNode;
                        setHighlightNodeAddress([`${newNode.address}`]);
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'null'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        await customSleep(28);
                        setHighlightLeftPart();
                        setHighlightNodeAddress([]);
                        setHighlightedRootVariables([]);

                        await highlightLine(29);
                        await customSleep(29);

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    await highlightLine(31);
                    await customSleep(31);

                    await highlightLine(32);
                    setHighlightedRootVariables(['current']);
                    setHighlightRightPart([`${current.address}`]);
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'null'
                        }
                    }));
                    await customSleep(32);
                    setHighlightedRootVariables([]);
                    setHighlightRightPart([]);

                    await highlightLine(33);
                    setHighlightedRootVariables(['current']);
                    await customSleep(33);
                    setHighlightedRootVariables([]);
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        await highlightLine(34);
                        setHighlightedRootVariables(['parent']);
                        setHighlightRightPart([`${parent.address}`]);
                        setHighlightNodeAddress([`${newNode.address}`]);
                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'null', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        await customSleep(34);
                        setHighlightRightPart([]);
                        setHighlightNodeAddress([]);
                        setHighlightedRootVariables([]);

                        await highlightLine(35);
                        await customSleep(35);

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };

}

export const binarySearchTreeJava = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables,
    setHighlightedVariables,
    setHighlightedRootVariables,
    setHighlightedNodeVariables,
    setHighlightedSingleNodeVariables,
    setHighlightSingleNodeLeftPart,
    setHighlightSingleNodeDataPart,
    setHighlightSingleNodeRightPart,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  ) => {
    let binarySearchTree = new BinarySearchTreeForJava();
  
    await highlightLine(46);
    await customSleep(46);

    await highlightCallingLine(46);
    await binarySearchTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(46, "Returning from the add method.");
    await customSleep(46);
    
    await highlightLine(47);
    await customSleep(47);

    await highlightCallingLine(47);
    await binarySearchTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(47, "Returning from the add method.");
    await customSleep(47);
    
    await highlightLine(48);
    await customSleep(48);

    await highlightCallingLine(48);
    await binarySearchTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(48, "Returning from the add method.");
    await customSleep(48);

    await highlightLine(49);
    await customSleep(49);

    await highlightCallingLine(49);
    await binarySearchTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(49, "Returning from the add method.");
    await customSleep(49);

    await highlightLine(50);
    await customSleep(50);

    await highlightCallingLine(50);
    await binarySearchTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(50, "Returning from the add method.");
    await customSleep(50);

    await highlightLine(51);
    await customSleep(51);

    await highlightCallingLine(51);
    await binarySearchTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(51, "Returning from the add method.");
    await customSleep(51);

    await highlightLine(52);
    await customSleep(52);

    await highlightCallingLine(52);
    await binarySearchTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(52, "Returning from the add method.");
    await customSleep(52);
};


class BinarySearchTreeForCpp {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    ) => {
        await highlightLine(20);
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        setHighlightedVariables(['data']);
        await customSleep(20);
        setHighlightedVariables([]);

        await highlightLine(21);
        await customSleep(21);

        await highlightSecondCallingLine(21);
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinarySearchTreeNode(data, nodeAddress);
        await highlightLine(7);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        setHighlightedSingleNodeVariables([`${newNode.address}`]);
        await customSleep(7);
        setHighlightedSingleNodeVariables([]);

        await highlightLine(8);
        setHighlightedVariables(['data']);
        setHighlightSingleNodeDataPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(8);
        setHighlightedVariables([]);
        setHighlightSingleNodeDataPart([]);

        await highlightLine(9);
        setHighlightSingleNodeLeftPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep();
        setHighlightSingleNodeLeftPart([]);

        setHighlightSingleNodeRightPart([`${newNode.address}`]);
        await customSleep(9, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        setHighlightedNodeVariables([`${newNode.address}`]);
        await highlightLine(21, "Returning from the Node constructor method.");
        await customSleep(21);
        setHighlightSingleNodeRightPart([]);
        setHighlightedNodeVariables([]);
    
        await highlightLine(22);
        setHighlightedRootVariables(['root']);
        await customSleep(22);
        setHighlightedRootVariables([]);
        if (this.root === null) {
            await highlightLine(23);
            setHighlightedRootVariables(['root']);
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'nullptr' }
            }));
            setHighlightNodeAddress([`${newNode.address}`]);

            await customSleep(null, 1200);
            // Delete single node variable
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));
            await customSleep(23);
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            await highlightLine(24);
            await customSleep(24);
            
            await highlightLine(25);
            let current = this.root;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current.address}
            }));
            setHighlightedRootVariables(['current']);
            await customSleep(25);
            setHighlightedRootVariables([]);

            while (true) {
                await highlightLine(26);
                await customSleep(26);

                await highlightLine(27);
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                setHighlightedRootVariables(['parent']);
                await customSleep(27);
                setHighlightedRootVariables([]);

                await highlightLine(28);
                setHighlightedVariables(['data']);
                setHighlightedRootVariables(['current']);
                setHighlightNodeAddress([`${current.address}`]);
                setHighlightDataPart([`${current.address}`]);
                await customSleep(28);
                setHighlightedVariables([]);
                setHighlightedRootVariables([]);
                setHighlightNodeAddress([]);
                setHighlightDataPart([]);
                if (data < current.data) {
                    await highlightLine(29);
                    setHighlightedRootVariables(['current']);
                    setHighlightLeftPart([`${current.address}`]);
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'nullptr'
                        }
                    }));
                    await customSleep(29);
                    setHighlightedRootVariables([]);
                    setHighlightLeftPart([]);

                    await highlightLine(30);
                    setHighlightedRootVariables(['current']);
                    await customSleep(30);
                    setHighlightedRootVariables([]);
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        await highlightLine(31);
                        setHighlightedRootVariables(['parent']);
                        setHighlightLeftPart([`${parent.address}`]);
                        parent.left = newNode;
                        setHighlightNodeAddress([`${newNode.address}`]);
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'nullptr'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        await customSleep(31);
                        setHighlightLeftPart();
                        setHighlightNodeAddress([]);
                        setHighlightedRootVariables([]);

                        await highlightLine(32);
                        await customSleep(32);

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    await highlightLine(34);
                    await customSleep(34);

                    await highlightLine(35);
                    setHighlightedRootVariables(['current']);
                    setHighlightRightPart([`${current.address}`]);
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'nullptr'
                        }
                    }));
                    await customSleep(35);
                    setHighlightedRootVariables([]);
                    setHighlightRightPart([]);

                    await highlightLine(36);
                    setHighlightedRootVariables(['current']);
                    await customSleep(36);
                    setHighlightedRootVariables([]);
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        await highlightLine(37);
                        setHighlightedRootVariables(['parent']);
                        setHighlightRightPart([`${parent.address}`]);
                        setHighlightNodeAddress([`${newNode.address}`]);
                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'nullptr', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        await customSleep(37);
                        setHighlightRightPart([]);
                        setHighlightNodeAddress([]);
                        setHighlightedRootVariables([]);

                        await highlightLine(38);
                        await customSleep(38);

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };

}

export const binarySearchTreeCpp = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables,
    setHighlightedVariables,
    setHighlightedRootVariables,
    setHighlightedNodeVariables,
    setHighlightedSingleNodeVariables,
    setHighlightSingleNodeLeftPart,
    setHighlightSingleNodeDataPart,
    setHighlightSingleNodeRightPart,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  ) => {
    let binarySearchTree = new BinarySearchTreeForCpp();
  
    await highlightLine(48);
    await customSleep(48);

    await highlightCallingLine(48);
    await binarySearchTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(48, "Returning from the add method.");
    await customSleep(48);
    
    await highlightLine(49);
    await customSleep(49);

    await highlightCallingLine(49);
    await binarySearchTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(49, "Returning from the add method.");
    await customSleep(49);
    
    await highlightLine(50);
    await customSleep(50);

    await highlightCallingLine(50);
    await binarySearchTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(50, "Returning from the add method.");
    await customSleep(50);

    await highlightLine(51);
    await customSleep(51);

    await highlightCallingLine(51);
    await binarySearchTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(51, "Returning from the add method.");
    await customSleep(51);

    await highlightLine(52);
    await customSleep(52);

    await highlightCallingLine(52);
    await binarySearchTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(52, "Returning from the add method.");
    await customSleep(52);

    await highlightLine(53);
    await customSleep(53);

    await highlightCallingLine(53);
    await binarySearchTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(53, "Returning from the add method.");
    await customSleep(53);

    await highlightLine(54);
    await customSleep(54);

    await highlightCallingLine(54);
    await binarySearchTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(54, "Returning from the add method.");
    await customSleep(54);
};


class BinarySearchTreeForC {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    ) => {
        await highlightLine(17);
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        setHighlightedVariables(['data']);
        await customSleep(17);
        setHighlightedVariables([]);

        await highlightLine(18);
        await customSleep(18);

        await highlightSecondCallingLine(18);
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinarySearchTreeNode(data, nodeAddress);
        await highlightLine(9);
        await customSleep(9);

        await highlightLine(10);
        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        setHighlightedSingleNodeVariables([`${newNode.address}`]);
        await customSleep(10);
        setHighlightedSingleNodeVariables([]);

        await highlightLine(11);
        setHighlightedVariables(['data']);
        setHighlightSingleNodeDataPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(11);
        setHighlightedVariables([]);
        setHighlightSingleNodeDataPart([]);

        await highlightLine(12);
        setHighlightSingleNodeLeftPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(12);
        setHighlightSingleNodeLeftPart([]);

        await highlightLine(13);
        setHighlightSingleNodeRightPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        await customSleep(13);
        setHighlightSingleNodeRightPart([]);

        await highlightLine(14);
        setHighlightedSingleNodeVariables([`${newNode.address}`]);
        await customSleep(14);
        setHighlightedSingleNodeVariables([]);

        await highlightLine(18, "Returning from the createNode function and storing the returned node in the newNode variable.");
        await customSleep(18);
    
        await highlightLine(19);
        setHighlightedRootVariables(['root']);
        await customSleep(19);
        setHighlightedRootVariables([]);
        if (this.root === null) {
            await highlightLine(20);
            setHighlightedRootVariables(['root']);
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'NULL' }
            }));
            setHighlightNodeAddress([`${newNode.address}`]);

            await customSleep(null, 1200);
            // Delete single node variable
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));
            await customSleep(20);
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            await highlightLine(21);
            await customSleep(21);
            
            await highlightLine(22);
            let current = this.root;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current.address}
            }));
            setHighlightedRootVariables(['current']);
            await customSleep(22);
            setHighlightedRootVariables([]);

            while (true) {
                await highlightLine(23);
                await customSleep(23);

                await highlightLine(24);
                let parent = current;
                setRootVariables((vars) => ({ ...vars, 
                    parent: { variable_name: 'parent', value: parent.address }
                }));
                setHighlightedRootVariables(['parent']);
                await customSleep(24);
                setHighlightedRootVariables([]);

                await highlightLine(25);
                setHighlightedVariables(['data']);
                setHighlightedRootVariables(['current']);
                setHighlightNodeAddress([`${current.address}`]);
                setHighlightDataPart([`${current.address}`]);
                await customSleep(25);
                setHighlightedVariables([]);
                setHighlightedRootVariables([]);
                setHighlightNodeAddress([]);
                setHighlightDataPart([]);
                if (data < current.data) {
                    await highlightLine(26);
                    setHighlightedRootVariables(['current']);
                    setHighlightLeftPart([`${current.address}`]);
                    current = current.left;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'NULL'
                        }
                    }));
                    await customSleep(26);
                    setHighlightedRootVariables([]);
                    setHighlightLeftPart([]);

                    await highlightLine(27);
                    setHighlightedRootVariables(['current']);
                    await customSleep(27);
                    setHighlightedRootVariables([]);
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        await highlightLine(28);
                        setHighlightedRootVariables(['parent']);
                        setHighlightLeftPart([`${parent.address}`]);
                        parent.left = newNode;
                        setHighlightNodeAddress([`${newNode.address}`]);
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: newNode.address, right: parent.right ? parent.right.address : 'NULL'},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        await customSleep(28);
                        setHighlightLeftPart();
                        setHighlightNodeAddress([]);
                        setHighlightedRootVariables([]);

                        await highlightLine(29);
                        await customSleep(29);

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                } else {
                    await highlightLine(31);
                    await customSleep(31);

                    await highlightLine(32);
                    setHighlightedRootVariables(['current']);
                    setHighlightRightPart([`${current.address}`]);
                    current = current.right;
                    setRootVariables((vars) => ({ ...vars, 
                        current: { 
                            variable_name: 'current',
                            value: current ? current.address : 'NULL'
                        }
                    }));
                    await customSleep(32);
                    setHighlightedRootVariables([]);
                    setHighlightRightPart([]);

                    await highlightLine(33);
                    setHighlightedRootVariables(['current']);
                    await customSleep(33);
                    setHighlightedRootVariables([]);
                    if (current === null) {
                        setSingleNodeVariable(() => ({}));

                        await highlightLine(34);
                        setHighlightedRootVariables(['parent']);
                        setHighlightRightPart([`${parent.address}`]);
                        setHighlightNodeAddress([`${newNode.address}`]);
                        parent.right = newNode;
                        setNodeVariables((vars) => ({ ...vars, 
                            [parent.address]: { 
                                variable_name: parent.address,
                                value: {data: parent.data, left: parent.left ? parent.left.address : 'NULL', right: newNode.address},
                                address: parent.address,
                                parent: parent.parent
                            }
                        }));
                        await customSleep(34);
                        setHighlightRightPart([]);
                        setHighlightNodeAddress([]);
                        setHighlightedRootVariables([]);

                        await highlightLine(35);
                        await customSleep(35);

                        // Delete temp from headVariables
                        setRootVariables(() => ({
                            root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                        }));

                        // Emptying vaiables
                        setVariables(() => ({}));
                        return;
                    }
                }
            }
        }
    };

}

export const binarySearchTreeC = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables,
    setHighlightedVariables,
    setHighlightedRootVariables,
    setHighlightedNodeVariables,
    setHighlightedSingleNodeVariables,
    setHighlightSingleNodeLeftPart,
    setHighlightSingleNodeDataPart,
    setHighlightSingleNodeRightPart,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    customSleep
  ) => {
    let binarySearchTree = new BinarySearchTreeForC();
  
    await highlightLine(44);
    await customSleep(44);

    await highlightCallingLine(44);
    await binarySearchTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(44, "Returning from the addNode function.");
    await customSleep(44);
    
    await highlightLine(45);
    await customSleep(45);

    await highlightCallingLine(45);
    await binarySearchTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(45, "Returning from the addNode function.");
    await customSleep(45);
    
    await highlightLine(46);
    await customSleep(46);

    await highlightCallingLine(46);
    await binarySearchTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(46, "Returning from the addNode function.");
    await customSleep(46);

    await highlightLine(47);
    await customSleep(47);

    await highlightCallingLine(47);
    await binarySearchTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(47, "Returning from the addNode function.");
    await customSleep(47);

    await highlightLine(48);
    await customSleep(48);

    await highlightCallingLine(48);
    await binarySearchTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(48, "Returning from the addNode function.");
    await customSleep(48);

    await highlightLine(49);
    await customSleep(49);

    await highlightCallingLine(49);
    await binarySearchTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(49, "Returning from the addNode function.");
    await customSleep(49);

    await highlightLine(50);
    await customSleep(50);

    await highlightCallingLine(50);
    await binarySearchTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        customSleep
    );
    await highlightLine(50, "Returning from the addNode function.");
    await customSleep(50);
};

import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
      This algorithm performs basic arithmetic operations between two numbers, x and y, including addition, 
      subtraction, multiplication, division, modulus, exponentiation, and floor division.
      It checks for division by zero to prevent errors and prints the results of each operation.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Financial Calculations:</b> Compute totals, differences, and averages, such as budgeting and accounting operations.</li>
            <li><b>Scientific Computations:</b> Perform precise calculations, including exponential growth or decay (e.g., in population studies or radioactive decay).</li>
            <li><b>Programming Logic:</b> Implement operations in logic for gaming (e.g., scoring systems) or data processing.</li>
            <li><b>Everyday Math:</b> Solve real-world problems like splitting bills or calculating discounts.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

const generateMemoryAddress = () => {
  return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
};

class Node {
  constructor(data, nodeAddress) {
    this.data = data;
    this.next = null;
    this.address = nodeAddress;
  }
}

class GraphForPython {
  constructor() {
    this.graph = new Map();
    this.address = {};
  }

  async addEdge(
    vertex,
    neighbor,
    directed,
    setGraphVariables,
    setHighlightedGraphVariables,
    setVariables,
    setHighlightedVariables,
    setmapVariables,
    setHighlightedMapIndex,
    setHighlightedLineArrow,
    highlightLine,
    customSleep
  ) {
    await highlightLine(4);
    setVariables((vars) => ({ ...vars, 
        vertex: { variable_name: 'vertex', value: vertex },
        neighbor: { variable_name: 'neighbor', value: neighbor },
        directed: { variable_name: 'directed', value: directed === true ? 'True' : 'False' }
    }));
    setHighlightedVariables(['vertex', 'neighbor', 'directed']);
    await customSleep(4);
    setHighlightedVariables([]);

    await highlightLine(5);
    setHighlightedVariables(['vertex']);
    setHighlightedMapIndex([{ mapName: 'graph', key: `${vertex}` }]);
    await customSleep(5);
    setHighlightedMapIndex([]);
    setHighlightedVariables([]);
    if (!this.graph.has(vertex)) {
      await highlightLine(6);
      setHighlightedVariables(['vertex']);
      this.graph.set(vertex, []);

      this.address[vertex] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [vertex]: { 
          variable_name: vertex,
          address: this.address[vertex],
          edges: []
        }
      }));
      setHighlightedGraphVariables([this.address[vertex]]);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
      setHighlightedMapIndex([{ mapName: 'graph', key: `${vertex}` }]);
      await customSleep(6);
      setHighlightedVariables([]);
      setHighlightedMapIndex([]);
      setHighlightedGraphVariables([]);
    }

    await highlightLine(7);
    setHighlightedVariables(['neighbor']);
    setHighlightedMapIndex([{ mapName: 'graph', key: `${neighbor}` }]);
    await customSleep(7);
    setHighlightedMapIndex([]);
    setHighlightedVariables([]);
    if (!this.graph.has(neighbor)) {
      await highlightLine(8);
      setHighlightedVariables(['neighbor']);
      this.graph.set(neighbor, []);

      this.address[neighbor] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: []
        }
      }));
      setHighlightedGraphVariables([this.address[neighbor]]);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
      setHighlightedMapIndex([{ mapName: 'graph', key: `${neighbor}` }]);
      await customSleep(8);
      setHighlightedVariables([]);
      setHighlightedMapIndex([]);
      setHighlightedGraphVariables([]);
    }

    await highlightLine(10);
    setHighlightedVariables(['vertex', 'neighbor']);
    await customSleep(null, 1200);
    setHighlightedGraphVariables([this.address[vertex], this.address[neighbor]]);
    this.graph.get(vertex).push(neighbor);
    let _graph = {};
    for (let [vertex, neighbors] of this.graph.entries()) {
      _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
    }
    setHighlightedMapIndex([{ mapName: 'graph', key: `${vertex}` }]);
    await customSleep(null, 1200);
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    await customSleep(null, 1200);
    setGraphVariables((vars) => ({ ...vars, 
      [vertex]: { 
        variable_name: vertex,
        address: this.address[vertex],
        edges: this.graph.get(vertex)
      }
    }));
    await customSleep(10);
    setHighlightedMapIndex([]);
    setHighlightedVariables([]);
    setHighlightedGraphVariables([]);

    await highlightLine(11);
    setHighlightedVariables(['directed']);
    await customSleep(11);
    setHighlightedVariables([]);
    if (!directed) {
      await highlightLine(12);
      setHighlightedVariables(['vertex', 'neighbor']);
      setHighlightedGraphVariables([this.address[neighbor], this.address[vertex]]);
      setHighlightedLineArrow([`${this.address[neighbor]}_${this.address[vertex]}`]);
      this.graph.get(neighbor).push(vertex);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setHighlightedMapIndex([{ mapName: 'graph', key: `${neighbor}` }]);
      await customSleep(null, 1200);
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
      await customSleep(null, 1200);
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: this.graph.get(neighbor)
        }
      }));
      await customSleep(12);
      setHighlightedMapIndex([]);
      setHighlightedVariables([]);
      setHighlightedLineArrow([]);
      setHighlightedGraphVariables([]);
    }

    // Emptying vaiables
    setVariables(() => ({}));
  }
}

export const graphPython = async (
  graphInput,
  edgeDirection,
  setGraphVariables,
  setHighlightedGraphVariables,
  setVariables,
  setHighlightedVariables,
  setmapVariables,
  setHighlightedMapVariables,
  setHighlightedMapIndex,
  setHighlightedLineArrow,
  highlightLine,
  highlightCallingLine,
  customSleep
  ) => {
    await highlightLine(16);
    await customSleep(16);

    await highlightCallingLine(16);
    const graph = new GraphForPython();
    await highlightLine(1);
    await customSleep(16);

    await highlightLine(2);
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: {" ": " "} } }));
    setHighlightedMapVariables(['graph']);
    await customSleep(2);
    setHighlightedMapVariables([]);
    
    await highlightLine(16, "Returning from the constructor method.");
    await customSleep(16);


    await highlightLine(17);
    await customSleep(17);

    await highlightCallingLine(17);
    await graph.addEdge(
      graphInput[0][0],
      graphInput[0][1],
      edgeDirection[0],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );
    await highlightLine(17, "Returning from the add_edge method.");
    await customSleep(17);

    await highlightLine(18);
    await customSleep(18);

    await highlightCallingLine(18);
    await graph.addEdge(
      graphInput[1][0],
      graphInput[1][1],
      edgeDirection[1],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );
    await highlightLine(18, "Returning from the add_edge method.");
    await customSleep(18);

    await highlightLine(19);
    await customSleep(19);

    await highlightCallingLine(19);
    await graph.addEdge(
      graphInput[2][0],
      graphInput[2][1],
      edgeDirection[2],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );
    await highlightLine(19, "Returning from the add_edge method.");
    await customSleep(19);

    await highlightLine(20);
    await customSleep(20);

    await highlightCallingLine(20);
    await graph.addEdge(
      graphInput[3][0],
      graphInput[3][1],
      edgeDirection[3],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );
    await highlightLine(20, "Returning from the add_edge method.");
    await customSleep(20);

    await highlightLine(21);
    await customSleep(21);

    await highlightCallingLine(21);
    await graph.addEdge(
      graphInput[4][0],
      graphInput[4][1],
      edgeDirection[4],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );  // Directed edge
    await highlightLine(21, "Returning from the add_edge method.");
    await customSleep(21);
}

class GraphForJava {
  constructor() {
    this.graph = new Map();
    this.address = {};
  }

  async addEdge(
    vertex,
    neighbor,
    directed,
    setGraphVariables,
    setHighlightedGraphVariables,
    setVariables,
    setHighlightedVariables,
    setmapVariables,
    setHighlightedMapIndex,
    setHighlightedLineArrow,
    highlightLine,
    customSleep
  ) {
    await highlightLine(9);
    setVariables((vars) => ({ ...vars, 
        vertex: { variable_name: 'vertex', value: vertex },
        neighbor: { variable_name: 'neighbor', value: neighbor },
        directed: { variable_name: 'directed', value: directed === true ? 'true' : 'false' }
    }));
    setHighlightedVariables(['vertex', 'neighbor', 'directed']);
    await customSleep(9);
    setHighlightedVariables([]);

    await highlightLine(10);
    setHighlightedVariables(['vertex']);
    setHighlightedMapIndex([{ mapName: 'graph', key: `${vertex}` }]);
    if (!this.graph.has(vertex)) {
      this.graph.set(vertex, []);

      this.address[vertex] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [vertex]: { 
          variable_name: vertex,
          address: this.address[vertex],
          edges: []
        }
      }));
      setHighlightedGraphVariables([this.address[vertex]]);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
      await customSleep(10);
      setHighlightedMapIndex([]);
      setHighlightedVariables([]);
      setHighlightedGraphVariables([]);
    }

    await highlightLine(11);
    setHighlightedVariables(['neighbor']);
    setHighlightedMapIndex([{ mapName: 'graph', key: `${neighbor}` }]);
    if (!this.graph.has(neighbor)) {
      this.graph.set(neighbor, []);

      this.address[neighbor] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: []
        }
      }));
      setHighlightedGraphVariables([this.address[neighbor]]);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
      setHighlightedMapIndex([{ mapName: 'graph', key: `${neighbor}` }]);
      await customSleep(11);
      setHighlightedVariables([]);
      setHighlightedMapIndex([]);
      setHighlightedGraphVariables([]);
    }

    await highlightLine(12);
    setHighlightedVariables(['vertex', 'neighbor']);
    await customSleep(null, 1200);
    setHighlightedGraphVariables([this.address[vertex], this.address[neighbor]]);
    this.graph.get(vertex).push(neighbor);
    let _graph = {};
    for (let [vertex, neighbors] of this.graph.entries()) {
      _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
    }
    setHighlightedMapIndex([{ mapName: 'graph', key: `${vertex}` }]);
    await customSleep(null, 1200);
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    await customSleep(null, 1200);
    setGraphVariables((vars) => ({ ...vars, 
      [vertex]: { 
        variable_name: vertex,
        address: this.address[vertex],
        edges: this.graph.get(vertex)
      }
    }));
    await customSleep(12);
    setHighlightedMapIndex([]);
    setHighlightedVariables([]);
    setHighlightedGraphVariables([]);

    await highlightLine(13);
    setHighlightedVariables(['directed']);
    await customSleep(13);
    setHighlightedVariables([]);
    if (!directed) {
      await highlightLine(14);
      setHighlightedVariables(['vertex', 'neighbor']);
      setHighlightedGraphVariables([this.address[neighbor], this.address[vertex]]);
      setHighlightedLineArrow([`${this.address[neighbor]}_${this.address[vertex]}`]);
      this.graph.get(neighbor).push(vertex);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setHighlightedMapIndex([{ mapName: 'graph', key: `${neighbor}` }]);
      await customSleep(null, 1200);
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
      await customSleep(null, 1200);
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: this.graph.get(neighbor)
        }
      }));
      await customSleep(14);
      setHighlightedMapIndex([]);
      setHighlightedVariables([]);
      setHighlightedLineArrow([]);
      setHighlightedGraphVariables([]);
    }

    // Emptying vaiables
    setVariables(() => ({}));
  }
}

export const graphJava = async (
  graphInput,
  edgeDirection,
  setGraphVariables,
  setHighlightedGraphVariables,
  setVariables,
  setHighlightedVariables,
  setmapVariables,
  setHighlightedMapVariables,
  setHighlightedMapIndex,
  setHighlightedLineArrow,
  highlightLine,
  highlightCallingLine,
  customSleep
  ) => {
    await highlightLine(21);
    await customSleep(21);

    await highlightCallingLine(21);
    const graph = new GraphForJava();
    await highlightLine(5);
    await customSleep(5);

    await highlightLine(6);
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: {" ": " "} } }));
    setHighlightedMapVariables(['graph']);
    await customSleep(6);
    setHighlightedMapVariables([]);
    
    await highlightLine(21, "Returning from the constructor method.");
    await customSleep(21);


    await highlightLine(22);
    await customSleep(22);

    await highlightCallingLine(22);
    await graph.addEdge(
      graphInput[0][0],
      graphInput[0][1],
      edgeDirection[0],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );
    await highlightLine(22, "Returning from the addEdge method.");
    await customSleep(22);

    await highlightLine(23);
    await customSleep(23);

    await highlightCallingLine(23);
    await graph.addEdge(
      graphInput[1][0],
      graphInput[1][1],
      edgeDirection[1],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );
    await highlightLine(23, "Returning from the addEdge method.");
    await customSleep(23);

    await highlightLine(24);
    await customSleep(24);

    await highlightCallingLine(24);
    await graph.addEdge(
      graphInput[2][0],
      graphInput[2][1],
      edgeDirection[2],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );
    await highlightLine(24, "Returning from the addEdge method.");
    await customSleep(24);

    await highlightLine(25);
    await customSleep(25);

    await highlightCallingLine(25);
    await graph.addEdge(
      graphInput[3][0],
      graphInput[3][1],
      edgeDirection[3],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );
    await highlightLine(25, "Returning from the addEdge method.");
    await customSleep(25);

    await highlightLine(26);
    await customSleep(26);

    await highlightCallingLine(26);
    await graph.addEdge(
      graphInput[4][0],
      graphInput[4][1],
      edgeDirection[4],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );  // Directed edge
    await highlightLine(26, "Returning from the addEdge method.");
    await customSleep(26);
}

class GraphForCpp {
  constructor() {
    this.graph = new Map();
    this.address = {};
  }

  async addEdge(
    vertex,
    neighbor,
    directed,
    setGraphVariables,
    setHighlightedGraphVariables,
    setVariables,
    setHighlightedVariables,
    setmapVariables,
    setHighlightedMapIndex,
    setHighlightedLineArrow,
    highlightLine,
    customSleep
  ) {
    await highlightLine(12);
    setVariables((vars) => ({ ...vars, 
        vertex: { variable_name: 'vertex', value: vertex },
        neighbor: { variable_name: 'neighbor', value: neighbor },
        directed: { variable_name: 'directed', value: directed === true ? 'true' : 'false' }
    }));
    setHighlightedVariables(['vertex', 'neighbor', 'directed']);
    await customSleep(12);
    setHighlightedVariables([]);

    await highlightLine(13);
    setHighlightedVariables(['vertex']);
    setHighlightedMapIndex([{ mapName: 'graph', key: `${vertex}` }]);
    await customSleep(13);
    setHighlightedMapIndex([]);
    setHighlightedVariables([]);
    if (!this.graph.has(vertex)) {
      await highlightLine(14);
      setHighlightedVariables(['vertex']);
      this.graph.set(vertex, []);

      this.address[vertex] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [vertex]: { 
          variable_name: vertex,
          address: this.address[vertex],
          edges: []
        }
      }));
      setHighlightedGraphVariables([this.address[vertex]]);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
      setHighlightedMapIndex([{ mapName: 'graph', key: `${vertex}` }]);
      await customSleep(14);
      setHighlightedVariables([]);
      setHighlightedMapIndex([]);
      setHighlightedGraphVariables([]);
    }

    await highlightLine(16);
    setHighlightedVariables(['neighbor']);
    setHighlightedMapIndex([{ mapName: 'graph', key: `${neighbor}` }]);
    await customSleep(16);
    setHighlightedMapIndex([]);
    setHighlightedVariables([]);
    if (!this.graph.has(neighbor)) {
      await highlightLine(17);
      setHighlightedVariables(['neighbor']);
      this.graph.set(neighbor, []);

      this.address[neighbor] = generateMemoryAddress();
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: []
        }
      }));
      setHighlightedGraphVariables([this.address[neighbor]]);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
      setHighlightedMapIndex([{ mapName: 'graph', key: `${neighbor}` }]);
      await customSleep(17);
      setHighlightedVariables([]);
      setHighlightedMapIndex([]);
      setHighlightedGraphVariables([]);
    }

    await highlightLine(19);
    setHighlightedVariables(['vertex', 'neighbor']);
    await customSleep(null, 1200);
    setHighlightedGraphVariables([this.address[vertex], this.address[neighbor]]);
    this.graph.get(vertex).push(neighbor);
    let _graph = {};
    for (let [vertex, neighbors] of this.graph.entries()) {
      _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
    }
    setHighlightedMapIndex([{ mapName: 'graph', key: `${vertex}` }]);
    await customSleep(null, 1200);
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
    await customSleep(null, 1200);
    setGraphVariables((vars) => ({ ...vars, 
      [vertex]: { 
        variable_name: vertex,
        address: this.address[vertex],
        edges: this.graph.get(vertex)
      }
    }));
    await customSleep(19);
    setHighlightedMapIndex([]);
    setHighlightedVariables([]);
    setHighlightedGraphVariables([]);

    await highlightLine(20);
    setHighlightedVariables(['directed']);
    await customSleep(20);
    setHighlightedVariables([]);
    if (!directed) {
      await highlightLine(21);
      setHighlightedVariables(['vertex', 'neighbor']);
      setHighlightedGraphVariables([this.address[neighbor], this.address[vertex]]);
      setHighlightedLineArrow([`${this.address[neighbor]}_${this.address[vertex]}`]);
      this.graph.get(neighbor).push(vertex);
      let _graph = {};
      for (let [vertex, neighbors] of this.graph.entries()) {
        _graph[vertex] = `[ ${neighbors.join(", ")} ]`;
      }
      setHighlightedMapIndex([{ mapName: 'graph', key: `${neighbor}` }]);
      await customSleep(null, 1200);
      setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: _graph } }));
      await customSleep(null, 1200);
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: this.graph.get(neighbor)
        }
      }));
      await customSleep(21);
      setHighlightedMapIndex([]);
      setHighlightedVariables([]);
      setHighlightedLineArrow([]);
      setHighlightedGraphVariables([]);
    }

    // Emptying vaiables
    setVariables(() => ({}));
  }
}

export const graphCpp = async (
  graphInput,
  edgeDirection,
  setGraphVariables,
  setHighlightedGraphVariables,
  setVariables,
  setHighlightedVariables,
  setmapVariables,
  setHighlightedMapVariables,
  setHighlightedMapIndex,
  setHighlightedLineArrow,
  highlightLine,
  highlightCallingLine,
  customSleep
  ) => {
    await highlightLine(28);
    await customSleep(28);

    await highlightCallingLine(28);
    const graph = new GraphForCpp();

    await highlightLine(8);
    setmapVariables((vars) => ({ ...vars, graph: { variable_name: 'graph', value: {" ": " "} } }));
    setHighlightedMapVariables(['graph']);
    await customSleep(8);
    setHighlightedMapVariables([]);
    
    await highlightLine(28, "An instance of the graph has been created.");
    await customSleep(28);


    await highlightLine(29);
    await customSleep(29);

    await highlightCallingLine(29);
    await graph.addEdge(
      graphInput[0][0],
      graphInput[0][1],
      edgeDirection[0],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );
    await highlightLine(29, "Returning from the addEdge method.");
    await customSleep(29);

    await highlightLine(30);
    await customSleep(30);

    await highlightCallingLine(30);
    await graph.addEdge(
      graphInput[1][0],
      graphInput[1][1],
      edgeDirection[1],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );
    await highlightLine(30, "Returning from the addEdge method.");
    await customSleep(30);

    await highlightLine(31);
    await customSleep(31);

    await highlightCallingLine(31);
    await graph.addEdge(
      graphInput[2][0],
      graphInput[2][1],
      edgeDirection[2],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );
    await highlightLine(31, "Returning from the addEdge method.");
    await customSleep(31);

    await highlightLine(32);
    await customSleep(32);

    await highlightCallingLine(32);
    await graph.addEdge(
      graphInput[3][0],
      graphInput[3][1],
      edgeDirection[3],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );
    await highlightLine(32, "Returning from the addEdge method.");
    await customSleep(32);

    await highlightLine(33);
    await customSleep(33);

    await highlightCallingLine(33);
    await graph.addEdge(
      graphInput[4][0],
      graphInput[4][1],
      edgeDirection[4],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setmapVariables,
      setHighlightedMapIndex,
      setHighlightedLineArrow,
      highlightLine,
      customSleep
    );  // Directed edge
    await highlightLine(33, "Returning from the addEdge method.");
    await customSleep(33);
}


class GraphForC {
  constructor(maxVertices) {
    this.graph = new Map();
    this._graph = {};
    this.address = {};
    this.adjLists = new Array(maxVertices).fill(null);
    this.maxVertices = maxVertices;
  }

  async createNode(
    v,
    setVariables,
    setHighlightedVariables,
    highlightLine,
    highlightMultipleLines,
    highlightThirdCallingLine,
    setNodeVariables,
    setHighlightedNodeVariables,
    setHighlightNodeAddress,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    customSleep
  ) {

    await highlightLine(23);
    setVariables((vars) => ({ ...vars, 
      v: { variable_name: 'v', value: v }
    }));
    setHighlightedVariables(['v']);
    await customSleep(23);
    setHighlightedVariables([]);

    await highlightLine(24);
    await customSleep(24);

    await highlightThirdCallingLine(24);
    await highlightMultipleLines([7, 8]);
    let nodeAddress = generateMemoryAddress();
    let newNode = new Node(v, nodeAddress);
    setNodeVariables({
      [newNode.address]: { 
        variable_name: newNode.address,
        value: { data: '', next: '' },
        address: newNode.address,
      }
    });
    setHighlightedNodeVariables([`${nodeAddress}`]);
    await customSleep(7);
    setHighlightedNodeVariables([]);
    await highlightMultipleLines([]);
    
    await highlightLine(24);
    await customSleep(24);

    await highlightLine(25);
    setHighlightedVariables(['v']);
    setHighlightDataPart([`${nodeAddress}`]);
    await customSleep(null, 1200);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: ''},
        address: newNode.address,
      }
    }));
    await customSleep(25);
    setHighlightedVariables([]);
    setHighlightDataPart([]);

    await highlightLine(26);
    setHighlightNextAddressPart([`${nodeAddress}`]);
    await customSleep(null, 1200);
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: 'NULL'},
        address: newNode.address,
      }
    }));
    await customSleep(26);
    setHighlightNextAddressPart([]);

    await highlightLine(27);
    await customSleep(27);

    return newNode;
    
  }

  async createGraph(
    setVariables,
    setHighlightedVariables,
    setAdjList,
    setMapVariablesForC,
    setHighlightedMapVariablesForC,
    setHighlightedMapIndexForC,
    highlightLine,
    highlightSecondCallingLine,
    customSleep
  ) {

    await highlightLine(15);
    await customSleep(15);

    await highlightLine(16);
    await customSleep(16);

    await highlightSecondCallingLine(16);
    await highlightLine(12);
    setMapVariablesForC((vars) => ({ ...vars, graph: { variable_name: 'graph', value: {" ": " "} } }));
    setHighlightedMapVariablesForC(['graph']);
    // let _graph = {};
    for (let i = 0; i < this.maxVertices; i++) {
      this._graph[i] = ``;
      setAdjList((prevAdjList) => {
        const updatedList = [...prevAdjList];
  
        if (updatedList[i]) {
          updatedList[i] = [...updatedList[i], ``];
        } else {
          updatedList[i] = ``;
        }
  
        return updatedList;
      });
    }
    setMapVariablesForC((vars) => ({ ...vars, graph: { variable_name: 'graph', value: this._graph } }));
    await customSleep(12);
    setHighlightedMapVariablesForC([]);
    await highlightLine(16);
    await customSleep(16);

    for (let i = 0; i < this.maxVertices; i++) {
      await highlightLine(17);
      setHighlightedVariables(['i', 'MAX_VERTICES']);
      setVariables((vars) => ({ ...vars, 
        i: { variable_name: 'i', value: i }
      }));
      await customSleep(17);
      setHighlightedVariables([]);

      await highlightLine(18);
      setHighlightedMapIndexForC([{ mapName: 'graph', key: `${i}` }]);
      setHighlightedVariables(['i']);
      await customSleep(null, 1200);
      this._graph[i] = 'NULL';
      setMapVariablesForC((vars) => ({ ...vars, graph: { variable_name: 'graph', value: this._graph } }));
      setAdjList((prevAdjList) => {
        const updatedList = [...prevAdjList];
  
        if (updatedList[i]) {
          updatedList[i] = [...updatedList[i], 'NULL'];
        } else {
          updatedList[i] = 'NULL';
        }
  
        return updatedList;
      });
      await customSleep(18);
      setHighlightedVariables([]);
      setHighlightedMapIndexForC([]);

      if (i === this.maxVertices - 1) {
        await highlightLine(17);
        setVariables((vars) => ({ ...vars, 
          i: { variable_name: 'i', value: i + 1 }
        }));
        setHighlightedVariables(['i', 'MAX_VERTICES']);
        await customSleep(17);
        setHighlightedVariables([]);
      }
    }
    
    await highlightLine(20);
    await customSleep(20);

  }

  async addEdge(
    vertex,
    neighbor,
    directed,
    setGraphVariables,
    setHighlightedGraphVariables,
    setVariables,
    setHighlightedVariables,
    setAdjList,
    setHighlightedMapIndexForC,
    setHighlightedLineArrow,
    highlightLine,
    highlightMultipleLines,
    highlightSecondCallingLine,
    highlightThirdCallingLine,
    setNodeVariables,
    setHighlightedNodeVariables,
    setHighlightNodeAddress,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    customSleep
  ) {
    await highlightLine(30);
    setVariables((vars) => ({ ...vars, 
        vertex: { variable_name: 'vertex', value: vertex },
        neighbor: { variable_name: 'neighbor', value: neighbor },
        directed: { variable_name: 'directed', value: directed === true ? 'true' : 'false' }
    }));
    setHighlightedVariables(['vertex', 'neighbor', 'directed']);
    await customSleep(30);
    setHighlightedVariables([]);

    await highlightLine(31);
    await customSleep(31);

    await highlightSecondCallingLine(31);
    let newNode = await this.createNode(
      neighbor,
      setVariables,
      setHighlightedVariables,
      highlightLine,
      highlightMultipleLines,
      highlightThirdCallingLine,
      setNodeVariables,
      setHighlightedNodeVariables,
      setHighlightNodeAddress,
      setHighlightDataPart,
      setHighlightNextAddressPart,
      customSleep
    )
    await highlightLine(31);
    await customSleep(31);

    await highlightLine(32);
    setHighlightedMapIndexForC([{ mapName: 'graph', key: `${vertex}` }]);
    setHighlightedVariables(['vertex']);
    setHighlightNextAddressPart([`${newNode.address}`]);
    await customSleep(null, 1200);
    newNode.next = this.adjLists[vertex];
    setNodeVariables((vars) => ({ ...vars, 
      [newNode.address]: { 
        variable_name: newNode.address,
        value: {data: newNode.data, next: `${this._graph[vertex]}`},
        address: newNode.address,
      }
    }));
    await customSleep(32);
    setHighlightedMapIndexForC([]);
    setHighlightedVariables([]);
    setHighlightNextAddressPart([]);

    await highlightLine(33);
    setHighlightedMapIndexForC([{ mapName: 'graph', key: `${vertex}` }]);
    setHighlightedVariables(['vertex']);
    setHighlightNodeAddress([`${newNode.address}`]);
    setNodeVariables(() => ({}));
    this.adjLists[vertex] = newNode;
    setHighlightedNodeVariables([`${newNode.address}`]);
    this._graph[vertex] = newNode.address;
    setAdjList((prevAdjList) => {
      const updatedList = [...prevAdjList];
      let node = {
        'address': newNode.address,
        'data': newNode.data,
        'next': newNode.next === null ? 'NULL' : newNode.next.address,
      };

      if (updatedList[vertex] && typeof updatedList[vertex] !== 'string') {
        updatedList[vertex] = [node, ...updatedList[vertex]];
      } else {
        updatedList[vertex] = [node];
      }

      return updatedList;
    });

    await customSleep(null, 1200);
    
    if (!this.graph.has(vertex)) {
      this.graph.set(vertex, []);
      this.address[vertex] = generateMemoryAddress();

      setGraphVariables((vars) => ({ ...vars, 
        [vertex]: { 
          variable_name: vertex,
          address: this.address[vertex],
          edges: []
        }
      }));
      setHighlightedGraphVariables([this.address[vertex]]);
    }
    await customSleep(null, 1200);
    setHighlightedGraphVariables([]);

    if (!this.graph.has(neighbor)) {
      this.graph.set(neighbor, []);
      this.address[neighbor] = generateMemoryAddress();

      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: []
        }
      }));
      setHighlightedGraphVariables([this.address[neighbor]]);
    }
    await customSleep(null, 1200);
    setHighlightedGraphVariables([]);

    this.graph.get(vertex).push(neighbor);
    setGraphVariables((vars) => ({ ...vars, 
      [vertex]: { 
        variable_name: vertex,
        address: this.address[vertex],
        edges: this.graph.get(vertex)
      }
    }));

    await customSleep(33);
    setHighlightedMapIndexForC([]);
    setHighlightedVariables([]);
    setHighlightNodeAddress([]);
    setHighlightedNodeVariables([]);

    await highlightLine(35);
    setHighlightedVariables(['directed']);
    await customSleep(35);
    setHighlightedVariables([]);
    if (!directed) {
      await highlightLine(36);
      await customSleep(36);

      await highlightSecondCallingLine(36);
      let newNode = await this.createNode(
        vertex,
        setVariables,
        setHighlightedVariables,
        highlightLine,
        highlightMultipleLines,
        highlightThirdCallingLine,
        setNodeVariables,
        setHighlightedNodeVariables,
        setHighlightNodeAddress,
        setHighlightDataPart,
        setHighlightNextAddressPart,
        customSleep
      )
      await highlightLine(36);
      await customSleep(36);
      
      await highlightLine(37);
      setHighlightedMapIndexForC([{ mapName: 'graph', key: `${neighbor}` }]);
      setHighlightedVariables(['neighbor']);
      setHighlightNextAddressPart([`${newNode.address}`]);
      await customSleep(null, 1200);
      newNode.next = this.adjLists[neighbor];
      setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
          variable_name: newNode.address,
          value: {data: newNode.data, next: `${this._graph[neighbor]}`},
          address: newNode.address
        }
      }));
      await customSleep(37);
      setHighlightedMapIndexForC([]);
      setHighlightedVariables([]);
      setHighlightNextAddressPart([]);
      
      await highlightLine(38);
      setHighlightedMapIndexForC([{ mapName: 'graph', key: `${neighbor}` }]);
      setHighlightedVariables(['neighbor']);
      setHighlightNodeAddress([`${newNode.address}`]);
      setHighlightedNodeVariables([`${newNode.address}`]);
      setNodeVariables(() => ({}));
      this.adjLists[neighbor] = newNode;
      this._graph[neighbor] = newNode.address;
      setAdjList((prevAdjList) => {
        const updatedList = [...prevAdjList];
        let node = {
          'address': newNode.address,
          'data': newNode.data,
          'next': newNode.next === null ? 'NULL' : newNode.next.address
        };
  
        if (updatedList[neighbor] && typeof updatedList[neighbor] !== 'string') {
          updatedList[neighbor] = [node, ...updatedList[neighbor]];
        } else {
          updatedList[neighbor] = [node];
        }
  
        return updatedList;
      });

      await customSleep(null, 1200);
      this.graph.get(neighbor).push(vertex);
      setGraphVariables((vars) => ({ ...vars, 
        [neighbor]: { 
          variable_name: neighbor,
          address: this.address[neighbor],
          edges: this.graph.get(neighbor)
        }
      }));
      setHighlightedLineArrow([`${this.address[neighbor]}_${this.address[vertex]}`]);
      await customSleep(38);
      setHighlightedMapIndexForC([]);
      setHighlightedVariables([]);
      setHighlightNodeAddress([]);
      setHighlightedLineArrow([]);
      setHighlightedNodeVariables([]);
      
    }

    // Emptying vaiables
    setVariables(() => ({}));
  }
}

export const graphC = async (
  maxVertices,
  graphInput,
  edgeDirection,
  setGraphVariables,
  setHighlightedGraphVariables,
  setVariables,
  setHighlightedVariables,
  setAdjList,
  setMapVariablesForC,
  setHighlightedMapVariablesForC,
  setHighlightedMapIndexForC,
  setHighlightedLineArrow,
  highlightLine,
  highlightMultipleLines,
  highlightCallingLine,
  highlightSecondCallingLine,
  highlightThirdCallingLine,
  setNodeVariables,
  setHighlightedNodeVariables,
  setHighlightNodeAddress,
  setHighlightDataPart,
  setHighlightNextAddressPart,
  customSleep
  ) => {
    await highlightLine(4);
    setVariables((vars) => ({ ...vars, 
      MAX_VERTICES: { variable_name: 'MAX_VERTICES', value: maxVertices }
    }));
    setHighlightedVariables(['MAX_VERTICES']);
    await customSleep(4);
    setHighlightedVariables([]);

    await highlightLine(44);
    await customSleep(44);

    await highlightCallingLine(44);
    const graph = new GraphForC(maxVertices);
    await graph.createGraph(
      setVariables,
      setHighlightedVariables,
      setAdjList,
      setMapVariablesForC,
      setHighlightedMapVariablesForC,
      setHighlightedMapIndexForC,
      highlightLine,
      highlightSecondCallingLine,
      customSleep
    );
    await highlightLine(44, "Returning from the createGraph function.");
    await customSleep(44);

    await highlightLine(46);
    await customSleep(46);

    await highlightCallingLine(46);
    await graph.addEdge(
      graphInput[0][0],
      graphInput[0][1],
      edgeDirection[0],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setAdjList,
      setHighlightedMapIndexForC,
      setHighlightedLineArrow,
      highlightLine,
      highlightMultipleLines,
      highlightSecondCallingLine,
      highlightThirdCallingLine,
      setNodeVariables,
      setHighlightedNodeVariables,
      setHighlightNodeAddress,
      setHighlightDataPart,
      setHighlightNextAddressPart,
      customSleep
    );
    await highlightLine(46, "Returning from the addEdge function.");
    await customSleep(46);

    await highlightLine(47);
    await customSleep(47);

    await highlightCallingLine(47);
    await graph.addEdge(
      graphInput[1][0],
      graphInput[1][1],
      edgeDirection[1],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setAdjList,
      setHighlightedMapIndexForC,
      setHighlightedLineArrow,
      highlightLine,
      highlightMultipleLines,
      highlightSecondCallingLine,
      highlightThirdCallingLine,
      setNodeVariables,
      setHighlightedNodeVariables,
      setHighlightNodeAddress,
      setHighlightDataPart,
      setHighlightNextAddressPart,
      customSleep
    );
    await highlightLine(47, "Returning from the addEdge function.");
    await customSleep(47);

    await highlightLine(48);
    await customSleep(48);

    await highlightCallingLine(48);
    await graph.addEdge(
      graphInput[2][0],
      graphInput[2][1],
      edgeDirection[2],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setAdjList,
      setHighlightedMapIndexForC,
      setHighlightedLineArrow,
      highlightLine,
      highlightMultipleLines,
      highlightSecondCallingLine,
      highlightThirdCallingLine,
      setNodeVariables,
      setHighlightedNodeVariables,
      setHighlightNodeAddress,
      setHighlightDataPart,
      setHighlightNextAddressPart,
      customSleep
    );
    await highlightLine(48, "Returning from the addEdge function.");
    await customSleep(48);

    await highlightLine(49);
    await customSleep(49);

    await highlightCallingLine(49);
    await graph.addEdge(
      graphInput[3][0],
      graphInput[3][1],
      edgeDirection[3],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setAdjList,
      setHighlightedMapIndexForC,
      setHighlightedLineArrow,
      highlightLine,
      highlightMultipleLines,
      highlightSecondCallingLine,
      highlightThirdCallingLine,
      setNodeVariables,
      setHighlightedNodeVariables,
      setHighlightNodeAddress,
      setHighlightDataPart,
      setHighlightNextAddressPart,
      customSleep
    );
    await highlightLine(49, "Returning from the addEdge function.");
    await customSleep(49);

    await highlightLine(50);
    await customSleep(50);

    await highlightCallingLine(50);
    await graph.addEdge(
      graphInput[4][0],
      graphInput[4][1],
      edgeDirection[4],
      setGraphVariables,
      setHighlightedGraphVariables,
      setVariables,
      setHighlightedVariables,
      setAdjList,
      setHighlightedMapIndexForC,
      setHighlightedLineArrow,
      highlightLine,
      highlightMultipleLines,
      highlightSecondCallingLine,
      highlightThirdCallingLine,
      setNodeVariables,
      setHighlightedNodeVariables,
      setHighlightNodeAddress,
      setHighlightDataPart,
      setHighlightNextAddressPart,
      customSleep
    );  // Directed edge
    await highlightLine(50, "Returning from the addEdge function.");
    await customSleep(50);
}
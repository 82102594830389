import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This implementation performs an in-order traversal of a binary tree iteratively using a stack. The traversal visits nodes in the order:
        <ul style={{paddingLeft: '15px'}}>
            <li><b>Left subtree:</b> Process all nodes in the left subtree.</li>
            <li><b>Root node:</b> Process the root.</li>
            <li><b>Right subtree:</b> Process all nodes in the right subtree.</li>
        </ul>
        <div><b>Steps:</b></div>
        <ul style={{paddingLeft: '15px'}}>
            <li>Start with the root node.</li>
            <li>Use a stack to manage the traversal
                <ul style={{paddingLeft: '15px'}}>
                    <li>Push nodes to the stack while traversing left.</li>
                    <li>When a node has no left child, pop it from the stack, process it, and move to its right child.</li>
                </ul>
            </li>
            <li>Repeat until the stack is empty and all nodes are processed.</li>
        </ul>
      </div>
      <div id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Expression Trees:</b> Evaluate expressions where in-order traversal corresponds to the natural order of operators.</li>
            <li><b>Binary Search Trees (BST):</b> Retrieve sorted elements from a BST.</li>
            <li><b>Tree Visualization:</b> Display tree elements in hierarchical order.</li>
            <li><b>Data Processing Pipelines:</b> Manage dependencies or process tasks in a specific order.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

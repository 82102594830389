import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This program demonstrates the concept of <b>string interning</b>, where identical 
        immutable string literals are stored in a shared memory location for optimization.
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Memory Efficiency:</b> Reduces memory usage by reusing identical strings in large-scale applications.</li>
            <li><b>String Comparison Optimization:</b> Allows faster comparisons (s1 is s3) by comparing references instead of values.</li>
            <li><b>Constant Management:</b> Useful in applications dealing with many identical constants, such as keywords in programming languages or configuration files.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

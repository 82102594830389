import { useState, useEffect, useRef } from 'react';
import defaultExplanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useDictionaryTypes = (speakingLang, translatedExplanations, language, student, breakpoints) => {
  const [explanations, setExplanations] = useState(defaultExplanations);
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [highlightedMultipleLines, setHighlightedMultipleLines] = useState([]);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [mapVariables, setmapVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedMapVariables, setHighlightedMapVariables] = useState([]);
  const [highlightedMapIndex, setHighlightedMapIndex] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef, speakingLang);
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);
  

  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  useEffect(() => {
    if (translatedExplanations && language) {
      setExplanations((prevExplanations) => ({
        ...prevExplanations,
        [language]: Object.keys(prevExplanations[language] || {}).reduce((acc, key, index) => {
          acc[key] = translatedExplanations[index] || prevExplanations[language][key];
          return acc;
        }, {}),
      }));
    } else {
      setExplanations(defaultExplanations);
    }
  }, [translatedExplanations, language]);

  const updateVariables = (newStudent) => {
    resetState();

    // Dynamically update the code samples
    let updatedCodeSamples = "";
    if (language === "Python") {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/"name":\s*".*?"/, `"name": "${newStudent.name}"`)
          .replace(/"age":\s*\d+/, `"age": ${newStudent.age}`)
          .replace(/"graduated":\s*(True|False)/, `"graduated": ${newStudent.graduated}`)
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/"name",\s*".*?"/, `"name", "${newStudent.name}"`)
          .replace(/"age",\s*\d+/, `"age", ${newStudent.age}`)
          .replace(/"graduated",\s*(true|false)/, `"graduated", ${newStudent.graduated}`)
      );
    }
     
    setCodeSamples(updatedCodeSamples);
  };


  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setHighlightedMultipleLines([]);
    setFocusedEndLine(null);
    setVariables({});
    setArrayVariables({});
    setmapVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedMapVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightMultipleLines = async (lineNumbers) => {
    setHighlightedMultipleLines(lineNumbers);
    let lineNumber = lineNumbers[0];
    
    if (lineNumber) {
      await logMessage(`Line ${lineNumber + 1}: ${explanations['Python'][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
    }
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runDictionaryTypesPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightMultipleLines([2, 3, 4, 5, 6]);
    setmapVariables({ student: { variable_name: 'student', value: student } });
    setHighlightedMapVariables(['student']);
    await customSleep(2);
    setHighlightedMapVariables([]);
    highlightMultipleLines([]);

    await highlightLine(9);
    setHighlightedMapIndex([{ mapName: 'student', key: 'name' }]);
    await logMessage(`Name: ${student.name}`);
    await customSleep(9);
    setHighlightedMapIndex([]);

    await highlightLine(10);
    setHighlightedMapIndex([{ mapName: 'student', key: 'age' }]);
    await logMessage(`Age: ${student.age}`);
    await customSleep(10);
    setHighlightedMapIndex([]);

    await highlightLine(11);
    setHighlightedMapIndex([{ mapName: 'student', key: 'graduated' }]);
    await logMessage(`Graduated: ${student.graduated}`);
    await customSleep(11);
    setHighlightedMapIndex([]);

    await highlightLine(14);
    student["GPA"] = 3.8;
    setHighlightedMapIndex([{ mapName: 'student', key: 'GPA' }]);
    await customSleep(14);
    setHighlightedMapIndex([]);

    await highlightLine(17);
    setHighlightedMapIndex([{ mapName: 'student', key: 'age' }]);
    student["age"] = 23;
    await customSleep(17);
    setHighlightedMapIndex([]);

    await highlightLine(20);
    setHighlightedMapIndex([{ mapName: 'student', key: 'graduated' }]);
    delete student["graduated"];
    await customSleep(20);
    setHighlightedMapIndex([]);

    await highlightLine(23);
    await logMessage("Updated student dictionary:");
    await customSleep(23);

    for (const [key, value] of Object.entries(student)) {
        await highlightLine(24);
        setHighlightedMapIndex([{ mapName: 'student', key: key }]);
        setVariables((vars) => ({ ...vars, key: { variable_name: 'key', value: key }, value: { variable_name: 'value', value: value } }));
        setHighlightedVariables(['key', 'value']);
        await customSleep(24);
        setHighlightedMapIndex([]);

        await highlightLine(25);
        await logMessage(`${key}: ${value}`);
        await customSleep(25);
        setHighlightedVariables([]);
    }

    await focusEndLine(25);
    setIsRunning(false);
  };

  const runDictionaryTypesJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    let _student = {};

    await highlightLine(5);
    setmapVariables({ student: { variable_name: 'student', value: _student } });
    setHighlightedMapVariables(['student']);
    await customSleep(5);
    setHighlightedMapVariables([]);

    await highlightLine(6);
    _student['name'] = student['name'];
    setHighlightedMapIndex([{ mapName: 'student', key: 'name' }]);
    await customSleep(6);
    setHighlightedMapIndex([]);

    await highlightLine(7);
    _student['age'] = student['age'];
    setHighlightedMapIndex([{ mapName: 'student', key: 'age' }]);
    await customSleep(7);
    setHighlightedMapIndex([]);

    await highlightLine(8);
    _student['graduated'] = student['graduated'];
    setHighlightedMapIndex([{ mapName: 'student', key: 'graduated' }]);
    await customSleep(8);
    setHighlightedMapIndex([]);

    await highlightLine(11);
    setHighlightedMapIndex([{ mapName: 'student', key: 'name' }]);
    await logMessage(`Name: ${_student.name}`);
    await customSleep(11);
    setHighlightedMapIndex([]);

    await highlightLine(12);
    setHighlightedMapIndex([{ mapName: 'student', key: 'age' }]);
    await logMessage(`Age: ${_student.age}`);
    await customSleep(12);
    setHighlightedMapIndex([]);

    await highlightLine(13);
    setHighlightedMapIndex([{ mapName: 'student', key: 'graduated' }]);
    await logMessage(`Graduated: ${_student.graduated}`);
    await customSleep(13);
    setHighlightedMapIndex([]);

    await highlightLine(16);
    _student["GPA"] = 3.8;
    setHighlightedMapIndex([{ mapName: 'student', key: 'GPA' }]);
    await customSleep(16);
    setHighlightedMapIndex([]);

    await highlightLine(19);
    setHighlightedMapIndex([{ mapName: 'student', key: 'age' }]);
    _student["age"] = 23;
    await customSleep(19);
    setHighlightedMapIndex([]);

    await highlightLine(22);
    setHighlightedMapIndex([{ mapName: 'student', key: 'graduated' }]);
    delete _student["graduated"];
    await customSleep(22);
    setHighlightedMapIndex([]);

    await highlightLine(25);
    await logMessage("Updated student dictionary:");
    await customSleep(25);

    for (const [key, value] of Object.entries(_student)) {
        await highlightLine(26);
        setHighlightedMapIndex([{ mapName: 'student', key: key }]);
        let entry = {
          [key]: value
        }
        setmapVariables((vars) => ({ ...vars, entry: { variable_name: 'entry', value: entry } }));
        setHighlightedMapVariables(['entry']);
        await customSleep(26);
        setHighlightedMapIndex([]);
        setHighlightedMapVariables([]);

        await highlightLine(27);
        setHighlightedMapIndex([{ mapName: 'entry', key: key }]);
        await logMessage(`${key}: ${value}`);
        await customSleep(27);
        setHighlightedVariables([]);
        setHighlightedMapIndex([]);
    }

    await focusEndLine(25);
    setIsRunning(false);
  };

  const runDictionaryTypes = async () => {
    if (language === 'Python') {
      await runDictionaryTypesPython();
    } else if (language === 'Java') {
      await runDictionaryTypesJava();
    }
  };

  return {
    highlightedLine,
    highlightedMultipleLines,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    mapVariables,
    highlightedVariables,
    highlightedMapVariables,
    highlightedMapIndex,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setHighlightedMultipleLines,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setmapVariables,
    setHighlightedVariables,
    setHighlightedMapVariables,
    setHighlightedMapIndex,
    runDictionaryTypes
  };
};

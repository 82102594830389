import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This program demonstrates the <b>Breadth-First Search (BFS)</b> algorithm for traversing a graph. 
        Using a queue and a set to track visited nodes, the BFS function explores all neighbors of a starting node level by level. 
        The given implementation initializes a graph but lacks connections, resulting in an error when executed.
      </div>
      <div id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>V + E</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Pathfinding:</b> Find the shortest path in unweighted graphs (e.g., road maps, social networks).</li>
            <li><b>Web Crawlers:</b> Explore websites level by level.</li>
            <li><b>Broadcasting:</b> Simulate signal propagation in networks.</li>
            <li><b>Game AI:</b> Explore possible moves or states in games.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This program implements the <b>Selection Sort</b> algorithm, which sorts a list by 
        repeatedly finding the minimum element from the unsorted part and swapping it 
        with the first element of the unsorted portion. It iterates through the list, 
        ensuring the smallest elements are placed in the correct positions sequentially.
      </div>
      <div id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}><span>N<sup>2</sup></span></span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Small Data Sets:</b> Sorting small lists in embedded systems or resource-constrained environments.</li>
            <li><b>Educational Tools:</b> Teaching sorting algorithms due to its simplicity and ease of understanding.</li>
            <li><b>Manual Sorting:</b> Mimicking the way humans sort small collections, such as organizing cards or documents by size or order.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

import React, { useState, useRef } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from './AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';
import LanguageDropdown from '../LanguageDropdown';
import defaultExplanations from './explanations';

import { useBooleanTypes } from './useBooleanTypes';

const BooleanTypes = ({ language, subTopicId }) => {
  const [speakingLang, setSpeakingLang] = useState("en-US");
  const [translatedExplanations, setTranslatedExplanations] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [isHungry, setIsHungry] = useState(true);
  const [hasFood, setHasFood] = useState(false);

  const [inputIsHungry, setInputIsHungry] = useState(true);
  const [inputHasFood, setInputHasFood] = useState(false);

  const [breakpoints, setBreakpoints] = useState([]);

  const editorCardRef = useRef(null);

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runBooleanTypes
  } = useBooleanTypes(speakingLang, translatedExplanations, language, isHungry, hasFood, breakpoints);

  
  const handleUpdateVariables = () => {
    setIsHungry(inputIsHungry);
    setHasFood(inputHasFood);
    updateVariables(inputIsHungry, inputHasFood);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6" ref={editorCardRef}>
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Boolean Type Example"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            isRunning={isRunning}
            breakpoints={breakpoints}
            setBreakpoints={setBreakpoints}
            onOpenModal={() => {
              setInputIsHungry(isHungry);
              setInputHasFood(hasFood);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <div className="d-flex justify-content-center position-relative">
              <h3 className="section-title text-center flex-grow-1">Canvas</h3>
              <LanguageDropdown
                onLanguageChange={setSpeakingLang}
                onTranslate={setTranslatedExplanations}
                selectedProgrammingLang={language}
                defaultExplanations={defaultExplanations}
                isRunning={isRunning} 
                setIsTranslating={setIsTranslating} 
              />
            </div>

            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runBooleanTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
              isTranslating={isTranslating}
              editorCardRef={editorCardRef}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        isHungry={inputIsHungry}
        setIsHungry={setInputIsHungry}
        hasFood={inputHasFood}
        setHasFood={setInputHasFood}
        handleUpdateVariables={handleUpdateVariables}
      />
    </div>
  );
};

export default BooleanTypes;

import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
      This code iterates through each character in the string greeting using a for loop. 
      It accesses each character by its index and prints it on a new line.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>String Analysis:</b> Process or manipulate strings character by character, such as validating passwords or parsing text.</li>
            <li><b>Text-to-Speech Systems:</b> Feed individual characters or phonemes to voice synthesis systems for precise pronunciation.</li>
            <li><b>Animation Effects:</b> Display characters one at a time to create typing or scrolling text effects in user interfaces.</li>
            <li><b>Educational Tools:</b> Teach string indexing and looping constructs in programming.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

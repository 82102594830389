import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This program demonstrates the creation and basic functionality of a <b>Singly Linked List</b>. 
        It includes a Node class to represent elements of the list and a LinkedList class to manage 
        the list. The insert method appends new elements at the end of the list. This program 
        initializes a linked list and inserts three elements into it.
      </div>
      <div id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Undo Functionality in Text Editors:</b> Linked lists can store a series of actions in a text editor, where each node represents a change, allowing efficient traversal to undo or redo actions.</li>
            <li><b>Music Playlists:</b> In music players, linked lists can represent playlists where each song is a node, allowing easy insertion, deletion, and traversal for seamless playback control.</li>
            <li><b>Navigating Browsers History:</b> Browsers use a doubly linked list to manage the history of visited websites, enabling users to move back and forth between pages easily.</li>
            <li><b>Real-Time Scheduling Systems:</b> Linked lists are used in operating systems to manage processes and tasks dynamically, especially in priority or round-robin scheduling.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This program demonstrates <b>string concatenation</b> by manually appending characters from 
        two strings, to create a new string. The program uses loops to iterate over each character 
        in both strings and combines them into the final concatenated string.
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Creating Sentences:</b> Dynamically building sentences or messages.</li>
            <li><b>Combining Data:</b> Concatenating user inputs, file paths, or query parameters in applications.</li>
            <li><b>Text-Based File Generation:</b> Writing structured documents, such as HTML or CSV, by appending strings dynamically.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

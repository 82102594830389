import React, { useState, useRef } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from './AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';
import LanguageDropdown from '../LanguageDropdown';
import defaultExplanations from './explanations';

import { useIntegerTypes } from './useIntegerTypes';

const IntegerTypes = ({ language, subTopicId }) => {
  const [speakingLang, setSpeakingLang] = useState("en-US");
  const [translatedExplanations, setTranslatedExplanations] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [moonLandingYear, setMoonLandingYear] = useState(1969);
  const [olympicGoldMedals, setOlympicGoldMedals] = useState(23);
  const [highestMountainHeightMeters, setHighestMountainHeightMeters] = useState(8848);
  const [lowestTemperatureRecordedCelsius, setLowestTemperatureRecordedCelsius] = useState(-89);

  const [inputMoonLandingYear, setInputMoonLandingYear] = useState(moonLandingYear);
  const [inputOlympicGoldMedals, setInputOlympicGoldMedals] = useState(olympicGoldMedals);
  const [inputHighestMountainHeightMeters, setInputHighestMountainHeightMeters] = useState(highestMountainHeightMeters);
  const [inputLowestTemperatureRecordedCelsius, setInputLowestTemperatureRecordedCelsius] = useState(lowestTemperatureRecordedCelsius);

  const [breakpoints, setBreakpoints] = useState([]);

  const editorCardRef = useRef(null);

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runIntegerTypes
  } = useIntegerTypes(
    speakingLang,
    translatedExplanations,
    language,
    moonLandingYear,
    olympicGoldMedals,
    highestMountainHeightMeters,
    lowestTemperatureRecordedCelsius,
    breakpoints
  );

  const handleUpdateVariables = () => {
    setMoonLandingYear(inputMoonLandingYear);
    setOlympicGoldMedals(inputOlympicGoldMedals);
    setHighestMountainHeightMeters(inputHighestMountainHeightMeters);
    setLowestTemperatureRecordedCelsius(inputLowestTemperatureRecordedCelsius);

    updateVariables(inputMoonLandingYear, inputOlympicGoldMedals, inputHighestMountainHeightMeters, inputLowestTemperatureRecordedCelsius);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6" ref={editorCardRef}>
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Integer Types Example"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            isRunning={isRunning}
            breakpoints={breakpoints}
            setBreakpoints={setBreakpoints}
            onOpenModal={() => {
              setInputMoonLandingYear(moonLandingYear);
              setInputOlympicGoldMedals(olympicGoldMedals);
              setInputHighestMountainHeightMeters(highestMountainHeightMeters);
              setInputLowestTemperatureRecordedCelsius(lowestTemperatureRecordedCelsius);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <div className="d-flex justify-content-center position-relative">
              <h3 className="section-title text-center flex-grow-1">Canvas</h3>
              <LanguageDropdown
                onLanguageChange={setSpeakingLang}
                onTranslate={setTranslatedExplanations}
                selectedProgrammingLang={language}
                defaultExplanations={defaultExplanations}
                isRunning={isRunning} 
                setIsTranslating={setIsTranslating} 
              />
            </div>

            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runIntegerTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
              isTranslating={isTranslating}
              editorCardRef={editorCardRef}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        moonLandingYear={inputMoonLandingYear}
        olympicGoldMedals={inputOlympicGoldMedals}
        highestMountainHeightMeters={inputHighestMountainHeightMeters}
        lowestTemperatureRecordedCelsius={inputLowestTemperatureRecordedCelsius}
        setMoonLandingYear={setInputMoonLandingYear}
        setOlympicGoldMedals={setInputOlympicGoldMedals}
        setHighestMountainHeightMeters={setInputHighestMountainHeightMeters}
        setLowestTemperatureRecordedCelsius={setInputLowestTemperatureRecordedCelsius}
        onSave={handleUpdateVariables}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default IntegerTypes;

import { useState, useEffect, useRef } from 'react';
import defaultExplanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useBooleanTypes = (speakingLang, translatedExplanations, language, isHungry, hasFood, breakpoints) => {
  const [explanations, setExplanations] = useState(defaultExplanations);
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef, speakingLang);
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  useEffect(() => {
    if (translatedExplanations && language) {
      setExplanations((prevExplanations) => ({
        ...prevExplanations,
        [language]: Object.keys(prevExplanations[language] || {}).reduce((acc, key, index) => {
          acc[key] = translatedExplanations[index] || prevExplanations[language][key];
          return acc;
        }, {}),
      }));
    } else {
      setExplanations(defaultExplanations);
    }
  }, [translatedExplanations, language]);

  const updateVariables = (newIsHungry, newHasFood) => {
    resetState();

     // Dynamically update the code samples
     let updatedCodeSamples = "";
     if (language === "Python") {
      newIsHungry = newIsHungry ? 'True' : 'False';
      newHasFood = newHasFood ? 'True' : 'False';
      
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/is_hungry = (True|False)/, `is_hungry = ${newIsHungry}`)
          .replace(/has_food = (True|False)/, `has_food = ${newHasFood}`)
      );
     } else {
      newIsHungry = newIsHungry ? 'true' : 'false';
      newHasFood = newHasFood ? 'true' : 'false';

      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/isHungry = (true|false)/, `isHungry = ${newIsHungry}`)
          .replace(/hasFood = (true|false)/, `hasFood = ${newHasFood}`)
      );
     }
     
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setArrayVariables({});
    setHighlightedArrayVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runBooleanTypesPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(1);
    let _isHungry = isHungry ? 'True' : 'False';
    setVariables((vars) => ({ ...vars, isHungry: { variable_name: 'is_hungry', value: _isHungry } }));
    setHighlightedVariables(['is_hungry']);
    await customSleep(1);
    setHighlightedVariables([]);

    await highlightLine(2);
    let _hasFood = hasFood ? 'True' : 'False';
    setVariables((vars) => ({ ...vars, hasFood: { variable_name: 'has_food', value: _hasFood } }));
    setHighlightedVariables(['has_food']);
    await customSleep(2);
    setHighlightedVariables([]);

    await highlightLine(4);
    setHighlightedVariables(['is_hungry', 'has_food']);
    await customSleep(4);
    setHighlightedVariables([]);
    if (isHungry && hasFood) {
        await highlightLine(5);
        await logMessage("Time to eat!");
        await customSleep(5);
    } else {
        await highlightLine(6);
        await customSleep(6);

        await highlightLine(7);
        await logMessage("No food or not hungry.");
        await customSleep(7);
    }

    await highlightLine(9);
    await logMessage(`Is hungry: ${_isHungry}`);
    await customSleep(9);

    await highlightLine(10);
    await logMessage(`Has food: ${_hasFood}`);
    await customSleep(10);

    await focusEndLine(10);
    setIsRunning(false);
  };

  const runBooleanTypesJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setVariables((vars) => ({ ...vars, isHungry: { variable_name: 'is_hungry', value: `${isHungry}` } }));
    setHighlightedVariables(['is_hungry']);
    await customSleep(2);
    setHighlightedVariables([]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, hasFood: { variable_name: 'has_food', value: `${hasFood}` } }));
    setHighlightedVariables(['has_food']);
    await customSleep(3);
    setHighlightedVariables([]);

    await highlightLine(5);
    setHighlightedVariables(['is_hungry', 'has_food']);
    await customSleep(5);
    setHighlightedVariables([]);
    if (isHungry && hasFood) {
      await highlightLine(6);
      await logMessage("Time to eat!");
      await customSleep(6);
    } else {
        await highlightLine(7);
        await customSleep(7);

        await highlightLine(8);
        await logMessage("No food or not hungry.");
        await customSleep(8);
    }

    await highlightLine(11);
    await logMessage(`Is hungry: ${isHungry}`);
    await customSleep(11);

    await highlightLine(12);
    await logMessage(`Has food: ${hasFood}`);
    await customSleep(12);

    await focusEndLine(14);
    setIsRunning(false);
  };

  const runBooleanTypesCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, isHungry: { variable_name: 'is_hungry', value: `${isHungry}` } }));
    setHighlightedVariables(['is_hungry']);
    await customSleep(3);
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, hasFood: { variable_name: 'has_food', value: `${hasFood}` } }));
    setHighlightedVariables(['has_food']);
    await customSleep(4);
    setHighlightedVariables([]);

    await highlightLine(6);
    setHighlightedVariables(['is_hungry', 'has_food']);
    await customSleep(6);
    setHighlightedVariables([]);
    if (isHungry && hasFood) {
        await highlightLine(7);
        await logMessage("Time to eat!");
        await customSleep(7);
    } else {
        await highlightLine(8);
        await customSleep(8);

        await highlightLine(9);
        await logMessage("No food or not hungry.");
        await customSleep(9);
    }

    await highlightLine(12);
    await logMessage(`Is hungry: ${isHungry}`);
    await customSleep(12);

    await highlightLine(13);
    await logMessage(`Has food: ${hasFood}`);
    await customSleep(13);

    await highlightLine(14);
    await customSleep(14);

    await focusEndLine(15);
    setIsRunning(false);
  };

  const runBooleanTypes = async () => {
    if (language === 'Python') {
      await runBooleanTypesPython();
    } else if (language === 'Java') {
      await runBooleanTypesJava();
    } else if (language === 'C++') {
      await runBooleanTypesCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    runBooleanTypes
  };
};

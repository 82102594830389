import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This program demonstrates the use of <b>typedef</b>, which creates an alias for a data type. 
        Here, the <b>typedef</b> keyword is used to define CakeCount as an alias for <b>unsigned int</b>.
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Improving Code Readability:</b> Replacing complex data types with simpler, more descriptive aliases (e.g., typedef unsigned int Age for age representation).</li>
            <li><b>Portability:</b> Using aliases to easily adapt code to different platforms or systems.</li>
            <li><b>Custom Units:</b> Defining application-specific units, such as typedef double Distance; for distances in a physics simulation.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

import { useEffect } from 'react';

const useStopSpeech = () => {

    useEffect(() => {
        let interval;

        const stopSpeechIfOnMainTopicPage = () => {
            let attempts = 0;
            interval = setInterval(() => {
                if (window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel();
                    clearInterval(interval);
                }

                attempts++;
                if (attempts >= 20) {
                    clearInterval(interval);
                }
            }, 500);
        };

        stopSpeechIfOnMainTopicPage();

        return () => {
            if (interval) clearInterval(interval);
        };
    }, []);
};

export default useStopSpeech;

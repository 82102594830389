import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This program demonstrates a method to delete a node from a <b>Singly Linked List</b>. 
        The <b>delete</b> method checks if the list is empty, removes the head node if it matches 
        the target data, or traverses the list to find and remove the node with the specified data. 
        If the element isn't found, it informs the user.
      </div>
      <div id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Real-Time Messaging Apps:</b> Linked lists are used to dynamically manage messages in a chat. Deleting a specific message from a conversation is efficiently handled using linked list operations.</li>
            <li><b>Memory Management:</b> In memory allocation systems, linked lists are used to track free and allocated memory blocks. Deleting a memory block (node) ensures resources are efficiently reused.</li>
            <li><b>Playlist Management in Media Players:</b> A linked list can represent a playlist where songs can be added or removed dynamically, ensuring smooth operations like deleting a song from the playlist.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MonacoEditor from "@monaco-editor/react";


const languageOptions = {
  "English": "en-US",
  "हिन्दी": "hi-IN",
  "বাংলা": "bn-BD",
  "Deutsch": "de-DE",
  "español": "es-ES",
  "español de Estados Unidos": "es-US",
  "français": "fr-FR",
  "italiano": "it-IT",
  "日本語": "ja-JP",
  "한국의": "ko-KR",
  "Nederlands": "nl-NL",
  "polski": "pl-PL",
  "português do Brasil": "pt-BR",
  "русский": "ru-RU",
  "普通话（中国大陆）": "zh-CN",
  "粤語（香港）": "zh-HK",
  "國語（臺灣）": "zh-TW"
};

const programmingOptions = {
  "c": "c",
  "cpp": "cpp",
  "java": "java",
  "python": "python"
};

const defaultTopic = "Select Topic";
const topicOptions = {
  "Data types": "Data types",
  "Basic": "Basic",
  "Loops": "Loops",
  "Arrays": "Arrays",
  "String": "String",
  "Recursion": "Recursion",
  "Searching": "Searching",
  "Sorting": "Sorting",
  "Mathematics": "Mathematics",
  "Linked List": "Linked List",
  "Tree": "Tree",
  "Graph": "Graph",
  "Dynamic Programming": "Dynamic Programming",
};

const defaultDifficultyLevel = "Select Difficulty Level";
const difficultyLevelOptions = {
  "Beginner": "Beginner",
  "Intermediate": "Intermediate",
  "Advanced": "Advanced",
};

const AICodeChallenge = () => {
  const [topic, setTopic] = useState(defaultTopic);
  const [difficulty, setDifficulty] = useState(defaultDifficultyLevel);
  const [challenge, setChallenge] = useState(null);
  const [language, setLanguage] = useState("python"); 
  const [code, setCode] = useState("# Write your solution here...");
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState("");
  const [loadingOutput, setLoadingOutput] = useState(false);
  const [loadingReview, setLoadingReview] = useState(false);
  const [editorTheme, setEditorTheme] = useState("vs-dark");
  const [humanLanguage, setHumanLanguage] = useState("English");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSolution, setShowSolution] = useState(false);

  const editorCardRef = useRef(null);
  const outputCardRef = useRef(null);
  const aiCodeReviewDivRef = useRef(null);
  const solutionRef = useRef(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Set an SEO-friendly page title
    document.title = `AI Code Challenge | Coding Canvas`;

    // Set meta description for SEO
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", `Explore interactive tutorials, code samples, and exercises to learn programming visually on Coding Canvas.`);
    }
  }, []);

  const showRegisterModal = () => {
    setShowModal(false);
    const registerModalEvent = new CustomEvent('showRegisterModal');
    window.dispatchEvent(registerModalEvent);
  };

  const toggleTheme = () => {
    setEditorTheme((prevTheme) => (prevTheme === "vs-dark" ? "customTheme" : "vs-dark"));
  };

  const handleTopicChange = (topic) => {
    setTopic(topic);
  };

  const handleDifficultyLevelChange = (difficulty) => {
    setDifficulty(difficulty);
  };

  const handleLanguageChange = (language) => {
    setLanguage(language);

    const languageComments = {
      python: "# Write your solution here...",
      java: "// Write your solution here...",
      c: "// Write your solution here...",
      cpp: "// Write your solution here...",
    };

    setCode(languageComments[language] || "// Write your solution here...");
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleHumanLanguageChange = (language) => {
    setHumanLanguage(language);
  };

  const handleSolutionBtn = () => {
    setShowSolution(!showSolution);
    setTimeout(() => {
      if (solutionRef.current) {
        solutionRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }, 200);
  };

  const fetchChallenge = async () => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      setErrorMessage(
        <>
          VIP Access Only! <a href="/pricing" className="subscribe-link"><span className="badge bg-danger me-2">Subscribe</span></a>to unlock
          or <a href="#" onClick={showRegisterModal} className="subscribe-link"><span className="badge bg-danger me-2">Register</span></a>for 3 free AI challenges!
        </>
      );
      setShowModal(true);
      return;
    }

    if (topic === defaultTopic) {
      setErrorMessage(
        <>
          Please Select Topic From The Dropdown Menu.
        </>
      );
      setShowModal(true);
      return;
    }

    if (difficulty === defaultDifficultyLevel) {
      setErrorMessage(
        <>
          Please Select Difficulty Level From The Dropdown Menu.
        </>
      );
      setShowModal(true);
      return;
    }

    setLoading(true);
    setShowSolution(false);
    setChallenge(null);

    setTimeout(() => {
      if (editorCardRef.current) {
          editorCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 200);

    const data = {
      topic: topic,
      difficulty: difficulty,
      language: language,
      human_language: humanLanguage,
    };

    try {
      const response = await axios.post(`${apiUrl}/api/generate-code-challenge/`, data, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const challengeText = response.data;
      setChallenge(challengeText);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          setErrorMessage(
            <>
              Free limit reached! <a href="/pricing" className="subscribe-link"><span className="badge bg-danger me-2">Subscribe</span></a>to continue!
            </>
          );
        } else if (error.response.status === 429) {
          let retryAfter = error.response?.data?.retry_after;
          if (retryAfter) {
              const hours = Math.floor(retryAfter / 3600);
              const minutes = Math.ceil((retryAfter % 3600) / 60);
              retryAfter = `${hours} hours ${minutes} minutes.`;
          }

          setErrorMessage(`You have reached the daily limit of AI-generated challenges. Please try again after ${retryAfter}`);
        } else {
          setErrorMessage("An error occurred while generating the challenge. Please try again later.");
        }
      } else {
        setErrorMessage("A network error occurred. Please check your connection and try again.");
      }
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle code submission
  const submitCode = async () => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      setShowModal(true);
      return;
    }

    setLoadingOutput(true);
    setOutput("");

    setTimeout(() => {
      if (outputCardRef.current) {
        outputCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 200);

    const data = {
      code: code,
      programming_language: capitalizeFirstLetter(language),
      test_cases: challenge.example_test_cases,
    };

    try {
      const response = await axios.post(`${apiUrl}/api/execute-and-validate-user-submitted-code/`, data, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      setOutput(response.data);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setShowModal(true);
      }
    } finally {
      setLoadingOutput(false);
    }
  };

  const reviewCodeWithAI = async () => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      setShowModal(true);
      return;
    }

    setLoadingReview(true);
    setOutput({ ...output, aiReview: "Reviewing your code, please wait..." });

    setTimeout(() => {
      if (aiCodeReviewDivRef.current) {
        aiCodeReviewDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 200);

    try {
      const response = await axios.post(`${apiUrl}/api/ai-code-review/`, {
        code,
        programming_language: capitalizeFirstLetter(language),
      }, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      setOutput((prev) => ({
        ...prev,
        aiReview: response.data.ai_review || "No specific issues found.",
      }));

      setTimeout(() => {
        if (aiCodeReviewDivRef.current) {
          aiCodeReviewDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 200);
    } catch (error) {
      if (error.response.status === 429) {
        let retryAfter = error.response?.data?.retry_after;
        if (retryAfter) {
            const hours = Math.floor(retryAfter / 3600);
            const minutes = Math.ceil((retryAfter % 3600) / 60);
            retryAfter = `${hours} hours ${minutes} minutes.`;
        }
        setOutput((prev) => ({
          ...prev,
          aiReview: `You have reached the daily limit for Review Code With AI. Please try again after ${retryAfter}`,
        }));
      } else {
        setOutput((prev) => ({
          ...prev,
          aiReview: "Failed to fetch AI review. Try again later.",
        }));
      }
    } finally {
      setLoadingReview(false);
    }
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6" ref={editorCardRef}>
          {/* Code Editor */}
          <div className="ai-code-challenge-editor-card shadow-lg p-3 position-relative">
            <div className="d-flex justify-content-center position-relative">
              <h3 className="section-title">Code Editor</h3>
              <div className="dropdown position-absolute end-0">
                <button
                  className="btn dropdown-toggle border-0"
                  type="button"
                  id="canvasDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  disabled={loading}
                >
                  {capitalizeFirstLetter(language)}
                </button>
                <ul className="dropdown-menu dropdown-menu-end language-dropdown-menu" aria-labelledby="canvasDropdown">
                  {Object.keys(programmingOptions).map((language) => (
                    <li
                      key={language}
                      className="dropdown-item language-dropdown-menu"
                      onClick={() => handleLanguageChange(language)}
                    >
                      {capitalizeFirstLetter(language)}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6" style={{ paddingTop: "15px" }}>
                <button 
                  className={`btn btn-lg toggle-btn position-absolute ${editorTheme === "vs-dark" ? 'editor-theme-toggle-btn-on' : 'editor-theme-toggle-btn-off'}`} 
                  onClick={toggleTheme}
                >
                  <div className="toggle-switch">
                    <div className={`toggle-knob ${editorTheme === "vs-dark" ? "toggle-knob-on" : "toggle-knob-off"}`}></div>
                  </div>
                  <span className="toggle-label">
                    {editorTheme === "vs-dark" ? "Dark" : "Light"}
                  </span>
                </button>
              </div>
              <div className="col-md-6 d-flex" style={{ paddingBottom: "15px", paddingTop: "5px" }}>
                <button
                  className="modern-btn ms-auto"
                  onClick={submitCode}
                  disabled={!code || !challenge?.example_test_cases}
                >
                  Submit Code
                </button>
              </div>
            </div>
            <MonacoEditor
              language={language}
              theme={editorTheme}
              value={code}
              onChange={(value) => setCode(value)}
              options={{
                minimap: { enabled: false },
              }}
              className="monaco-editor-wrapper"
              onMount={(editor, monaco) => {
                monaco.editor.defineTheme("customTheme", {
                  base: "vs",
                  inherit: true,
                  rules: [],
                  colors: {
                    "editor.background": "#f0f0f0",
                    "editor.foreground": "#000000",
                    "editorLineNumber.foreground": "#888888",
                    "editor.selectionBackground": "#d6d6d6",
                  },
                });
              }}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div className="ai-code-challenge-editor-card shadow-lg p-3 position-relative">
            <h3 className="section-title">Challenge</h3>
            <div className="dropdown position-absolute end-0">
              <button
                className="btn dropdown-toggle border-0"
                type="button"
                id="canvasDropdown1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                disabled={loading}
              >
                {humanLanguage}
              </button>
              <ul className="dropdown-menu language-dropdown-menu" aria-labelledby="canvasDropdown1">
                {Object.keys(languageOptions).map((language) => (
                  <li
                    key={language}
                    className="dropdown-item language-dropdown-menu"
                    onClick={() => handleHumanLanguageChange(language)}
                  >
                    {language}
                  </li>
                ))}
              </ul>
            </div>
            <div className="visualization-controls d-flex justify-content-between align-items-center mb-3 gap-2">
              <div className="dropdown">
                <button
                  className="btn border dropdown-toggle w-100 text-start ai-challenge-dropdown"
                  type="button"
                  id="canvasDropdown2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  disabled={loading}
                >
                  {topic}
                </button>
                <ul 
                  className="dropdown-menu language-dropdown-menu ai-challenge-dropdown-ul"
                  aria-labelledby="canvasDropdown2"
                >
                  {Object.keys(topicOptions).map((topic) => (
                    <li
                      key={topic}
                      className="dropdown-item language-dropdown-menu"
                      onClick={() => handleTopicChange(topic)}
                    >
                      {topic}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="dropdown">
                <button
                  className="btn border dropdown-toggle w-100 text-start ai-challenge-dropdown"
                  type="button"
                  id="canvasDropdown3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  disabled={loading}
                >
                  {difficulty}
                </button>
                <ul
                  className="dropdown-menu language-dropdown-menu ai-challenge-dropdown-ul"
                  aria-labelledby="canvasDropdown3"
                >
                  {Object.keys(difficultyLevelOptions).map((difficulty) => (
                    <li
                      key={difficulty}
                      className="dropdown-item language-dropdown-menu"
                      onClick={() => handleDifficultyLevelChange(difficulty)}
                    >
                      {difficulty}
                    </li>
                  ))}
                </ul>
              </div>
              <button
                onClick={fetchChallenge}
                className="ai-challenge-btn"
                disabled={loading}
              >
                ⚡ Generate Challenge
              </button>
            </div>

            <div className="visualization-canvas ai-challenge-div"
              style={
                challenge
                  ? { overflow: 'auto', display: 'block' }
                  : {}
              }
            >
              {challenge ? (
                <div >
                  <div className="ai-code-heading">
                    <span className="font-15">📜</span> Problem Statement
                  </div>
                  <pre className="ai-code-pre">
                    {challenge.problem_statement}
                  </pre>
      
                  <div className="ai-code-heading">
                    <span className="font-15">🔢</span> Input
                  </div>
                  <pre className="ai-code-pre">
                    {challenge.input}
                  </pre>
      
                  <div className="ai-code-heading">
                    <span className="font-15">🎯</span> Output
                  </div>
                  <pre className="ai-code-pre">
                    {challenge.output}
                  </pre>

                  <div className="ai-code-heading">
                    <span className="font-15">📏</span> Constraints
                  </div>
                  <pre className="ai-code-pre">
                    {challenge.constraints}
                  </pre>
      
                  <div className="ai-code-heading">
                    <span className="font-15">🧩</span> Example Test Cases
                  </div>
                  <pre className="ai-code-pre">
                    {challenge.example_test_cases.map((testCase, index) => (
                      <div key={index}>
                        <strong>Test Case {index + 1}:</strong>
                        <br />
                        <strong>Input:</strong>{" "}
                        {Array.isArray(testCase.input) ? testCase.input.join(", ") : testCase.input}
                        <br />
                        <strong>Output:</strong>{" "}
                        {Array.isArray(testCase.output) ? testCase.output.join(", ") : testCase.output}
                        <br />
                        <strong>Explanation:</strong> {testCase.explanation}
                        <br />
                        <br />
                      </div>
                    ))}
                  </pre>
      
                  <div className="ai-code-heading">
                    <span className="font-15">💡</span> Hint
                  </div>
                  <pre className="ai-code-pre">
                    {challenge.hint}
                  </pre>

                  <div className="ai-code-heading" onClick={handleSolutionBtn} style={{ cursor: "pointer" }}>
                    <span className="font-15">{showSolution ? "⬆️" : "⬇️"}</span> Solution
                  </div>

                  {showSolution && <pre className="ai-code-pre" ref={solutionRef}>{atob(challenge.solution)}</pre>}
                </div>
              ) : <div
                    style={{textAlign: 'center', position: 'relative'}}
                  >
                    {loading ? 
                      <ul className="ai-loader">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                      : <div>Click the <strong>Generate Challenge</strong> button to get your challenge! 🚀</div>
                    }
                  </div>
              }
            </div>
          </div>
        </div>
      </div>

      {/* Results Display */}
      
      <div className="p-4 border rounded-lg bg-light mt-4" ref={outputCardRef}>
        {output 
          ? (
            <div>
              <h4 className="font-weight-bold">Test Case Results</h4>
              <table className="table table-bordered mt-3">
                <thead className="thead-dark">
                  <tr>
                    <th>Input</th>
                    <th>Expected Output</th>
                    <th>Actual Output</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {output.results.map((test, index) => (
                    <tr key={index}>
                      <td>{test.input}</td>
                      <td>{test.expected_output}</td>
                      <td>{test.actual_output}</td>
                      <td>
                        {test.passed ? (
                          <span className="text-success font-weight-bold">✅ Pass</span>
                        ) : (
                          <span className="text-danger font-weight-bold">❌ Fail</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* AI Code Review Button */}
              <div className="d-flex justify-content-end mt-3">
                <button
                  className="generate-challenge-btn modern-btn"
                  onClick={reviewCodeWithAI}
                  disabled={!output || loadingReview}
                >
                  {loadingReview ? "Reviewing..." : "Review Code with AI"}
                </button>
              </div>

              {output?.aiReview && (
                <div className="alert alert-secondary mt-3" ref={aiCodeReviewDivRef}>
                  <h5>🧐 AI Code Review</h5>
                  <pre>
                    {output.aiReview}
                    {loadingReview &&
                      <div className="text-center my-2">
                        <ul className="ai-loader">
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
                      </div>
                    }
                    {
                      !loadingReview &&
                      <div style={{color: '#3f0101', paddingTop: '15px'}}>
                        <strong>Warning:</strong> Implementing AI feedback may cause test case failures. This feedback is intended as a best practice to help you learn, apply, and grow.
                      </div>
                    }
                  </pre>
                </div>
              )}

            </div>)
          :
            <div
              style={{textAlign: 'center'}}
            >
              {loadingOutput ? 
                <div className="text-center my-2">
                  <div className="spinner-border" role="status"></div>
                </div>
                : <div>Your code's results and AI feedback will be displayed here!</div>
              }
            </div>
        }
      </div>

      {/* Modal for Error Display */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-glass-avatar">
              <div className="modal-header"></div>
              <div className="modal-body">
              <div
                className={`user-progress-animation-container user-progress-failure`}
              >
                <div className="user-progress-circle">
                  <i className="fas fa-times-circle user-progress-cross-mark"></i>
                </div>
                <p
                  className={`user-progress-fail-message`}
                >
                  {errorMessage}
                </p>
              </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AICodeChallenge;

// Python Stack Algorithm
export const stackPython = async (highlightLine, setVariables, setArrayVariables, setStackVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedStackVariables, setHighlightedIndices, setHighlightedStackIndex, highlightCallingLine, customSleep, logMessage) => {
    let size;
    let tos = -1;
    let stack = [];
  
    const init = async (_size) => {
      await highlightLine(1);
      setVariables((vars) => ({ ...vars, _size: { variable_name: '_size', value: _size } }));
      setHighlightedVariables(['_size']);
      await customSleep(1);
      setHighlightedVariables([]);

      await highlightLine(2);
      size = _size - 1;
      setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
      setHighlightedVariables(['size', '_size']);
      await customSleep(2);
      setHighlightedVariables([]);

      await highlightLine(3);
      stack = Array(_size).fill("");
      setStackVariables({ stack: { variable_name: 'stack', value: stack } });
      setHighlightedStackVariables(['stack']);
      await customSleep(3);
      setHighlightedStackVariables([]);

      await highlightLine(4);
      tos = -1;
      setVariables((vars) => ({ ...vars, tos: { variable_name: 'tos', value: tos } }));
      setHighlightedVariables(['tos']);
      await customSleep(4);
      setHighlightedVariables([]);
    };
  
    const push = async (data) => {

      await highlightLine(6);
      setVariables((vars) => ({ ...vars, data: { variable_name: 'data', value: data } }));
      setHighlightedVariables(['data']);
      await customSleep(6);
      setHighlightedVariables([]);

      await highlightLine(7);
      setHighlightedVariables(['tos', 'size']);
      await customSleep(7);
      if (tos < size) {
        await highlightLine(8);
        setHighlightedVariables(['tos']);
        tos++;
        setVariables((vars) => ({ ...vars, tos: { variable_name: 'tos', value: tos } }));
        await customSleep(8);
        setHighlightedVariables([]);

        await highlightLine(9);
        setHighlightedVariables(['tos', 'data']);
        setHighlightedStackIndex([{ stackName: 'stack', index: tos, iteratorName: 'tos' }]);
        stack[tos] = data;
        await customSleep(9);
        setHighlightedVariables([]);
        setHighlightedStackIndex([]);

        await highlightLine(10);
        await customSleep(10);
        await highlightCallingLine(10);
        await display();
        await highlightLine(10, "Returning from the display method.");
        await customSleep(10);
      } else {
        await highlightLine(11);
        await customSleep(11);

        await highlightLine(12);
        await logMessage("Stack is Full");
        await customSleep(12);
      }
    };
  
    const pop = async () => {
      await highlightLine(14);
      await customSleep(14);

      await highlightLine(15);
      setHighlightedVariables(['tos']);
      await customSleep(15);
      setHighlightedVariables([]);
      if (tos < 0) {
        await highlightLine(16);
        await logMessage("Stack is Empty");
        await customSleep(16);
      } else {
        await highlightLine(17);
        await customSleep(17);

        await highlightLine(18);
        setHighlightedStackIndex([{ stackName: 'stack', index: tos, iteratorName: 'tos' }]);
        stack[tos] = null;
        await customSleep(18);
        setHighlightedStackIndex([]);

        await highlightLine(19);
        setHighlightedVariables(['tos']);
        tos--;
        setVariables((vars) => ({ ...vars, tos: { variable_name: 'tos', value: tos } }));
        await customSleep(19);
        setHighlightedVariables([]);

        await highlightLine(20);
        await customSleep(20);
        await highlightCallingLine(20);
        await display();
        await highlightLine(20, "Returning from the display method.");
        await customSleep(20);
      }
    };
  
    const display = async () => {
      await highlightLine(22);
      await customSleep(22);

      for (let i = tos; i >= 0; i--) {
        await highlightLine(23);
        setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
        setHighlightedVariables(['i', 'tos']);
        await customSleep(23);
        setHighlightedVariables([]);

        await highlightLine(24);
        setHighlightedVariables(['i']);
        setHighlightedStackIndex([{ stackName: 'stack', index: i, iteratorName: 'i' }]);
        await logMessage(`${stack[i]}`);
        await customSleep(24);
        setHighlightedVariables([]);
        setHighlightedStackIndex([]);
      }

      await highlightLine(25);
      await logMessage("-----");
      await customSleep(25);
    };
  
    return { init, push, pop };
  };
  
  // Java Stack Algorithm
  export const stackJava = async (highlightLine, setVariables, setArrayVariables, setStackVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedStackVariables, setHighlightedIndices, setHighlightedStackIndex, highlightCallingLine, customSleep, logMessage) => {
    let size;
    let tos = -1;
    let stack = [];
  
    const init = async (_size) => {
      await highlightLine(5);
      setVariables((vars) => ({ ...vars, _size: { variable_name: '_size', value: _size } }));
      setHighlightedVariables(['_size']);
      await customSleep(5);
      setHighlightedVariables([]);

      await highlightLine(6);
      size = _size - 1;
      setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
      setHighlightedVariables(['size', '_size']);
      await customSleep(6);
      setHighlightedVariables([]);

      await highlightLine(7);
      stack = Array(_size).fill("");
      setStackVariables({ stack: { variable_name: 'stack', value: stack } });
      setHighlightedStackVariables(['stack']);
      await customSleep(7);
      setHighlightedStackVariables([]);

      await highlightLine(8);
      tos = -1;
      setVariables((vars) => ({ ...vars, tos: { variable_name: 'tos', value: tos } }));
      setHighlightedVariables(['tos']);
      await customSleep(8);
      setHighlightedVariables([]);
    };
  
    const push = async (data) => {

      await highlightLine(11);
      setVariables((vars) => ({ ...vars, data: { variable_name: 'data', value: data } }));
      setHighlightedVariables(['data']);
      await customSleep(11);
      setHighlightedVariables([]);

      await highlightLine(12);
      setHighlightedVariables(['tos', 'size']);
      await customSleep(12);
      if (tos < size) {
        await highlightLine(13);
        setHighlightedVariables(['tos']);
        tos++;
        setVariables((vars) => ({ ...vars, tos: { variable_name: 'tos', value: tos } }));
        await customSleep(13);
        setHighlightedVariables([]);

        await highlightLine(14);
        setHighlightedVariables(['tos', 'data']);
        setHighlightedStackIndex([{ stackName: 'stack', index: tos, iteratorName: 'tos' }]);
        stack[tos] = data;
        await customSleep(14);
        setHighlightedVariables([]);
        setHighlightedStackIndex([]);

        await highlightLine(15);
        await customSleep(15);
        await highlightCallingLine(15);
        await display();
        await highlightLine(15, "Returning from the display method.");
        await customSleep(15);
      } else {
        await highlightLine(16);
        await customSleep(16);

        await highlightLine(17);
        await logMessage("Stack is Full");
        await customSleep(17);
      }
    };
  
    const pop = async () => {
      await highlightLine(21);
      await customSleep(21);

      await highlightLine(22);
      setHighlightedVariables(['tos']);
      await customSleep(22);
      setHighlightedVariables([]);
      if (tos < 0) {
        await highlightLine(23);
        await logMessage("Stack is Empty");
        await customSleep(23);
      } else {
        await highlightLine(24);
        await customSleep(24);

        await highlightLine(25);
        setHighlightedStackIndex([{ stackName: 'stack', index: tos, iteratorName: 'tos' }]);
        stack[tos] = 0;
        await customSleep(25);
        setHighlightedStackIndex([]);

        await highlightLine(26);
        setHighlightedVariables(['tos']);
        tos--;
        setVariables((vars) => ({ ...vars, tos: { variable_name: 'tos', value: tos } }));
        await customSleep(26);
        setHighlightedVariables([]);

        await highlightLine(27);
        await customSleep(27);
        await highlightCallingLine(27);
        await display();
        await highlightLine(27, "Returning from the display method.");
        await customSleep(27);
      }
    };
  
    const display = async () => {
      await highlightLine(31);
      await customSleep(31);

      for (let i = tos; i >= 0; i--) {
        await highlightLine(32);
        setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
        setHighlightedVariables(['i', 'tos']);
        await customSleep(32);
        setHighlightedVariables([]);

        await highlightLine(33);
        setHighlightedVariables(['i']);
        setHighlightedStackIndex([{ stackName: 'stack', index: i, iteratorName: 'i' }]);
        await logMessage(`${stack[i]}`);
        await customSleep(33);
        setHighlightedVariables([]);
        setHighlightedStackIndex([]);
      }

      await highlightLine(35);
      await logMessage("-----");
      await customSleep(35);
    };
  
    return { init, push, pop };
  };
  
  // C Stack Algorithm
  export const stackC = async (highlightLine, setVariables, setArrayVariables, setStackVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedStackVariables, setHighlightedIndices, setHighlightedStackIndex, highlightCallingLine, customSleep, logMessage) => {
    let tos = -1;
    let stack = [];
    let size = 3;

    let MAX = 3;
  
    const init = async () => {
      await highlightLine(3);
      setVariables((vars) => ({ ...vars, MAX: { variable_name: 'MAX', value: MAX } }));
      setHighlightedVariables(['MAX']);
      await customSleep(3);
      setHighlightedVariables([]);

      await highlightLine(5);
      stack = Array(MAX).fill("");
      setHighlightedVariables(['MAX']);
      setStackVariables({ stack: { variable_name: 'stack', value: stack } });
      setHighlightedStackVariables(['stack']);
      await customSleep(5);
      setHighlightedStackVariables([]);
      setHighlightedVariables([]);

      await highlightLine(6);
      tos = -1;
      setVariables((vars) => ({ ...vars, tos: { variable_name: 'tos', value: tos } }));
      setHighlightedVariables(['tos']);
      await customSleep(6);
      setHighlightedVariables([]);
    };
  
    const push = async (value) => {

      await highlightLine(9);
      setVariables((vars) => ({ ...vars, value: { variable_name: 'value', value: value } }));
      setHighlightedVariables(['value']);
      await customSleep(9);
      setHighlightedVariables([]);

      await highlightLine(10);
      setHighlightedVariables(['tos', 'MAX']);
      await customSleep(10);
      setHighlightedVariables([]);
      if (tos === MAX - 1) {
        await highlightLine(11);
        await logMessage("Stack Overflow");
        await customSleep(11);
      } else {
        await highlightLine(12);
        await customSleep(12);

        await highlightLine(13);
        setHighlightedVariables(['tos', 'value']);
        tos += 1;
        setVariables((vars) => ({ ...vars, tos: { variable_name: 'tos', value: tos } }));
        setHighlightedStackIndex([{ stackName: 'stack', index: tos, iteratorName: 'tos' }]);
        stack[tos] = value;
        await customSleep(13);
        setHighlightedVariables([]);
        setHighlightedStackIndex([]);
      }
    };
  
    const pop = async () => {
      await highlightLine(18);
      await customSleep(18);

      await highlightLine(19);
      setHighlightedVariables(['tos']);
      await customSleep(19);
      setHighlightedVariables([]);
      if (tos === -1) {
        await highlightLine(20);
        await logMessage("Stack Underflow");
        await customSleep(20);
      } else {
        await highlightLine(21);
        await customSleep(21);

        await highlightLine(22);
        setHighlightedStackIndex([{ stackName: 'stack', index: tos, iteratorName: 'tos' }]);
        setHighlightedVariables(['tos']);
        stack[tos] = 0;
        tos -= 1;
        setVariables((vars) => ({ ...vars, tos: { variable_name: 'tos', value: tos } }));
        await customSleep(22);
        setHighlightedStackIndex([]);
        setHighlightedVariables([]);
      }
    };
  
    const display = async () => {
      await highlightLine(27);
      await customSleep(27);

      await highlightLine(28);
      setHighlightedVariables(['tos']);
      await customSleep(28);
      setHighlightedVariables([]);
      if (tos === -1) {
        await highlightLine(29);
        await logMessage("Stack is empty");
        await customSleep(29);
      } else {
        await highlightLine(30);
        await customSleep(30);

        await highlightLine(31);
        await logMessage("Stack elements are:");
        await customSleep(31);

        for (let i = tos; i >= 0; i--) { 
          await highlightLine(32);
          setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
          setHighlightedVariables(['i', 'tos']);
          await customSleep(32);
          setHighlightedVariables([]);

          await highlightLine(33);
          setHighlightedVariables(['i']);
          setHighlightedStackIndex([{ stackName: 'stack', index: i, iteratorName: 'i' }]);
          await logMessage(`${stack[i]}`);
          await customSleep(33);
          setHighlightedVariables([]);
          setHighlightedStackIndex([]);
        }
      }
    };
  
    return { init, push, pop, display };
  };
  
  // C++ Stack Algorithm
  export const stackCpp = async (highlightLine, setVariables, setArrayVariables, setStackVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedStackVariables, setHighlightedIndices, setHighlightedStackIndex, highlightCallingLine, customSleep, logMessage) => {
    let tos = -1;
    let stack = [];

    let MAX = 3;
  
    const init = async (size) => {
      await highlightLine(3);
      MAX = size;
      setVariables((vars) => ({ ...vars, MAX: { variable_name: 'MAX', value: MAX } }));
      setHighlightedVariables(['MAX']);
      await customSleep(3);
      setHighlightedVariables([]);

      await highlightLine(7);
      stack = Array(MAX).fill("");
      setHighlightedVariables(['MAX']);
      setStackVariables({ stack: { variable_name: 'stack', value: stack } });
      setHighlightedStackVariables(['stack']);
      await customSleep(7);
      setHighlightedStackVariables([]);
      setHighlightedVariables([]);

      await highlightLine(8);
      setVariables((vars) => ({ ...vars, tos: { variable_name: 'tos', value: "" } }));
      setHighlightedVariables(['tos']);
      await customSleep(8);
      setHighlightedVariables([]);

      await highlightLine(10);
      await customSleep(10);
      
      await highlightLine(11);
      tos = -1;
      setVariables((vars) => ({ ...vars, tos: { variable_name: 'tos', value: tos } }));
      setHighlightedVariables(['tos']);
      await customSleep(11);
      setHighlightedVariables([]);
    };
  
    const push = async (value) => {

      await highlightLine(14);
      setVariables((vars) => ({ ...vars, value: { variable_name: 'value', value: value } }));
      setHighlightedVariables(['value']);
      await customSleep(14);
      setHighlightedVariables([]);

      await highlightLine(15);
      setHighlightedVariables(['tos', 'MAX']);
      await customSleep(15);
      setHighlightedVariables([]);
      if (tos === MAX - 1) {
        await highlightLine(16);
        await logMessage("Stack Overflow");
        await customSleep(16);
      } else {
        await highlightLine(17);
        await customSleep(17);

        await highlightLine(18);
        setHighlightedVariables(['tos', 'value']);
        tos += 1;
        setVariables((vars) => ({ ...vars, tos: { variable_name: 'tos', value: tos } }));
        setHighlightedStackIndex([{ stackName: 'stack', index: tos, iteratorName: 'tos' }]);
        stack[tos] = value;
        await customSleep(18);
        setHighlightedVariables([]);
        setHighlightedStackIndex([]);
      }
    };
  
    const pop = async () => {
      await highlightLine(22);
      await customSleep(22);

      await highlightLine(23);
      setHighlightedVariables(['tos']);
      await customSleep(23);
      setHighlightedVariables([]);
      if (tos === -1) {
        await highlightLine(24);
        await logMessage("Stack Underflow");
        await customSleep(24);
      } else {
        await highlightLine(25);
        await customSleep(25);

        await highlightLine(26);
        setHighlightedStackIndex([{ stackName: 'stack', index: tos, iteratorName: 'tos' }]);
        setHighlightedVariables(['tos']);
        stack[tos] = 0;
        tos -= 1;
        setVariables((vars) => ({ ...vars, tos: { variable_name: 'tos', value: tos } }));
        await customSleep(26);
        setHighlightedStackIndex([]);
        setHighlightedVariables([]);
      }
    };
  
    const display = async () => {
      await highlightLine(30);
      await customSleep(30);

      await highlightLine(32);
      setHighlightedVariables(['tos']);
      await customSleep(32);
      setHighlightedVariables([]);
      if (tos === -1) {
        await highlightLine(32);
        await logMessage("Stack is empty");
        await customSleep(32);
      } else {
        await highlightLine(33);
        await customSleep(33);

        await highlightLine(34);
        await logMessage("Stack elements are:");
        await customSleep(34);

        for (let i = tos; i >= 0; i--) { 
          await highlightLine(35);
          setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
          setHighlightedVariables(['i', 'tos']);
          await customSleep(35);
          setHighlightedVariables([]);

          await highlightLine(36);
          setHighlightedVariables(['i']);
          setHighlightedStackIndex([{ stackName: 'stack', index: i, iteratorName: 'i' }]);
          await logMessage(`${stack[i]}`);
          await customSleep(36);
          setHighlightedVariables([]);
          setHighlightedStackIndex([]);
        }
      }
    };
  
    return { init, push, pop, display };
  };
  
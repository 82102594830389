import React, { useState, useRef } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from './AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';
import LanguageDropdown from '../LanguageDropdown';
import defaultExplanations from './explanations';

import { useValueInterchange } from './useValueInterchange';

const ValueInterchange = ({ language, subTopicId }) => {
  const [speakingLang, setSpeakingLang] = useState("en-US");
  const [translatedExplanations, setTranslatedExplanations] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [initialA, setInitialA] = useState(5);
  const [initialB, setInitialB] = useState(10);

  const [inputInitialA, setInputInitialA] = useState(initialA);
  const [inputInitialB, setInputInitialB] = useState(initialB);

  const [breakpoints, setBreakpoints] = useState([]);

  const editorCardRef = useRef(null);

  const {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runValueInterchange
  } = useValueInterchange(speakingLang, translatedExplanations, language, initialA, initialB, breakpoints);

  const handleUpdateVariables = () => {
    setInitialA(inputInitialA);
    setInitialB(inputInitialB);
    
    updateVariables(inputInitialA, inputInitialB);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6" ref={editorCardRef}>
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Value Interchange"}
            subTopicId={subTopicId}
            language={language}
            isRunning={isRunning}
            breakpoints={breakpoints}
            setBreakpoints={setBreakpoints}
            onOpenModal={() => {
              setInputInitialA(initialA);
              setInputInitialB(initialB);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <div className="d-flex justify-content-center position-relative">
              <h3 className="section-title text-center flex-grow-1">Canvas</h3>
              <LanguageDropdown
                onLanguageChange={setSpeakingLang}
                onTranslate={setTranslatedExplanations}
                selectedProgrammingLang={language}
                defaultExplanations={defaultExplanations}
                isRunning={isRunning} 
                setIsTranslating={setIsTranslating} 
              />
            </div>

            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runValueInterchange}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
              isTranslating={isTranslating}
              editorCardRef={editorCardRef}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        initialA={inputInitialA}
        initialB={inputInitialB}
        setInitialA={setInputInitialA}
        setInitialB={setInputInitialB}
        onSave={handleUpdateVariables}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default ValueInterchange;

import React, { useState } from "react";
import axios from 'axios';

const languageOptions = {
  "English": "en-US",
  "हिन्दी": "hi-IN",
  // "বাংলা": "bn-BD",
  "Deutsch": "de-DE",
  "español": "es-ES",
  "español de Estados Unidos": "es-US",
  "français": "fr-FR",
  "Bahasa Indonesia": "id-ID",
  "italiano": "it-IT",
  "日本語": "ja-JP",
  "한국의": "ko-KR",
  "Nederlands": "nl-NL",
  "polski": "pl-PL",
  "português do Brasil": "pt-BR",
  "русский": "ru-RU",
  "普通话（中国大陆）": "zh-CN",
  "粤語（香港）": "zh-HK",
  "國語（臺灣）": "zh-TW"
};

const LanguageDropdown = ({ onLanguageChange, onTranslate, selectedProgrammingLang, defaultExplanations, isRunning, setIsTranslating }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('userToken');

  const handleLanguageChange = async (language) => {
    if (isRunning) return;

    if (!token) {
      setErrorMessage("VIP access only. Log in to unlock the magic!");
      setShowModal(true);
      return;
    }

    setIsTranslating(true);
    setSelectedLanguage(language);

    if (language !== "English" && selectedProgrammingLang) {
      const explanationLines = Object.values(defaultExplanations[selectedProgrammingLang] || {})
        .join("\n");

      try {
        const response = await axios.post(
          `${apiUrl}/api/open-ai-translate/`,
          {
            message: explanationLines,
            language: language,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.translated_lines) {
          setSelectedLanguage(language);
          const langCode = languageOptions[language];
          onLanguageChange(langCode);

          onTranslate(response.data.translated_lines);
        }
      } catch (error) {
        console.error("Translation API error:", error);

        const language = "English";
        setSelectedLanguage(language);
        const langCode = languageOptions[language];
        onLanguageChange(langCode);

        setErrorMessage("Failed to translate. Please try again.");
        setShowModal(true);
      }
    } else {
      setSelectedLanguage(language);
      const langCode = languageOptions[language];
      onLanguageChange(langCode);

      onTranslate(null);
    }

    setIsTranslating(false);
  };

  return (
    <>
      <div className="dropdown position-absolute end-0">
        <button
          className="btn dropdown-toggle border-0"
          type="button"
          id="canvasDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          disabled={isRunning}
        >
          {selectedLanguage}
        </button>
        <ul className="dropdown-menu language-dropdown-menu" aria-labelledby="canvasDropdown">
          {Object.keys(languageOptions).map((language) => (
            <li
              key={language}
              className="dropdown-item language-dropdown-menu"
              onClick={() => handleLanguageChange(language)}
            >
              {language}
            </li>
          ))}
        </ul>
      </div>

      {/* Modal for Error Display */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-glass-avatar">
              <div className="modal-header"></div>
              <div className="modal-body">
              <div
                className={`user-progress-animation-container user-progress-failure`}
              >
                <div className="user-progress-circle">
                  <i className="fas fa-times-circle user-progress-cross-mark"></i>
                </div>
                <p
                  className={`user-progress-fail-message`}
                >
                  {errorMessage}
                </p>
              </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LanguageDropdown;

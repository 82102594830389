import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This algorithm iterates through an array using a for loop.
        It accesses each element by its index, prints the element along with its corresponding index.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Data Analysis:</b> Traverse a list of values (e.g., sales figures, test scores) to display or process each element with its position.</li>
            <li><b>Inventory Management:</b> Display items in a catalog or stock list with their indices for easy reference.</li>
            <li><b>Debugging:</b> Iterate through data structures while developing or troubleshooting programs to verify their contents.</li>
            <li><b>Educational Purposes:</b> Teach basic array traversal and index-based operations in programming tutorials.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

import React, { useState, useEffect } from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import TreeNode from './BinaryTree/TreeNode';
import '../VisualizationCanvas.css';

const VisualizationCanvas = ({ 
  variables,
  rootVariables,
  nodeVariables,
  singleNodeVariable,
  highlightedVariables,
  highlightedRootVariables,
  highlightedNodeVariables,
  highlightedSingleNodeVariables,
  highlightSingleNodeLeftPart,
  highlightSingleNodeDataPart,
  highlightSingleNodeRightPart,
  highlightLeftPart,
  highlightDataPart,
  highlightRightPart,
  highlightNodeAddress,
  stackVariables,
  highlightedStackVariables=[],
  highlightedStackIndex,
  strikeThroughStackIndex,
  language
}) => {
  const [variableAddresses, setVariableAddresses] = useState({});
  const [rootVariablesAddresses, setRootVariablesAddresses] = useState({});

  useEffect(() => {
    const adjustFontSize = () => {
      document.querySelectorAll('.array-value').forEach(element => {
        let fontSize = parseInt(window.getComputedStyle(element).fontSize);
        while (element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight) {
          if (fontSize <= 8) break;
          fontSize--;
          element.style.fontSize = `${fontSize}px`;
        }
      });
    };
    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);
    return () => window.removeEventListener('resize', adjustFontSize);
  }, []);

  useEffect(() => {
    const generateMemoryAddress = () => {
      return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
    };

    // Initialize variable addresses if not already initialized
    setVariableAddresses(prevAddresses => {
      const newAddresses = { ...prevAddresses };
      Object.keys(variables).forEach(key => {
        if (!newAddresses[key]) {
          newAddresses[key] = generateMemoryAddress();
        }
      });
      return newAddresses;
    });

    // Initialize variable addresses if not already initialized
    setRootVariablesAddresses(prevAddresses => {
      const newAddresses = { ...prevAddresses };
      Object.keys(rootVariables).forEach(key => {
        if (!newAddresses[key]) {
          newAddresses[key] = generateMemoryAddress();
        }
      });
      return newAddresses;
    });

  }, [variables, rootVariables]);

  const isStackIndexHighlighted = (stackName, index) => {
    return highlightedStackIndex.some(
      (highlight) => highlight.stackName === stackName && highlight.index === index
    );
  };

  const isStackIndexStrikeThrough = (stackName, index) => {
    return strikeThroughStackIndex?.some(
      (strikeThrough) => strikeThrough.stackName === stackName && strikeThrough.index === index
    ) || false;
  };

  const getStackIteratorName = (stackName, index) => {
    const highlight = highlightedStackIndex.find(
      (highlight) => highlight.stackName === stackName && highlight.index === index
    );

    return highlight && highlight.iteratorName && highlight.iteratorName.length > 0
      ? highlight.iteratorName + " = "
      : "";
  };

  function generateTreeDataFromRoot(nodeVariables, rootVariables) {
    // Extract the root node's address from rootVariables
    const rootAddress = rootVariables.root?.value;
  
    // Helper function to recursively build the tree data
    function buildNode(nodeAddress) {
      const node = nodeVariables[nodeAddress];
      if (!node) return null;
  
      const left = node.value.left ? buildNode(node.value.left.address || node.value.left) : null;
      const right = node.value.right ? buildNode(node.value.right.address || node.value.right) : null;
  
      return {
        id: nodeAddress,
        data: node.value.data,
        address: nodeAddress,
        left: left,
        right: right
      };
    }
  
    // Build tree starting from the root address
    return buildNode(rootAddress);
  }
  
  const treeData = generateTreeDataFromRoot(nodeVariables, rootVariables);

  const isVisualizationEmpty = () => {
    return !(
      (variables && Object.keys(variables).length) ||
      (rootVariables && Object.keys(rootVariables).length) ||
      (nodeVariables && Object.keys(nodeVariables).length) ||
      (singleNodeVariable && Object.keys(singleNodeVariable).length)
    );
  };

  return (
    <div className="visualization-canvas" style={{display: !isVisualizationEmpty() ? 'block' : ''}}>
      <ArcherContainer strokeColor="red">
      {isVisualizationEmpty() ? (
        <p 
          style={{textAlign: 'center'}}
        >Code visualizations will appear here.</p>
      ) : (
        <><div className="variables-container" style={{justifyContent: 'left', paddingLeft: '20px'}}>
          {rootVariables && Object.entries(rootVariables).map(([key, { variable_name, value, child }], index) => (
            <ArcherElement
              id={variable_name}
              key={variable_name}
              relations={
                child 
                ? [
                    {
                      targetId: child,
                      targetAnchor: 'left',
                      sourceAnchor: 'bottom',
                      style: { strokeColor: '#00e676', strokeDasharray: '5,5' },
                    },
                  ]
                : []
              }
            >
              <div key={index} className={`variable-box-wrapper`}>
                <div key={index} className={`variable-box ${highlightedRootVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}>
                <div className="variable-value">{value}</div>
                <div className={`${highlightedRootVariables.includes(variable_name) ? 'variable-name-highlighted' : 'variable-name'}`}>{variable_name}</div>
                </div>
                <div 
                  className={`variable-address ${highlightedRootVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}
                  style={{marginBottom: '0px'}}
                >{rootVariablesAddresses[key]}</div>
              </div>
            </ArcherElement>
          ))}

          <div style={{margin: '0 auto', display: 'flex'}}>
            {variables && Object.entries(variables).map(([key, { variable_name, value }], index) => (
              <div key={index} className={`variable-box-wrapper`}>
                <div key={index} className={`variable-box ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}>
                <div className="variable-value">{value}</div>
                <div className={`${highlightedVariables.includes(variable_name) ? 'variable-name-highlighted' : 'variable-name'}`}>{variable_name}</div>
                </div>
                <div 
                className={`variable-address ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}
                style={{marginBottom: '0px'}}
                >{variableAddresses[key]}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="binary-tree">
          {treeData ? 
            <TreeNode
              {...treeData} 
              language={language}
              highlightedNodeVariables={highlightedNodeVariables}
              highlightLeftPart={highlightLeftPart}
              highlightDataPart={highlightDataPart}
              highlightRightPart={highlightRightPart}
              highlightNodeAddress={highlightNodeAddress}
            /> : null
          }

          <div className="tree-node">
            {singleNodeVariable && Object.entries(singleNodeVariable).map(([key, { variable_name, value, address }], index) => (
                <div key={key}>
                  <div
                    className={`${highlightedSingleNodeVariables.includes(variable_name) ? 'highlighted-node-box' : 'node-box'}`}
                  >
                    <div
                      className={`${highlightSingleNodeLeftPart.includes(variable_name) ? 'highlighted-left-part' : 'left-part'}`}
                    >
                      <div>{value.left}</div>
                    </div>
                    <div
                      className={`${highlightSingleNodeDataPart.includes(variable_name) ? 'highlighted-data-part' : 'data-part'}`}
                    >
                      <div>{value.data}</div>
                    </div>
                    <div
                      className={`${highlightSingleNodeRightPart.includes(variable_name) ? 'highlighted-right-part' : 'right-part'}`}
                    >
                      <div>{value.right}</div>
                    </div>
                  </div>
                  <div
                    className={`${highlightedSingleNodeVariables.includes(variable_name) || highlightNodeAddress.includes(variable_name) ? 'highlighted-tree-node-address' : 'tree-node-address'}`}
                  >{address}</div>
              </div>
            ))}
          </div>
        </div>


        <div style={{display: 'flex', paddingTop: '40px'}}>
          {stackVariables && Object.entries(stackVariables).map(([stackName, stackData], stackIndex) => (
            <div 
              key={stackIndex} 
              style={{marginLeft: '40px'}}
              className={`${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-container' : 'stack-container'}`}
            >
              <div className={`${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-top-row' : 'stack-top-row'}`}
              >index</div>
              <div style={{}} 
              className={`${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-top-cell' : 'stack-top-cell'}`}
              >value</div>
              {[...stackData.value].reverse().map((value, valueIndex) => (
                <div key={`map-${valueIndex}`} style={{ display: 'contents' }}>
                  <div 
                    style={{whiteSpace: 'nowrap'}}
                    className={
                      `${isStackIndexHighlighted(stackName, stackData.value.length - 1 - valueIndex) ? 'highlighted-stack-row' : 'stack-row'} 
                      ${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-row' : 'stack-row'}`
                    }
                  >
                  {getStackIteratorName(stackName, stackData.value.length - 1 - valueIndex)}{stackData.value.length - 1 - valueIndex}
                  </div>
                  <div 
                    style={{minWidth: '40px', textAlign:'center'}}
                    className={
                      `${isStackIndexHighlighted(stackName, stackData.value.length - 1 - valueIndex) ? 'highlighted-stack-cell' : 'stack-cell'} 
                      ${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-cell' : 'stack-cell'}
                      ${isStackIndexStrikeThrough(stackName, stackData.value.length - 1 - valueIndex) ? 'line-through' : ''}`
                    }
                  >
                    {value}
                  </div>
                </div>
              ))}
              <div className={`${highlightedStackVariables.includes(stackName) ? 'highlighted-dictionary-cell merged' : 'dictionary-cell merged'}`}>
                {stackName}
              </div>
            </div>
          ))}
        </div></>
      )}
      </ArcherContainer>
    </div>
  );
};

export default VisualizationCanvas;

import React, { useState, useEffect } from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import '../VisualizationCanvas.css';

const VisualizationCanvas = ({ 
  variables,
  stringVariables,
  unionVariables,
  highlightedVariables,
  highlightedStringVariables=[],
  highlightedUnionVariables = [],
  highlightedStringIndices
}) => {
  const [variableAddresses, setVariableAddresses] = useState({});
  const [stringAddresses, setStringAddresses] = useState({});
  const [unionVariableAddresses, setUnionVariableAddresses] = useState({});

  useEffect(() => {
    const adjustFontSize = () => {
      document.querySelectorAll('.array-value').forEach(element => {
        let fontSize = parseInt(window.getComputedStyle(element).fontSize);
        while (element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight) {
          if (fontSize <= 8) break;
          fontSize--;
          element.style.fontSize = `${fontSize}px`;
        }
      });
    };
    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);
    return () => window.removeEventListener('resize', adjustFontSize);
  }, []);

  useEffect(() => {
    const generateMemoryAddress = () => {
      return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
    };

    // Initialize variable addresses if not already initialized
    setVariableAddresses(prevAddresses => {
      const newAddresses = { ...prevAddresses };
      Object.keys(variables).forEach(key => {
        if (!newAddresses[key]) {
          newAddresses[key] = generateMemoryAddress();
        }
      });
      return newAddresses;
    });

    // Initialize unionVariable addresses if not already initialized
    setUnionVariableAddresses(prevAddresses => {
      if (unionVariables && typeof unionVariables === 'object') {
        const newAddresses = { ...prevAddresses };
        Object.keys(unionVariables).forEach(key => {
          if (!newAddresses[key]) {
            newAddresses[key] = generateMemoryAddress();
          }
        });
        return newAddresses;
      } else {
        return prevAddresses;
      }
    });

    setStringAddresses(prevAddresses => {
      if (stringVariables && typeof stringVariables === 'object') {
        const newAddresses = { ...prevAddresses };
        const valueToAddressMap = {};
    
        // First, map existing addresses to values
        Object.keys(stringVariables).forEach(key => {
          const value = stringVariables[key].value;
          if (!valueToAddressMap[value] && newAddresses[key]) {
            valueToAddressMap[value] = newAddresses[key];
          }
        });
    
        // Then assign addresses, reusing if the value is the same
        Object.keys(stringVariables).forEach(key => {
          const value = stringVariables[key].value;
          if (!newAddresses[key]) {
            if (valueToAddressMap[value]) {
              // Reuse the address if it already exists for the same value
              newAddresses[key] = valueToAddressMap[value];
            } else {
              // Generate a new address and map it to the value
              const address = generateMemoryAddress();
              newAddresses[key] = address;
              valueToAddressMap[value] = address;
            }
          }
        });
    
        return newAddresses;
      } else {
        return prevAddresses;
      }
    });

  }, [variables, stringVariables, unionVariables]);


  const isStringIndexHighlighted = (stringName, index) => {
    return highlightedStringIndices.some(
      (highlight) => highlight.stringName === stringName && highlight.index === index
    );
  };

  const getStringIteratorName = (stringName, index) => {
    const highlight = highlightedStringIndices.find(
      (highlight) => highlight.stringName === stringName && highlight.index === index
    );

    return highlight && highlight.iteratorName && highlight.iteratorName.length > 0
      ? highlight.iteratorName + " = "
      : "";
  };

  const isVisualizationEmpty = () => {
    return !(
      (variables && Object.keys(variables).length) ||
      (stringVariables && Object.keys(stringVariables).length) ||
      (unionVariables && Object.keys(unionVariables).length)
    );
  };

  return (
      <div className="visualization-canvas" style={{flexDirection: 'column'}}>
        <ArcherContainer strokeColor="red">
          {isVisualizationEmpty() ? (
            <p 
              style={{textAlign: 'center'}}
            >Code visualizations will appear here.</p>
            ) : (
              <>
                <>
                  {unionVariables && Object.entries(unionVariables).map(([unionVariableName, unionVariableData], unionVariableIndex) => {
                    const getStyleByDataType = (dataType) => {
                      switch (dataType) {
                        case 'string':
                          return { strokeColor: '#00e676', strokeWidth: 2, noCurves: true };
                        case 'char*':
                          return { strokeColor: '#00e676', strokeWidth: 2, noCurves: true };
                        default:
                          return { strokeColor: '#00e676', strokeWidth: 2 };
                      }
                    };

                    const relations = unionVariableData.value.attributes.map((attribute) => ({
                      targetId: attribute.name,
                      style: getStyleByDataType(attribute.dataType),
                      targetAnchor: 'right',
                      sourceAnchor: 'right'
                    }));
                      
                    return(
                      <ArcherElement
                          id={unionVariableName}
                          key={unionVariableName}
                          relations={relations}
                      >
                        <div key={unionVariableIndex}
                          className={`${highlightedUnionVariables.includes(unionVariableName) ? 'highlighted-class-diagram' : 'class-diagram'}`}
                          style={{minWidth: '300px'}}
                        >
                          <div
                            className={`${highlightedUnionVariables.includes(unionVariableName) ? 'highlighted-class-name' : 'class-name'}`}
                          >{unionVariableData.unionName} : Union</div>
                          <div
                            className={`${highlightedUnionVariables.includes(unionVariableName) ? 'highlighted-attributes' : 'attributes'}`}
                          >
                            {unionVariableData.value.attributes.map((attribute, attributeIndex) => (
                              <div key={attributeIndex}>
                              - {attribute.name} : {attribute.dataType}
                              </div>
                            ))}
                          </div>
                          <div
                            className={`${highlightedUnionVariables.includes(unionVariableName) ? 'highlighted-object-name' : 'object-name'}`}
                          >
                            {unionVariableName} <br/>
                            <span style={{fontSize: '11px'}}> {unionVariableAddresses[unionVariableName]} </span>
                          </div>
                        </div>
                      </ArcherElement>
                    );
                  })}
                </>


                <div className="variables-container">
                  {variables && Object.entries(variables).map(([key, { variable_name, value }], index) => (
                    <ArcherElement
                      id={variable_name}
                      key={variable_name}
                    >
                      <div key={index} className={`variable-box-wrapper`}>
                        <div key={index} className={`variable-box ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}>
                        <div className="variable-value">{value}</div>
                        <div className={`${highlightedVariables.includes(variable_name) ? 'variable-name-highlighted' : 'variable-name'}`}>{variable_name}</div>
                        </div>
                        <div className={`variable-address ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}>{variableAddresses[key]}</div>
                      </div>
                    </ArcherElement>
                  ))}
                </div>

                <>
                  {stringVariables && Object.entries(stringVariables).map(([stringName, stringData], stringIndex) => (
                    <ArcherElement
                      id={stringName}
                      key={stringName}
                    >
                      <div key={stringIndex} className="string-container">
                        <div className={`${highlightedStringVariables.includes(stringName) ? 'highlighted-string string-variable-box-highlighted' : 'string-variable-box'}`}>
                          <div className="string-variable-name">{stringData.variable_name}
                            <br/>
                            <span style={{fontSize: '11px'}}> {stringAddresses[stringName]} </span>
                          </div>
                        </div>
                        {stringData.value.split('').map((char, index) => (
                        <div key={index}
                          style={stringData.width ? { width: `${stringData.width}` } : {}}
                          className={`${isStringIndexHighlighted(stringName, index) ? 'highlighted-str' : ''} 
                          ${highlightedStringVariables.includes(stringName) ? 'highlighted-string string-highlighted-box' : 'string-box'}`}>
                            <div className="string-index-value">
                              <div className={`${isStringIndexHighlighted(stringName, index) ? 'highlighted-string-index' : 'string-index'} 
                                ${highlightedStringVariables.includes(stringName) ? 'highlighted-string-index' : ''}`}>
                                {getStringIteratorName(stringName, index)}{index} 
                              </div>
                              <div className="string-value">{char}</div>
                            </div>
                        </div>
                        ))}
                      </div>
                    </ArcherElement>
                  ))}
                </>
              </>
            )
          }  
        </ArcherContainer>
      </div>
  );
};

export default VisualizationCanvas;

import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This implementation performs a level-order traversal (breadth-first traversal) of a binary tree. It processes nodes level by level, from left to right, using a queue.
        <div><b>Steps:</b></div>
        <ul style={{paddingLeft: '15px'}}>
            <li>
                <div><b>Start with the root node:</b></div>
                <ul style={{paddingLeft: '15px'}}>
                    <li>If the tree is empty (root is None), terminate the traversal.</li>
                </ul>
            </li>
            <li>
                <div><b>Initialize a queue:</b></div>
                <ul style={{paddingLeft: '15px'}}>
                    <li>Enqueue the root node.</li>
                </ul>
            </li>
            <li>
                <div><b>Process the queue:</b></div>
                <ul style={{paddingLeft: '15px'}}>
                    <li>Dequeue a node, process it (print its value), and enqueue its left and right children (if they exist).</li>
                </ul>
            </li>
            <li>Repeat until the queue is empty.</li>
        </ul>
      </div>
      <div id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Tree Representation:</b> Display binary tree data level by level (e.g., for visualizations or reports).</li>
            <li><b>Shortest Path Problems:</b> Used in unweighted graphs to find the shortest path (BFS logic).</li>
            <li><b>Networking:</b> Model communication hierarchies or protocols that traverse systems level by level.</li>
            <li><b>Decision Trees:</b> Evaluate decisions layer by layer in AI or game development.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

import { useState, useEffect, useRef } from 'react';
import { stackPython, stackJava, stackC, stackCpp } from './stackAlgorithms';
import defaultExplanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useStack = (speakingLang, translatedExplanations, language, stackSize, data, breakpoints) => {
  const [explanations, setExplanations] = useState(defaultExplanations);
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [stackVariables, setStackVariables] = useState({});
  const [tos, setTos] = useState(-1);
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedStackVariables, setHighlightedStackVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [highlightedStackIndex, setHighlightedStackIndex] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef, speakingLang);
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  useEffect(() => {
    if (translatedExplanations && language) {
      setExplanations((prevExplanations) => ({
        ...prevExplanations,
        [language]: Object.keys(prevExplanations[language] || {}).reduce((acc, key, index) => {
          acc[key] = translatedExplanations[index] || prevExplanations[language][key];
          return acc;
        }, {}),
      }));
    } else {
      setExplanations(defaultExplanations);
    }
  }, [translatedExplanations, language]);

  const updateVariables = (newData) => {
    resetState();
    
    let updatedCodeSamples = "";
    let index = 0;
     // Dynamically update the code samples
     updatedCodeSamples = codeSamplesTemplate[language].map((line) => {
      return line.replace(/push\s*\(\s*\d+\s*\)/g, () => {
        const newValue = newData[index] || 0; // Fallback to 0 if out of range
        index++;
        return `push(${newValue})`;
      });
    });
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setLogs([]);
    setTos(-1);
    setVariables({});
    setArrayVariables({});
    setStackVariables({});
    setHighlightedVariables([]);
    setHighlightedArrayVariables([]);
    setHighlightedStackVariables([]);
    setHighlightedIndices([]);
    setHighlightedStackIndex([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber, customLog=null) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    
    const logMessageText = customLog 
    ? `Line ${lineNumber + 1}: ${customLog}` 
    : `Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`;

    await logMessage(logMessageText);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  // Run Stack Visualization for Python
  const runStackPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    const { init, push, pop } = await stackPython(highlightLine, setVariables, setArrayVariables, setStackVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedStackVariables, setHighlightedIndices, setHighlightedStackIndex, highlightCallingLine, customSleep, logMessage);
    
    await highlightLine(28);
    await customSleep(28);

    await highlightCallingLine(28);
    await init(stackSize);
    await highlightLine(28, "Returning from the constructor method.");
    await customSleep(28);

    await highlightLine(30);
    await customSleep(30);

    await highlightCallingLine(30);
    await pop();
    await customSleep();
    await highlightLine(30, "Returning from the pop method.");
    await customSleep(30);

    await highlightLine(32);
    await customSleep(32);

    await highlightCallingLine(32);
    await push(data[0]);
    await customSleep();
    await highlightLine(32, "Returning from the push method.");
    await customSleep(32);

    await highlightLine(33);
    await customSleep(33);
    
    await highlightCallingLine(33);
    await push(data[1]);
    await customSleep();
    await highlightLine(33, "Returning from the push method.");
    await customSleep(33);

    await highlightLine(34);
    await customSleep(34);
    
    await highlightCallingLine(34);
    await push(data[2]);
    await customSleep();
    await highlightLine(34, "Returning from the push method.");
    await customSleep(34);

    await highlightLine(35);
    await customSleep(35);
    
    await highlightCallingLine(35);
    await push(data[3]);
    await customSleep();
    await highlightLine(35, "Returning from the push method.");
    await customSleep(35);

    await highlightLine(37);
    await customSleep(37);

    await highlightCallingLine(37);
    await pop();
    await highlightLine(37, "Returning from the pop method.");
    await customSleep(37);


    await focusEndLine(37);
    setIsRunning(false);
  };

  // Run Stack Visualization for Java
  const runStackJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    const { init, push, pop } = await stackJava(highlightLine, setVariables, setArrayVariables, setStackVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedStackVariables, setHighlightedIndices, setHighlightedStackIndex, highlightCallingLine, customSleep, logMessage);
    
    await highlightLine(39);
    await customSleep(39);

    await highlightCallingLine(39);
    await init(stackSize);
    await highlightLine(39, "Returning from the constructor method.");
    await customSleep(39);

    await highlightLine(41);
    await customSleep(41);

    await highlightCallingLine(41);
    await pop();
    await customSleep();
    await highlightLine(41, "Returning from the pop method.");
    await customSleep(41);

    await highlightLine(43);
    await customSleep(43);

    await highlightCallingLine(43);
    await push(data[0]);
    await customSleep();
    await highlightLine(43, "Returning from the push method.");
    await customSleep(43);

    await highlightLine(44);
    await customSleep(44);
    
    await highlightCallingLine(44);
    await push(data[1]);
    await customSleep();
    await highlightLine(44, "Returning from the push method.");
    await customSleep(44);

    await highlightLine(45);
    await customSleep(45);
    
    await highlightCallingLine(45);
    await push(data[2]);
    await customSleep();
    await highlightLine(45, "Returning from the push method.");
    await customSleep(45);

    await highlightLine(46);
    await customSleep(46);
    
    await highlightCallingLine(46);
    await push(data[3]);
    await customSleep();
    await highlightLine(46, "Returning from the push method.");
    await customSleep(46);

    await highlightLine(48);
    await customSleep(48);

    await highlightCallingLine(48);
    await pop();
    await highlightLine(48, "Returning from the pop method.");
    await customSleep(48);


    await focusEndLine(50);
    setIsRunning(false);
  };

  // Run Stack Visualization for C
  const runStackC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    const { init, push, pop, display } = await stackC(highlightLine, setVariables, setArrayVariables, setStackVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedStackVariables, setHighlightedIndices, setHighlightedStackIndex, highlightCallingLine, customSleep, logMessage);
    
    await init(stackSize);

    await highlightLine(39);
    await customSleep(39);

    await highlightCallingLine(39);
    await push(data[0]);
    await customSleep();
    await highlightLine(39, "Returning from the push function.");
    await customSleep(39);

    await highlightLine(40);
    await customSleep(40);
    
    await highlightCallingLine(40);
    await push(data[1]);
    await customSleep();
    await highlightLine(40, "Returning from the push function.");
    await customSleep(40);

    await highlightLine(41);
    await customSleep(41);
    
    await highlightCallingLine(41);
    await push(data[2]);
    await customSleep();
    await highlightLine(41, "Returning from the push function.");
    await customSleep(41);

    await highlightLine(42);
    await customSleep(42);
    
    await highlightCallingLine(42);
    await push(data[3]);
    await customSleep();
    await highlightLine(42, "Returning from the push function.");
    await customSleep(42);

    await highlightLine(44);
    await customSleep(44);

    await highlightCallingLine(44);
    await display();
    await highlightLine(44, "Returning from the display function.");
    await customSleep(44);

    await highlightLine(46);
    await customSleep(46);

    await highlightCallingLine(46);
    await pop();
    await highlightLine(46, "Returning from the pop function.");
    await customSleep(46);

    await highlightLine(47);
    await customSleep(47);

    await highlightCallingLine(47);
    await pop();
    await highlightLine(47, "Returning from the pop function.");
    await customSleep(47);

    await highlightLine(49);
    await customSleep(49);

    await highlightCallingLine(49);
    await display();
    await highlightLine(49, "Returning from the display function.");
    await customSleep(49);

    await highlightLine(51);
    await customSleep(51);

    await focusEndLine(52);
    setIsRunning(false);
  };

  // Run Stack Visualization for C++
  const runStackCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    const { init, push, pop, display } = await stackCpp(highlightLine, setVariables, setArrayVariables, setStackVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedStackVariables, setHighlightedIndices, setHighlightedStackIndex, highlightCallingLine, customSleep, logMessage);
    
    await highlightLine(43);
    await customSleep(43);

    await highlightCallingLine(43);
    await init(stackSize);
    await highlightLine(43, "Returning from the constructor method.");
    await customSleep(43);

    await highlightLine(45);
    await customSleep(45);

    await highlightCallingLine(45);
    await push(data[0]);
    await customSleep();
    await highlightLine(45, "Returning from the push method.");
    await customSleep(45);

    await highlightLine(46);
    await customSleep(46);
    
    await highlightCallingLine(46);
    await push(data[1]);
    await customSleep();
    await highlightLine(46, "Returning from the push method.");
    await customSleep(46);

    await highlightLine(47);
    await customSleep(47);
    
    await highlightCallingLine(47);
    await push(data[2]);
    await customSleep();
    await highlightLine(47, "Returning from the push method.");
    await customSleep(47);

    await highlightLine(48);
    await customSleep(48);
    
    await highlightCallingLine(48);
    await push(data[3]);
    await customSleep();
    await highlightLine(48, "Returning from the push method.");
    await customSleep(48);

    await highlightLine(50);
    await customSleep(50);

    await highlightCallingLine(50);
    await display();
    await highlightLine(50, "Returning from the display method.");
    await customSleep(50);

    await highlightLine(52);
    await customSleep(52);

    await highlightCallingLine(52);
    await pop();
    await highlightLine(52, "Returning from the pop method.");
    await customSleep(52);

    await highlightLine(53);
    await customSleep(53);

    await highlightCallingLine(53);
    await pop();
    await highlightLine(53, "Returning from the pop method.");
    await customSleep(53);

    await highlightLine(55);
    await customSleep(55);

    await highlightCallingLine(55);
    await display();
    await highlightLine(55, "Returning from the display method.");
    await customSleep(55);

    await highlightLine(57);
    await customSleep(57);

    await focusEndLine(58);
    setIsRunning(false);
  };

  const runStack = async () => {
    if (language === 'Python') {
      await runStackPython();
    } else if (language === 'Java') {
      await runStackJava();
    } else if (language === 'C') {
      await runStackC();
    } else if (language === 'C++') {
      await runStackCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    stackVariables,
    tos,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedStackVariables,
    highlightedIndices,
    highlightedStackIndex,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setStackVariables,
    setTos,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedStackVariables,
    setHighlightedIndices,
    setHighlightedStackIndex,
    runStack
  };
};

import React, { useState } from 'react';

const Controls = ({ isRunning, isPaused, delay, setDelay, runVisualization, setIsPaused, isMuted, setIsMuted, isTranslating, editorCardRef }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMuteToggle = () => {
        setIsMuted((prevIsMuted) => {
        const newIsMuted = !prevIsMuted;
        if (!newIsMuted) {
            setDelay(2000); // Set delay to 2 seconds when unmuted
        }
        return newIsMuted;
    });
  };

  const handleRunVisualization = () => {
    runVisualization();

    setTimeout(() => {
        if (editorCardRef.current) {
            editorCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, 200);
  };

  return (
    <div className="visualization-controls d-flex justify-content-between align-items-center mb-3 gap-2">
        <button
            className="btn btn-success btn-sm"
            onClick={handleRunVisualization}
            disabled={isRunning || isTranslating}
        >
            {isTranslating
                ?
                    <div className="spinner-border" style={{height: '18px', width: '18px'}} role="status"/>
                :
                    <div><i className="fa fa-play"></i> Run</div>
            }
        </button>
        <button
            onClick={() => setIsPaused(!isPaused)}
            disabled={!isRunning}
            className="btn btn-warning btn-sm"
        >
            <i className={`fa ${isPaused ? 'fa-play-circle' : 'fa-pause-circle'}`}></i> {isPaused ? 'Resume' : 'Pause'}
        </button>
        <div style={{ textAlign: 'center' }}>
            <label htmlFor="delayRange" style={{width: '140px', fontSize: '14px'}}>
                Delay : <span id="delayValue">{delay / 1000}</span>s
            </label>
            <div
                style={{ position: 'relative'}}
                onMouseEnter={() => !isMuted && setShowTooltip(true)}
                onMouseLeave={() => !isMuted && setShowTooltip(false)}
            >
                <input
                    type="range"
                    id="delayRange"
                    min="0"
                    max="10"
                    step="0.1"
                    value={delay / 1000}
                    onChange={(e) => setDelay(e.target.value * 1000)}
                    disabled={!isMuted}
                    className="delay-btn"
                    style={{ width: '100%' }}
                />
                {showTooltip && (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '120%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: '#333',
                            color: '#fff',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            fontSize: '12px',
                            whiteSpace: 'nowrap',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            zIndex: 100,
                            pointerEvents: 'none',
                        }}
                    >
                        {!isMuted ? 'Mute to adjust visualization speed' : `Adjust visualization speed ${delay / 1000}s`}
                    </div>
                )}
            </div>

        </div>
        <div onClick={handleMuteToggle} className={`mute-button ${isMuted ? 'muted' : 'unmuted'}`}>
            <div className="inner-circle">
                <i className={`fa ${isMuted ? 'fa-volume-off' : 'fa-volume-up'}`}></i>
                <div className="wave"></div>
            </div>
        </div>
    </div>
  );
};

export default Controls;

import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This program demonstrates the use of the <b>short data type</b>, which is typically a <b>16-bit integer</b>. 
        It stores and displays values such as the number of days in a week and the 
        maximum and minimum values a short can represent.
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Small-Range Counters:</b> Storing values like days in a week or months in a year, which do not exceed the short range.</li>
            <li><b>Memory-Constrained Applications:</b> Useful in embedded systems where memory efficiency is critical.</li>
            <li><b>Fixed-Range Data:</b> Representing values with known limits, such as sensor readings or small lookup tables.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

import React, { useState, useEffect } from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import '../VisualizationCanvas.css';

const VisualizationCanvas = ({ 
  variables,
  nodeVariables,
  headVariables,
  highlightedVariables,
  highlightedNodeVariables,
  highlightedHeadVariables,
  highlightDataPart,
  highlightNextAddressPart,
  highlightNodeAddress
}) => {
  const [variableAddresses, setVariableAddresses] = useState({});
  const [headVariablesAddresses, setHeadVariablesAddresses] = useState({});

  useEffect(() => {
    const adjustFontSize = () => {
      document.querySelectorAll('.array-value').forEach(element => {
        let fontSize = parseInt(window.getComputedStyle(element).fontSize);
        while (element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight) {
          if (fontSize <= 8) break;
          fontSize--;
          element.style.fontSize = `${fontSize}px`;
        }
      });
    };
    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);
    return () => window.removeEventListener('resize', adjustFontSize);
  }, []);

  useEffect(() => {
    const generateMemoryAddress = () => {
      return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
    };

    // Initialize variable addresses if not already initialized
    setVariableAddresses(prevAddresses => {
      const newAddresses = { ...prevAddresses };
      Object.keys(variables).forEach(key => {
        if (!newAddresses[key]) {
          newAddresses[key] = generateMemoryAddress();
        }
      });
      return newAddresses;
    });

    // Initialize variable addresses if not already initialized
    setHeadVariablesAddresses(prevAddresses => {
      const newAddresses = { ...prevAddresses };
      Object.keys(headVariables).forEach(key => {
        if (!newAddresses[key]) {
          newAddresses[key] = generateMemoryAddress();
        }
      });
      return newAddresses;
    });

  }, [variables, headVariables]);

  const isVisualizationEmpty = () => {
    return !(
      (variables && Object.keys(variables).length) ||
      (nodeVariables && Object.keys(nodeVariables).length) ||
      (headVariables && Object.keys(headVariables).length)
    );
  };

  return (
    <div className="visualization-canvas" style={{display: !isVisualizationEmpty() ? 'block' : ''}}>
      <ArcherContainer strokeColor="red">
        {isVisualizationEmpty() ? (
          <p 
            style={{textAlign: 'center'}}
          >Code visualizations will appear here.</p>
          ) : (
            <>
              <div className="variables-container" style={{justifyContent: 'left', paddingLeft: '20px'}}>
                {headVariables && Object.entries(headVariables).map(([key, { variable_name, value, child }], index) => (
                  <ArcherElement
                    id={variable_name}
                    key={variable_name}
                    relations={
                      child 
                      ? [
                          {
                            targetId: child,
                            targetAnchor: 'top',
                            sourceAnchor: 'bottom',
                            style: { strokeColor: '#00e676', strokeDasharray: '5,5' },
                          },
                        ]
                      : []
                    }
                  >
                    <div key={index} className={`variable-box-wrapper`}>
                      <div key={index} className={`variable-box ${highlightedHeadVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}>
                      <div className="variable-value">{value}</div>
                      <div className={`${highlightedHeadVariables.includes(variable_name) ? 'variable-name-highlighted' : 'variable-name'}`}>{variable_name}</div>
                      </div>
                      <div 
                        className={`variable-address ${highlightedHeadVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}
                        style={{marginBottom: '0px'}}
                      >{headVariablesAddresses[key]}</div>
                    </div>
                  </ArcherElement>
                ))}

                <div style={{margin: '0 auto', display: 'flex'}}>
                  {variables && Object.entries(variables).map(([key, { variable_name, value }], index) => (
                      <div key={index} className={`variable-box-wrapper`}>
                        <div key={index} className={`variable-box ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}>
                        <div className="variable-value">{value}</div>
                        <div className={`${highlightedVariables.includes(variable_name) ? 'variable-name-highlighted' : 'variable-name'}`}>{variable_name}</div>
                        </div>
                        <div 
                          className={`variable-address ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}
                          style={{marginBottom: '0px'}}
                        >{variableAddresses[key]}</div>
                      </div>
                  ))}
                </div>

              </div>


              <div className="node-container" style={{paddingTop: '15px', paddingLeft: '185px', width: '100%'}}>
                {nodeVariables && Object.entries(nodeVariables).map(([key, { variable_name, value, address, parent, positionDown }], index) => (
                  <div className="node-block" key={index}>
                    <ArcherElement
                      id={address}
                      key={address}
                      relations={
                        parent 
                        ? [
                            {
                              targetId: parent,
                              targetAnchor: parent === 'head' ? 'bottom' : 'right',
                              sourceAnchor: parent === 'head' ? 'left' : 'left',
                              style: { strokeColor: '#00e676', strokeWidth: 2, endMarker: false, startMarker: true },
                            },
                          ]
                        : []
                      }
                    >
                      <div
                        className={highlightedNodeVariables.includes(address) ? 'highlighted-node' : 'node'}
                        style={{
                          marginTop: positionDown ? '50px' : '0px'
                        }}
                      >
                        <div
                          className={
                            highlightedNodeVariables.includes(address) ||
                            highlightDataPart.includes(address)
                              ? 'highlighted-node-data'
                              : 'node-data'
                          }
                          style={{display: 'block'}}
                        >
                          <div style={{borderBottom: '1px solid', fontSize: '11px'}}>data</div>
                          <div style={{paddingTop: '7px'}}>{value.data}</div>
                        </div>
                        <div
                          className={
                            highlightedNodeVariables.includes(address) ||
                            highlightNextAddressPart.includes(address) 
                              ? 'highlighted-node-pointer' 
                              : 'node-pointer'
                          }
                          style={{display: 'block'}}
                        >
                          <div style={{borderBottom: '1px solid', fontSize: '11px'}}>next</div>
                          <div style={{paddingTop: '7px'}}>{value.next}</div>
                        </div>
                      </div>
                    </ArcherElement>
                    <div
                      className={
                        highlightedNodeVariables.includes(address) ||
                        highlightNodeAddress.includes(address)
                          ? 'highlighted-node-address'
                          : 'node-address'
                      }
                    >{address}</div>
                  </div>
                ))}
              </div>
            </>
          )
        }
      </ArcherContainer>
    </div>
  );
};

export default VisualizationCanvas;

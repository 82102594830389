import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This program demonstrates the implementation of a <b>Queue</b> data structure using a fixed-size array. 
        It provides methods for:
        <ul style={{paddingLeft: '15px'}}>
            <li><b>enqueue:</b> Adds an element to the rear of the queue if space is available.</li>
            <li><b>dequeue:</b> Removes an element from the front of the queue if it is not empty.</li>
            <li><b>display:</b> Shows the current elements in the queue.</li>
        </ul>
      </div>
      <div id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <div style={{display: 'flex'}}>
            <ul style={{paddingLeft: '30px'}}>
              <li>
                <b>Enqueue:</b> &nbsp;&nbsp;
                <span style={{fontSize: '20px'}}>O</span>
                <span style={{fontSize: '18px'}}>(</span>
                <span style={{fontSize: '15px'}}>1</span>
                <span style={{fontSize: '18px'}}>)</span>
              </li>
            </ul>
            <ul style={{paddingLeft: '30px'}}>
              <li>
                <b>Dequeue:</b> &nbsp;&nbsp;
                <span style={{fontSize: '20px'}}>O</span>
                <span style={{fontSize: '18px'}}>(</span>
                <span style={{fontSize: '15px'}}>1</span>
                <span style={{fontSize: '18px'}}>)</span>
              </li>
            </ul>
            <ul style={{paddingLeft: '30px'}}>
              <li>
                <b>Display:</b> &nbsp;&nbsp;
                <span style={{fontSize: '20px'}}>O</span>
                <span style={{fontSize: '18px'}}>(</span>
                <span style={{fontSize: '15px'}}>n</span>
                <span style={{fontSize: '18px'}}>)</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Customer Service Systems:</b> Managing a line of customers waiting for service (e.g., at a bank or help desk).</li>
            <li><b>Task Scheduling:</b> Queues are used in CPU task scheduling to process tasks in the order they arrive.</li>
            <li><b>Data Buffering:</b> In networking or streaming, queues manage incoming packets or data streams in order.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        Use of scientific notation to represent large and small floating-point numbers. 
        It declares constants for <b>Earth's mass</b>, <b>Avogadro's number</b>, and <b>electron charge</b>, 
        then prints their values to the console for educational purposes.
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Scientific Calculations:</b> Represent large or small quantities, such as mass, atomic numbers, or charges.</li>
            <li><b>Educational Tools:</b> Teach constants and values used in physics and chemistry.</li>
            <li><b>Simulation Software:</b> Model systems requiring physical constants for computation, such as astrophysics or quantum mechanics.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

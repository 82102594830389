import React, { useEffect, useState } from 'react';
import Testimonials from './Testimonials';
import FloatingDrawer from './FloatingDrawer';
import useStopSpeech from '../hooks/useStopSpeech';
import axios from 'axios';

const MainContent = ({ onSelectLanguage }) => {
  const [languages, setLanguages] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  useStopSpeech();

  useEffect(() => {
    // Set SEO-friendly page title
    document.title = `Coding Canvas`;

    // Set meta description for SEO
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", `Explore AI-powered interactive tutorials, code samples, intelligent hints, and exercises to learn programming visually on CodingCanvas.`);
    }

    // Fetch language data from the API
    const fetchLanguages = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/languages/`);
        setLanguages(response.data);
      } catch (error) {
        console.error("Error fetching language data:", error);
      }
    };

    fetchLanguages();
  }, []);

  return (
    <>
      <main className="container-fluid my-5 main-content">
        <section className="text-center mb-5">
          <h2>What is CodingCanvas?</h2>
          <p>CodingCanvas simplifies complex programming concepts with engaging visuals, animations, and step-by-step guidance 
            in <b>Python</b>, <b>Java</b>, <b>C</b>, and <b>C++</b>. Ideal for students and experienced engineers, it helps sharpen skills in 
            algorithms, data structures, and coding fundamentals. With <b>AI-powered</b> features, CodingCanvas provides personalized coding challenges, 
            intelligent hints, automated code explanations, and real-time feedback. Whether mastering basics or preparing for interviews, 
            AI-driven insights make learning interactive, efficient, and confidence-boosting.</p>    
        </section>

        <section className="video-section text-center py-5">
          <h2>How to Use CodingCanvas</h2>
          <div className="video-wrapper">
            <video controls style={{ display: 'block', width: '100%', borderRadius: '12px' }} poster="/cover-photo.png">
              <source src="how-to-use-coding-canvas.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </section>

        <section className="row text-center mb-5">
          <div className="container-fluid">
            <div className="row no-gutters d-flex justify-content-center">
              <div className="col-md-4 mb-4 d-flex">
                <div className="card p-4 h-100">
                  <h3>Interactive Learning</h3>
                  <p>
                    Watch your code come to life through visualizations with AI-driven explanations, making it easier to grasp the toughest topics.
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-4 d-flex">
                <div className="card p-4 h-100">
                  <h3>Multi-Language Support</h3>
                  <p>
                    Explore Python, Java, C, and C++ with AI-powered code challenges and real-time translations in English, French, Hindi, and more 
                    for seamless learning.
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-4 d-flex">
                <div className="card p-4 h-100">
                  <h3>Personalized Experience</h3>
                  <p>
                    Track your progress and customize learning paths with AI-powered code reviews to improve your understanding of programming concepts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="row text-center mb-5">
          <div className="container-fluid">
            <div className="row no-gutters d-flex justify-content-center">
              {languages.map((language) => (
                <div key={language.id} className="col-md-3 mb-3 d-flex">
                  <div className="card p-3 h-100 d-flex flex-column justify-content-between">
                    <div className="text-center">
                      <img
                        src={`/${language.logo}`}
                        alt={`${language.display_name} logo`}
                        style={{ width: '60px', height: '60px', marginBottom: '15px' }}
                      />
                      <h3>{language.display_name}</h3>
                      <p style={{ fontSize: '17px' }}>{language.description}</p>
                    </div>
                    <section className="text-center mt-auto">
                      <a
                        href="#"
                        className="btn btn-lg get-started-btn"
                        onClick={(e) => {
                          e.preventDefault();
                          onSelectLanguage(language.name);
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                          });
                        }}
                      >
                        Get Started
                      </a>
                    </section>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="video-section text-center py-5">
          <h2>How to Use AI Code Challenge</h2>
          <div className="video-wrapper">
            <video controls style={{ display: 'block', width: '100%', borderRadius: '12px' }} poster="/cover-photo-ai-challenge.png">
              <source src="how-to-use-ai-code-challenge.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </section>

        <section className="row text-center mb-1">
          <Testimonials></Testimonials>
        </section>
      </main>
      <FloatingDrawer />
    </>
  );
};

export default MainContent;

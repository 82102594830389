import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This program demonstrates <b>numeric data types</b> by declaring and printing examples 
        of <b>integer</b>, <b>float</b>, and <b>complex numbers</b>. It also uses the <b>type()</b> function to confirm the 
        data type of each variable.
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Integer:</b> Used for counting discrete quantities, like the number of items in inventory.</li>
            <li><b>Float:</b> Used in precise financial calculations, such as computing interest rates.</li>
            <li><b>Complex Numbers:</b> Applied in electrical engineering to analyze alternating current (AC) circuits.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

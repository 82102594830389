import React, { useState, useEffect } from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import Logger from '../components/LinkedList/Logger';
import '../VisualizationCanvas.css';

const VisualizationCanvas = ({ 
  graphVariables,
  highlightedGraphVariables,
  variables,
  highlightedVariables,
  adjList,
  mapVariables,
  highlightedMapVariables=[],
  highlightedMapIndex,
  mapVariablesForC,
  highlightedMapVariablesForC=[],
  highlightedMapIndexForC,
  highlightedLineArrow,
  setsVariables,
  highlightedSetVariables,
  highlightedSetIndex,
  stackVariables,
  highlightedStackVariables,
  highlightedStackIndex,
  strikeThroughStackIndex,
  nodeVariables,
  highlightedNodeVariables,
  highlightNodeAddress,
  highlightDataPart,
  highlightNextAddressPart
}) => {
  const [variableAddresses, setVariableAddresses] = useState({});
  const [mapAddresses, setMapAddresses] = useState({});
  const [mapAddressesForC, setMapAddressesForC] = useState({});
  const [setsVariableAddresses, setSetsVariableAddresses] = useState({});
  const [stackVariableAddresses, setStackVariableAddresses] = useState({});

  useEffect(() => {
    const generateMemoryAddress = () => {
      return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
    };

    // Initialize variable addresses if not already initialized
    setVariableAddresses(prevAddresses => {
      const newAddresses = { ...prevAddresses };
      Object.keys(variables).forEach(key => {
        if (!newAddresses[key]) {
          newAddresses[key] = generateMemoryAddress();
        }
      });
      return newAddresses;
    });

    // Initialize map addresses if not already initialized
    setMapAddresses(prevAddresses => {
      if (mapVariables && typeof mapVariables === 'object') {
        const newAddresses = { ...prevAddresses };
        Object.keys(mapVariables).forEach(key => {
          if (!newAddresses[key]) {
            newAddresses[key] = generateMemoryAddress();
          }
        });
        return newAddresses;
      } else {
        return prevAddresses;
      }
    }); 

    // Initialize map addresses for C if not already initialized
    setMapAddressesForC(prevAddresses => {
      if (mapVariablesForC && typeof mapVariablesForC === 'object') {
        const newAddresses = { ...prevAddresses };
        Object.keys(mapVariablesForC).forEach(key => {
          if (!newAddresses[key]) {
            newAddresses[key] = generateMemoryAddress();
          }
        });
        return newAddresses;
      } else {
        return prevAddresses;
      }
    }); 

    // Initialize set variable addresses if not already initialized
    setSetsVariableAddresses(prevAddresses => {
      if (setsVariables && typeof setsVariables === 'object') {
        const newAddresses = { ...prevAddresses };
        Object.keys(setsVariables).forEach(key => {
          if (!newAddresses[key]) {
            newAddresses[key] = generateMemoryAddress();
          }
        });
        return newAddresses;
      } else {
        return prevAddresses;
      }
    });
    
    // Initialize stack variable addresses if not already initialized
    setStackVariableAddresses(prevAddresses => {
      if (stackVariables && typeof stackVariables === 'object') {
        const newAddresses = { ...prevAddresses };
        Object.keys(stackVariables).forEach(key => {
          if (!newAddresses[key]) {
            newAddresses[key] = generateMemoryAddress();
          }
        });
        return newAddresses;
      } else {
        return prevAddresses;
      }
    });

  }, [variables, mapVariables, mapVariablesForC, setsVariables, stackVariables]);

  const isMapIndexHighlighted = (mapName, key) => {
    return highlightedMapIndex.some(
      (highlight) => highlight.mapName === mapName && highlight.key === key
    );
  };

  const isMapForCIndexHighlighted = (mapName, key) => {
    return highlightedMapIndexForC.some(
      (highlight) => highlight.mapName === mapName && highlight.key === key
    );
  };

  const isSetIndexHighlighted = (setName, key) => {
    return highlightedSetIndex.some(
      (highlight) => highlight.setName === setName && highlight.key === key
    );
  };

  const isStackIndexHighlighted = (stackName, index) => {
    return highlightedStackIndex.some(
      (highlight) => highlight.stackName === stackName && highlight.index === index
    );
  };

  const isStackIndexStrikeThrough = (stackName, index) => {
    return strikeThroughStackIndex?.some(
      (strikeThrough) => strikeThrough.stackName === stackName && strikeThrough.index === index
    ) || false;
  };

  const getStackIteratorName = (stackName, index) => {
    const highlight = highlightedStackIndex.find(
      (highlight) => highlight.stackName === stackName && highlight.index === index
    );

    return highlight && highlight.iteratorName && highlight.iteratorName.length > 0
      ? highlight.iteratorName + " = "
      : "";
  };
 
  useEffect(() => {
    const adjustFontSize = () => {
      document.querySelectorAll('.array-value').forEach(element => {
        let fontSize = parseInt(window.getComputedStyle(element).fontSize);
        while (element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight) {
          if (fontSize <= 8) break;
          fontSize--;
          element.style.fontSize = `${fontSize}px`;
        }
      });
    };
    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);
    return () => window.removeEventListener('resize', adjustFontSize);
  }, []);

  const generateNodesAndEdges = (graphVariables) => {
    const nodes = [];
    const edges = [];
  
    // Generate nodes
    for (let key in graphVariables) {
      const node = graphVariables[key];
      nodes.push({
        memoryAddress: node.address,
        value: node.variable_name
      });
  
      // Generate edges for each node
      node.edges.forEach(edge => {
        // Find the address of the connected node
        const targetNode = graphVariables[edge];
        if (targetNode) {
          edges.push({
            from: node.address,
            to: targetNode.address
          });
        }
      });
    }
  
    return { nodes, edges };
  };
  
  const { nodes, edges } = generateNodesAndEdges(graphVariables);

  const centerX = 250;
  const centerY = 200;
  const radius = 150;

  const nodeSize = 60; // Size of each node for offset purposes
  const arrowSize = 34; // Size of the arrow to reduce the line length

  const nodesWithPosition = nodes.map((node, index) => {
    const angle = (2 * Math.PI * index) / nodes.length + Math.PI; // Start from left (make one more round)
    const x = centerX + radius * Math.cos(angle);
    const y = centerY + radius * Math.sin(angle);
    return { ...node, x, y };
  });

  const createLine = (fromNode, toNode) => {
    const x1 = fromNode.x + nodeSize / 2;
    const y1 = fromNode.y + nodeSize / 2;
    const x2 = toNode.x + nodeSize / 2;
    const y2 = toNode.y + nodeSize / 2;

    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY) - arrowSize; // Subtract the arrow size from the line length
    const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

    return (
      <div
        className={`${highlightedLineArrow.includes(fromNode.memoryAddress + '_' + toNode.memoryAddress) ? 'highlighted-graph-line' : 'graph-line'}`}
        key={`${fromNode.memoryAddress}_${toNode.memoryAddress}`}
        style={{
          width: `${highlightedLineArrow.includes(fromNode.memoryAddress + '_' + toNode.memoryAddress) ? (length + 6) + 'px' : (length + 4) + 'px'}`,
          transform: `rotate(${angle}deg)`,
          transformOrigin: '0 50%',
          top: `${y1}px`,
          left: `${x1}px`,
        }}
      />
    );
  };

  const isVisualizationEmpty = () => {
    return !(
      (variables && Object.keys(variables).length) ||
      (graphVariables && Object.keys(graphVariables).length) ||
      (mapVariables && Object.keys(mapVariables).length) ||
      (setsVariables && Object.keys(setsVariables).length) ||
      (stackVariables && Object.keys(stackVariables).length) ||
      (nodeVariables && Object.keys(nodeVariables).length)
    );
  };

  return (
    <div className="visualization-canvas" style={{display: !isVisualizationEmpty() ? 'block' : '', maxWidth: '100%'}}>
      <ArcherContainer strokeColor="red">
        {isVisualizationEmpty() ? (
          <p 
            style={{textAlign: 'center'}}
          >Code visualizations will appear here.</p>
        ) : (
          <>
            <div className={`${graphVariables && Object.keys(graphVariables).length ? 'graph-container' : ''}`}>
              {/* Render edges */}
              {edges.map((edge, index) => {
                const fromNode = nodesWithPosition.find(node => node.memoryAddress === edge.from);
                const toNode = nodesWithPosition.find(node => node.memoryAddress === edge.to);
                return fromNode && toNode ? createLine(fromNode, toNode) : null;
              })}

              {/* Render nodes */}
              {nodesWithPosition.map((node) => (
                <div
                  key={node.memoryAddress}
                  className={`${highlightedGraphVariables.includes(node.memoryAddress) ? 'highlighted-graph-node' : 'graph-node'}`}
                  style={{ left: node.x, top: node.y }}
                >
                  {/* <div className="graph-memory-address">{node.memoryAddress}</div> */}
                  <div className="graph-value">{node.value}</div>
                </div>
              ))}
            </div>

            <div style={{paddingTop: '40px', display: 'flex'}}>
              {mapVariables && Object.entries(mapVariables).map(([mapName, mapData], mapIndex) => (
                <div
                  key={mapIndex} 
                  className={`${highlightedMapVariables.includes(mapName) ? 'highlighted-dictionary-container' : 'dictionary-container'}`}
                  style={{marginRight: '20px', minWidth: '110px'}}
                >
                  {Object.entries(mapData.value).map(([key, val], index) => (
                      <div key={`map-${index}`} style={{display: 'contents'}}>
                        <div 
                          className={
                            `${isMapIndexHighlighted(mapName, key) ? 'highlighted-dictionary-row' : 'dictionary-row'} 
                            ${highlightedMapVariables.includes(mapName) ? 'highlighted-dictionary-row' : 'dictionary-row'}`
                          }
                        >
                          {key !== ' ' ? `Hash(${key}) = ${index}` : ''}
                        </div>
                        <div 
                          className={
                            `${isMapIndexHighlighted(mapName, key) ? 'highlighted-dictionary-cell' : 'dictionary-cell'}
                            ${highlightedMapVariables.includes(mapName) ? 'highlighted-dictionary-cell' : 'dictionary-cell'}`
                          }
                        >
                          {key !== ' ' ? `${key}: ${val}` : ''}
                        </div>
                      </div>
                  ))}
                  <div className={`${highlightedMapVariables.includes(mapName) ? 'highlighted-dictionary-cell merged' : 'dictionary-cell merged'}`}>
                    {mapName}
                    <br/>
                    <span style={{fontSize: '11px'}}> {mapAddresses[mapName]} </span>
                  </div>
                </div>
              ))}

              {mapVariablesForC && Object.entries(mapVariablesForC).map(([mapName, mapData], mapIndex) => (
                <div
                  key={mapIndex} 
                  className={`${highlightedMapVariablesForC.includes(mapName) ? 'highlighted-dictionary-container' : 'dictionary-container'}`}
                  style={{marginRight: '20px', minWidth: '110px'}}
                >
                  {Object.entries(mapData.value).map(([key, val], index) => (
                      <div key={`map-${index}`} style={{display: 'contents'}}>
                        <div 
                          className={
                            `${isMapForCIndexHighlighted(mapName, key) ? 'highlighted-dictionary-row' : 'dictionary-row'} 
                            ${highlightedMapVariablesForC.includes(mapName) ? 'highlighted-dictionary-row' : 'dictionary-row'}`
                          }
                        >
                          {key !== ' ' ? `${key}` : ''}
                        </div>
                        <div 
                          className={
                            `${isMapForCIndexHighlighted(mapName, key) ? 'highlighted-dictionary-cell' : 'dictionary-cell'}
                            ${highlightedMapVariablesForC.includes(mapName) ? 'highlighted-dictionary-cell' : 'dictionary-cell'}`
                          }
                          style={{display: 'flex'}}
                        >
                          <ArcherElement
                            id={`graph_${val}`}
                            key={`graph_${val}`}
                            relations={
                              val !== 'NULL' && val !== ''
                              ? [
                                  {
                                    targetId: val,
                                    targetAnchor: 'left',
                                    sourceAnchor: 'right',
                                    style: { strokeColor: '#00e676', strokeWidth: 2, endMarker: true, startMarker: false },
                                  },
                                ]
                              : []
                            }
                          >
                            <div style={{paddingRight: '5px'}}>{key !== ' ' ? `${val}` : null}</div>
                          </ArcherElement>
                        </div>
                      </div>
                  ))}
                  <div className={`${highlightedMapVariablesForC.includes(mapName) ? 'highlighted-dictionary-cell merged' : 'dictionary-cell merged'}`}>
                    {mapName}
                    <br/>
                    <span style={{fontSize: '11px'}}> {mapAddressesForC[mapName]} </span>
                  </div>
                </div>
              ))}

              {mapVariablesForC && Object.entries(mapVariablesForC).map(([mapName, mapData], mapIndex) => (
                <div
                  key={mapIndex} 
                  className={`dictionary-container`}
                  style={{marginRight: '20px', minWidth: '110px', border: 'none', boxShadow: 'none'}}
                >
                  {Object.entries(mapData.value).map(([key, val], index) => (
                      <div key={`map-${index}`} style={{display: 'contents'}}>
                        <div></div>
                        <div 
                          className={`dictionary-cell`}
                          style={{display: 'flex', background: 'none'}}
                        >
                          {Array.isArray(adjList[key]) ? (
                            adjList[key].map((node, nodeIndex) => (
                              <div key={nodeIndex} style={{paddingLeft: '45px'}}>
                                <ArcherElement
                                  id={node.address}
                                  key={node.address}
                                  relations={
                                    node.next !== 'NULL'
                                    ? [
                                        {
                                          targetId: node.next,
                                          targetAnchor: 'left',
                                          sourceAnchor: 'right',
                                          style: { strokeColor: '#00e676', strokeWidth: 2, endMarker: true, startMarker: false },
                                        },
                                      ]
                                    : []
                                  }
                                >
                                  <div
                                    className={highlightedNodeVariables.includes(node.address) ? 'highlighted-node' : 'node'}
                                  >
                                    <div
                                      className={
                                        highlightedNodeVariables.includes(node.address) ||
                                        highlightDataPart.includes(node.address)
                                          ? 'highlighted-node-data'
                                          : 'node-data'
                                      }
                                      style={{display: 'block'}}
                                    >
                                      <div style={{borderBottom: '1px solid', fontSize: '11px'}}>vertex</div>
                                      <div style={{paddingTop: '7px'}}>{node.data}</div>
                                    </div>
                                    <div
                                      className={
                                        highlightedNodeVariables.includes(node.address) ||
                                        highlightNextAddressPart.includes(node.address) 
                                          ? 'highlighted-node-pointer' 
                                          : 'node-pointer'
                                      }
                                      style={{display: 'block'}}
                                    >
                                      <div style={{borderBottom: '1px solid', fontSize: '11px'}}>next</div>
                                      <div style={{paddingTop: '7px'}}>{node.next}</div>
                                    </div>
                                  </div>
                                </ArcherElement>
                                <div
                                  className={
                                    highlightedNodeVariables.includes(node.address) ||
                                    highlightNodeAddress.includes(node.address)
                                      ? 'highlighted-node-address'
                                      : 'node-address'
                                  }
                                >{node.address}</div>
                              </div>
                            ))
                          ) : ("")}
                        </div>
                      </div>
                  ))}
                </div>
              ))}

              {/* <div className="node-container" style={{paddingTop: '15px', paddingLeft: '185px', width: '100%'}}> */}
                {nodeVariables && Object.entries(nodeVariables).map(([key, { variable_name, node_name, value, address, parent, positionDown }], index) => (
                  <div className="node-block" key={index}>
                    <ArcherElement
                      id={address}
                      key={address}
                      relations={
                        parent 
                        ? [
                            {
                              targetId: parent,
                              targetAnchor: parent === 'head' ? 'bottom' : 'right',
                              sourceAnchor: parent === 'head' ? 'left' : 'left',
                              style: { strokeColor: '#00e676', strokeWidth: 2, endMarker: false, startMarker: true },
                            },
                          ]
                        : []
                      }
                    >
                      <div
                        className={highlightedNodeVariables.includes(address) ? 'highlighted-node' : 'node'}
                        style={{
                          marginTop: positionDown ? '50px' : '0px'
                        }}
                      >
                        {value.data !== 'NULL' ? 
                          <>
                            <div
                              className={
                                highlightedNodeVariables.includes(address) ||
                                highlightDataPart.includes(address)
                                  ? 'highlighted-node-data'
                                  : 'node-data'
                              }
                              style={{display: 'block'}}
                            >
                              <div style={{borderBottom: '1px solid', fontSize: '11px'}}>vertex</div>
                              <div style={{paddingTop: '7px'}}>{value.data}</div>
                            </div>
                            <div
                              className={
                                highlightedNodeVariables.includes(address) ||
                                highlightNextAddressPart.includes(address) 
                                  ? 'highlighted-node-pointer' 
                                  : 'node-pointer'
                              }
                              style={{display: 'block'}}
                            >
                              <div style={{borderBottom: '1px solid', fontSize: '11px'}}>next</div>
                              <div style={{paddingTop: '7px'}}>{value.next}</div>
                            </div>
                          </> : <div className='node-full'>{value.data}</div>}
                        
                      </div>
                    </ArcherElement>
                    <div
                      className={
                        highlightedNodeVariables.includes(address) ||
                        highlightNodeAddress.includes(address)
                          ? 'highlighted-node-address'
                          : 'node-address'
                      }
                    >
                      {node_name ? node_name : address}
                    </div>
                  </div>
                ))}
              {/* </div> */}

              <div style={{paddingLeft: '20px'}}>
                {setsVariables && Object.entries(setsVariables).map(([setName, setData], setIndex) => (
                  <div 
                    key={setIndex} 
                    className={`${highlightedSetVariables.includes(setName) ? 'highlighted-dictionary-container' : 'dictionary-container'}`}
                    style={{minWidth: '110px'}}
                  >
                    {[...setData.value].map((value, valueIndex) => (
                      <div key={`map-${valueIndex}`} style={{ display: 'contents' }}>
                        <div 
                          className={
                            `${isSetIndexHighlighted(setName, value) ? 'highlighted-dictionary-row' : 'dictionary-row'} 
                            ${highlightedSetVariables.includes(setName) ? 'highlighted-dictionary-row' : 'dictionary-row'}`
                          }
                        >
                          Hash({value}) = {valueIndex}
                        </div>
                        <div 
                          className={
                            `${isSetIndexHighlighted(setName, value) ? 'highlighted-dictionary-cell' : 'dictionary-cell'} 
                            ${highlightedSetVariables.includes(setName) ? 'highlighted-dictionary-cell' : 'dictionary-cell'}`
                          }
                        >
                          {value}
                        </div>
                      </div>
                    ))}
                    <div className={`${highlightedSetVariables.includes(setName) ? 'highlighted-dictionary-cell merged' : 'dictionary-cell merged'}`}>
                      {setName}
                      <br/>
                      <span style={{fontSize: '11px'}}> {setsVariableAddresses[setName]} </span>
                    </div>
                  </div>
                ))}
              </div>

              <div style={{paddingLeft: '35px', display: 'flex'}}>
                {stackVariables && Object.entries(stackVariables).map(([stackName, stackData], stackIndex) => (
                  <div 
                    key={stackIndex} 
                    className={`${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-container' : 'stack-container'}`}
                    style={{marginLeft: '45px'}}
                  >
                    <div className={`${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-top-row' : 'stack-top-row'}`}
                    >index</div>
                    <div style={{}} 
                    className={`${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-top-cell' : 'stack-top-cell'}`}
                    >value</div>
                    {[...stackData.value].reverse().map((value, valueIndex) => (
                      <div key={`map-${valueIndex}`} style={{ display: 'contents' }}>
                        <div 
                          style={{whiteSpace: 'nowrap'}}
                          className={
                            `${isStackIndexHighlighted(stackName, stackData.value.length - 1 - valueIndex) ? 'highlighted-stack-row' : 'stack-row'} 
                            ${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-row' : 'stack-row'}`
                          }
                        >
                        {getStackIteratorName(stackName, stackData.value.length - 1 - valueIndex)}{stackData.value.length - 1 - valueIndex}
                        </div>
                        <div 
                          style={{minWidth: '40px', textAlign:'center'}}
                          className={
                            `${isStackIndexHighlighted(stackName, stackData.value.length - 1 - valueIndex) ? 'highlighted-stack-cell' : 'stack-cell'} 
                            ${highlightedStackVariables.includes(stackName) ? 'highlighted-stack-cell' : 'stack-cell'}
                            ${isStackIndexStrikeThrough(stackName, stackData.value.length - 1 - valueIndex) ? 'line-through' : ''}`
                          }
                        >
                          {value}
                        </div>
                      </div>
                    ))}
                    <div className={`${highlightedStackVariables.includes(stackName) ? 'highlighted-dictionary-cell merged' : 'dictionary-cell merged'}`}>
                      {stackName}
                      <br/>
                      <span style={{fontSize: '11px'}}> {stackVariableAddresses[stackName]} </span>
                    </div>
                  </div>
                ))}
              </div>

              <div style={{margin: '0 auto', display: 'flex', paddingLeft: '25px'}}>
                {variables && Object.entries(variables).map(([key, { variable_name, value }], index) => (
                  <div key={index} className={`variable-box-wrapper`}>
                    <div key={index} className={`variable-box ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}>
                    <div className="variable-value">{value}</div>
                    <div className={`${highlightedVariables.includes(variable_name) ? 'variable-name-highlighted' : 'variable-name'}`}>{variable_name}</div>
                    </div>
                    <div 
                    className={`variable-address ${highlightedVariables.includes(variable_name) ? 'highlighted-variable' : ''}`}
                    style={{marginBottom: '0px'}}
                    >{variableAddresses[key]}</div>
                  </div>
                ))}
              </div>

            </div>
          </>
        )}
      </ArcherContainer>
    </div>
  );
};

export default VisualizationCanvas;

import React, { useState, useRef } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from './AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';
import LanguageDropdown from '../LanguageDropdown';
import defaultExplanations from './explanations';

import { useFloatTypes } from './useFloatTypes';

const FloatTypes = ({ language, subTopicId }) => {
  const [speakingLang, setSpeakingLang] = useState("en-US");
  const [translatedExplanations, setTranslatedExplanations] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [piValue, setPiValue] = useState(3.14159);
  const [averageBodyTemperature, setAverageBodyTemperature] = useState(36.6);
  const [gravitationalAcceleration, setGravitationalAcceleration] = useState(9.81);

  const [inputPiValue, setInputPiValue] = useState(piValue);
  const [inputAverageBodyTemperature, setInputAverageBodyTemperature] = useState(averageBodyTemperature);
  const [inputGravitationalAcceleration, setInputGravitationalAcceleration] = useState(gravitationalAcceleration);

  const [breakpoints, setBreakpoints] = useState([]);

  const editorCardRef = useRef(null);

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runFloatTypes
  } = useFloatTypes(
    speakingLang,
    translatedExplanations,
    language,
    piValue,
    averageBodyTemperature,
    gravitationalAcceleration,
    breakpoints
  );

  const handleUpdateVariables = () => {
    setPiValue(inputPiValue);
    setAverageBodyTemperature(inputAverageBodyTemperature);
    setGravitationalAcceleration(inputGravitationalAcceleration);
    updateVariables(inputPiValue, inputAverageBodyTemperature, inputGravitationalAcceleration);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6" ref={editorCardRef}>
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Float Types Example"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            isRunning={isRunning}
            breakpoints={breakpoints}
            setBreakpoints={setBreakpoints}
            onOpenModal={() => {
              setInputPiValue(piValue);
              setInputAverageBodyTemperature(averageBodyTemperature);
              setInputGravitationalAcceleration(gravitationalAcceleration);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <div className="d-flex justify-content-center position-relative">
              <h3 className="section-title text-center flex-grow-1">Canvas</h3>
              <LanguageDropdown
                onLanguageChange={setSpeakingLang}
                onTranslate={setTranslatedExplanations}
                selectedProgrammingLang={language}
                defaultExplanations={defaultExplanations}
                isRunning={isRunning} 
                setIsTranslating={setIsTranslating} 
              />
            </div>

            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runFloatTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
              isTranslating={isTranslating}
              editorCardRef={editorCardRef}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        piValue={inputPiValue}
        setPiValue={setInputPiValue}
        averageBodyTemperature={inputAverageBodyTemperature}
        setAverageBodyTemperature={setInputAverageBodyTemperature}
        gravitationalAcceleration={inputGravitationalAcceleration}
        setGravitationalAcceleration={setInputGravitationalAcceleration}
        onSave={handleUpdateVariables}
      />
    </div>
  );
};

export default FloatTypes;

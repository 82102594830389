import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This code checks two boolean conditions (<b>is hungry</b> and <b>has food</b>) to determine if the person can eat:
        <ul style={{paddingLeft: '15px'}}>
            <li>
                If both <b>is hungry</b> and <b>has food</b> are True, it prints "Time to eat!".
            </li>
            <li>
                Otherwise, it prints "No food or not hungry."
            </li>
            <li>
                Finally, it outputs the values of <b>is hungry</b> and <b>has food</b> for reference.
            </li>
        </ul>
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Decision Making:</b> Model scenarios requiring multiple conditions to be satisfied, such as resource availability and user requirements.</li>
            <li><b>State Management:</b> Represent real-world states (e.g., hungry and having food) in applications like simulations or games.</li>
            <li><b>Control Systems:</b> Check preconditions for actions in automation or robotics (e.g., machine readiness).</li>
            <li><b>Educational Tools:</b> Demonstrate logical operators (and, or) and conditional statements in programming tutorials.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        <ul style={{paddingLeft: '15px'}}>
            <li>
                <b>letter:</b> Holds a single alphabetic character <b>'A'</b>
            </li>
            <li>
                <b>digit:</b> Holds a single numeric character <b>'1'</b>.
            </li>
            <li>
                <b>specialChar:</b> Holds a special character <b>'@'</b>.
            </li>
        </ul>
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Character Data:</b> Represent and process characters in text-based applications, like text editors or messaging systems.</li>
            <li><b>Input Validation:</b> Handle and validate single-character inputs, such as password restrictions or user commands.</li>
            <li><b>Educational Tools:</b> Teach variable types and character handling in programming.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

import { useState, useEffect, useRef } from 'react';
import { 
  dogConstructorForJava,
  dogBarkFunctionForJava,
  dogSleepFunctionForJava,
  dogInitForPython,
  dogBarkFunctionForPython,
  dogSleepFunctionForPython,
  dogConstructorForCpp,
  dogBarkFunctionForCpp,
  dogSleepFunctionForCpp
 } from './classFunctions';
import defaultExplanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';


export const useClassType = (speakingLang, translatedExplanations, language, initialClass, breakpoints) => {
  const [explanations, setExplanations] = useState(defaultExplanations);
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [classVariables, setClassVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [stringVariables, setStringVariables] = useState({});
  const [highlightedStringVariables, setHighlightedStringVariables] = useState([]);
  const [highlightedClassVariables, setHighlightedClassVariables] = useState([]);
  const [highlightedClassMethods, setHighlightedClassMethods] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef, speakingLang);
  const [isMuted, setLocalIsMuted] = useState(false);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);
  

  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  useEffect(() => {
    if (translatedExplanations && language) {
      setExplanations((prevExplanations) => ({
        ...prevExplanations,
        [language]: Object.keys(prevExplanations[language] || {}).reduce((acc, key, index) => {
          acc[key] = translatedExplanations[index] || prevExplanations[language][key];
          return acc;
        }, {}),
      }));
    } else {
      setExplanations(defaultExplanations);
    }
  }, [translatedExplanations, language]);

  const updateVariables = (newInitialClass) => {
    resetState();

    // Dynamically update the code samples
    let updatedCodeSamples = "";
    if (language === 'C++') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/buddy\(["'].*?["']\)/, `buddy("${newInitialClass?.name}")`)
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/Dog\(["'].*?["']\)/, `Dog("${newInitialClass?.name}")`)
      );
    }
     
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setCallingLine(null);
    setFocusedEndLine(null);
    setVariables({});
    setClassVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedClassVariables([]);
    setHighlightedClassMethods([]);
    setStringVariables({});
    setHighlightedStringVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber, customLog=null) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);

    const logMessageText = customLog 
    ? `Line ${lineNumber + 1}: ${customLog}` 
    : `Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`;

    await logMessage(logMessageText);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null, time=null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms;
    if (time === null) {
        ms = delayRef.current;
    } else {
        ms = time;
    }
    
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };


  const runClassTypePython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(11);
    let classAttributes = [
      {
        name: 'name',
        dataType: '<str>'
      }
    ];

    let classMethods = [
      {
        name: '__init__',
        type: 'constructor',
        parameters: [{name: 'self', dataType: '<Dog>'}, {name: 'name', dataType: '<str>'}],
        returnType: null
      },
      {
        name: 'bark',
        type: 'member',
        parameters: [{name: 'self', dataType: '<Dog>'}],
        returnType: 'void'
      },
      {
        name: 'sleep',
        type: 'member',
        parameters: [{name: 'self', dataType: '<Dog>'},],
        returnType: 'void'
      }
    ];

    setClassVariables((vars) => ({ ...vars, buddy: { 
      variable_name: 'buddy',
      className: 'Dog',
      value: {attributes: classAttributes, methods: classMethods} 
    } }));
    setHighlightedClassVariables(['buddy']);
    await customSleep();
    setHighlightedClassVariables(['']);

    setStringVariables((vars) => ({ ...vars, name: { variable_name: '', value: initialClass.name } }));
    setHighlightedStringVariables(['name']);
    await customSleep(11);
    setHighlightedStringVariables([]);

    await highlightCallingLine(11);
    setHighlightedClassMethods(['__init__']);
    await dogInitForPython(
      initialClass,
      highlightLine,
      setStringVariables,
      setHighlightedStringVariables,
      customSleep
    )
    setHighlightedClassMethods([]);
    await highlightLine(11, "Returning from the constructor method.");
    await customSleep(11);


    await highlightLine(13);
    await customSleep(13);

    await highlightCallingLine(13);
    setHighlightedClassMethods(['bark']);
    await dogBarkFunctionForPython(
      initialClass,
      highlightLine,
      setHighlightedStringVariables,
      customSleep,
      logMessage
    )
    setHighlightedClassMethods([]);
    await highlightLine(13, "Returning from the bark method.");
    await customSleep(13);

    await highlightLine(14);
    await customSleep(14);

    await highlightCallingLine(14);
    setHighlightedClassMethods(['sleep']);
    await dogSleepFunctionForPython(
      initialClass,
      highlightLine,
      setHighlightedStringVariables,
      customSleep,
      logMessage
    )
    setHighlightedClassMethods([]);
    await highlightLine(14, "Returning from the sleep method.");
    await customSleep(14);

    await focusEndLine(14);
    setIsRunning(false);
  };

  const runClassTypeJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(16);
    let classAttributes = [
      {
        name: 'name',
        dataType: 'String'
      }
    ];

    let classMethods = [
      {
        name: 'Dog',
        type: 'constructor',
        parameters: [{name: 'name', dataType: 'String'}],
        returnType: null
      },
      {
        name: 'bark',
        type: 'member',
        parameters: [],
        returnType: 'void'
      },
      {
        name: 'sleep',
        type: 'member',
        parameters: [],
        returnType: 'void'
      }
    ];

    setClassVariables((vars) => ({ ...vars, buddy: { 
      objectName: 'buddy',
      className: 'Dog',
      value: {attributes: classAttributes, methods: classMethods} 
    } }));
    setHighlightedClassVariables(['buddy']);
    await customSleep();
    setHighlightedClassVariables(['']);

    setStringVariables((vars) => ({ ...vars, name: { variable_name: '', value: initialClass.name } }));
    setHighlightedStringVariables(['name']);
    await customSleep(16);
    setHighlightedStringVariables([]);

    await highlightCallingLine(16);
    setHighlightedClassMethods(['Dog']);
    await dogConstructorForJava(
      initialClass,
      highlightLine,
      setStringVariables,
      setHighlightedStringVariables,
      customSleep
    )
    setHighlightedClassMethods([]);
    await highlightLine(16, "Returning from the constructor method.");
    await customSleep(16);


    await highlightLine(18);
    await customSleep(18);

    await highlightCallingLine(18);
    setHighlightedClassMethods(['bark']);
    await dogBarkFunctionForJava(
      initialClass,
      highlightLine,
      setHighlightedStringVariables,
      customSleep,
      logMessage
    )
    setHighlightedClassMethods([]);
    await highlightLine(18, "Returning from the bark method.");
    await customSleep(18);

    await highlightLine(19);
    await customSleep(19);

    await highlightCallingLine(19);
    setHighlightedClassMethods(['sleep']);
    await dogSleepFunctionForJava(
      initialClass,
      highlightLine,
      setHighlightedStringVariables,
      customSleep,
      logMessage
    )
    setHighlightedClassMethods([]);
    await highlightLine(19, "Returning from the sleep method.");
    await customSleep(19);

    await focusEndLine(21);
    setIsRunning(false);
  };

  const runClassTypeCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(20);
    let classAttributes = [
      {
        name: 'name',
        dataType: 'string'
      }
    ];

    let classMethods = [
      {
        name: 'Dog',
        type: 'constructor',
        parameters: [{name: 'name', dataType: 'string'}],
        returnType: null
      },
      {
        name: 'bark',
        type: 'member',
        parameters: [],
        returnType: 'void'
      },
      {
        name: 'sleep',
        type: 'member',
        parameters: [],
        returnType: 'void'
      }
    ];

    setClassVariables((vars) => ({ ...vars, buddy: { 
      variable_name: 'buddy', 
      value: {attributes: classAttributes, methods: classMethods} 
    } }));
    setHighlightedClassVariables(['buddy']);
    await customSleep();
    setHighlightedClassVariables(['']);

    setStringVariables((vars) => ({ ...vars, name: { variable_name: '', value: initialClass.name } }));
    setHighlightedStringVariables(['name']);
    await customSleep(20);
    setHighlightedStringVariables([]);

    await highlightCallingLine(20);
    setHighlightedClassMethods(['Dog']);
    await dogConstructorForCpp(
      initialClass,
      highlightLine,
      setStringVariables,
      setHighlightedStringVariables,
      customSleep
    )
    setHighlightedClassMethods([]);
    await highlightLine(20, "Returning from the constructor method.");
    await customSleep(20);


    await highlightLine(22);
    await customSleep(22);

    await highlightCallingLine(22);
    setHighlightedClassMethods(['bark']);
    await dogBarkFunctionForCpp(
      initialClass,
      highlightLine,
      setHighlightedStringVariables,
      customSleep,
      logMessage
    )
    setHighlightedClassMethods([]);
    await highlightLine(22, "Returning from the bark method.");
    await customSleep(22);

    await highlightLine(23);
    await customSleep(23);

    await highlightCallingLine(23);
    setHighlightedClassMethods(['sleep']);
    await dogSleepFunctionForCpp(
      initialClass,
      highlightLine,
      setHighlightedStringVariables,
      customSleep,
      logMessage
    )
    setHighlightedClassMethods([]);
    await highlightLine(23, "Returning from the sleep method.");
    await customSleep(23);

    await highlightLine(25);
    await customSleep(25);

    await focusEndLine(21);
    setIsRunning(false);
  };


  const runClassType = async () => {
    if (language === 'Java') {
      await runClassTypeJava();
    } else if (language === 'C++') {
      await runClassTypeCpp();
    } else if (language === 'Python') {
      await runClassTypePython();
    }
  };

  return {
    highlightedLine,
    callingLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    classVariables,
    stringVariables,
    highlightedVariables,
    highlightedClassVariables,
    highlightedClassMethods,
    highlightedStringVariables,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setCallingLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setClassVariables,
    setStringVariables,
    setHighlightedVariables,
    setHighlightedClassVariables,
    setHighlightedClassMethods,
    setHighlightedStringVariables,
    runClassType
  };
};

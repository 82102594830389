import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useStopSpeech from '../hooks/useStopSpeech';
import '../LearningTopics.css';

const LearningTopics = ({ selectedLanguage, onSelectTopic }) => {
  const [topics, setTopics] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [subTopicName, setSubTopicName] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  useStopSpeech();

  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);

    const fetchTopics = async () => {
      const token = localStorage.getItem('userToken');
      let headers = {};
      if (token) {
        headers = {
          Authorization: `token ${token}`
        };
      }
      try {
        const response = await axios.get(`${apiUrl}/api/topics-json/`, {
          headers: headers,
        });
        setTopics(response.data);
      } catch (error) {
        console.error('Error fetching topics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTopics();
  }, []);

  const languageTopics = topics[selectedLanguage]?.topics || {};

  // Display spinner while loading
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const handlePrimeTopic = (subTopicName) => {
    setSubTopicName(subTopicName)
    setModalVisible(true);
  };

  return (
    <div className="learning-topics container-fluid">
      <h3 className="text-center mb-5 mobile-header">{selectedLanguage} Learning Topics</h3>

      <div className="topics-container row">
        {Object.keys(languageTopics).map(topic => (
          <div key={topic} className="col-md-3 mb-3" style={{ padding: '10px' }}>
            <div className="card h-100 p-4 d-flex flex-column shadow-sm">
              <h2 className="mb-2">{topic}</h2>
              <hr className="divider my-2" />
              <p className="text-muted">
                Completed: {languageTopics[topic].completed} / {languageTopics[topic].total}
              </p>
              <div className="progress-bar my-2">
                <div
                  className="progress"
                  style={{
                    width: `${(languageTopics[topic].completed / languageTopics[topic].total) * 100}%`,
                  }}
                ></div>
              </div>
              <ul className="list-unstyled mt-2">
                {languageTopics[topic].subTopics
                  .filter(subTopic => subTopic.shouldDisplay)
                  .map(subTopic => (
                    <li
                      key={subTopic.name}
                      className={`sub-topic ${subTopic.isCompleted ? 'completed' : ''}`}
                      onClick={() => {
                        if (subTopic.isFree || subTopic.isPrimeMember) {
                          onSelectTopic(subTopic.name, subTopic.id);
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                          });
                        } else {
                          handlePrimeTopic(subTopic.displayName);
                        }
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {subTopic.displayName}
                      {!subTopic.isFree && !subTopic.isPrimeMember && (
                        <span>
                          <i className="fa fa-crown me-1" style={{ fontSize: '11px' }}></i>
                        </span>
                      )}
                      {subTopic.isCompleted && (
                        <i className="fa fa-check" style={{ fontSize: '11px' }}></i>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      {/* Bootstrap Modal */}
      {modalVisible &&
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-glass-avatar">
              <div className="modal-header">
                <h5 className="modal-title-avatar" id="exampleModalLabel">Prime Access</h5>
              </div>
              <div className="modal-body">
                Access to the <b>{subTopicName}</b> topic is restricted. <br/>
                Click <a href="/pricing" className="subscribe-link"><span className="badge bg-dark me-2">Subscribe</span></a>to make it yours!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={() => setModalVisible(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>

  );
};

export default LearningTopics;

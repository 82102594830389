import { useState, useEffect, useRef } from 'react';
import defaultExplanations from './explanations';
import {
  runPalindromeAlgorithmPython,
  runPalindromeAlgorithmJava,
  runPalindromeAlgorithmC,
  runPalindromeAlgorithmCpp,
} from './stringPalindromeAlgorithms';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useStringPalindrome = (speakingLang, translatedExplanations, language, string, breakpoints) => {
  const [explanations, setExplanations] = useState(defaultExplanations);
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [stringVariables, setStringVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedStringVariables, setHighlightedStringVariables] = useState([]);
  const [highlightedStringIndices, setHighlightedStringIndices] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef, speakingLang);
  const [isMuted, setLocalIsMuted] = useState(false);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  useEffect(() => {
    if (translatedExplanations && language) {
      setExplanations((prevExplanations) => ({
        ...prevExplanations,
        [language]: Object.keys(prevExplanations[language] || {}).reduce((acc, key, index) => {
          acc[key] = translatedExplanations[index] || prevExplanations[language][key];
          return acc;
        }, {}),
      }));
    } else {
      setExplanations(defaultExplanations);
    }
  }, [translatedExplanations, language]);

  const updateVariables = (newString) => {
    resetState();

    // Dynamically update the code samples
    let updatedCodeSamples = "";
    if (language === "Python") {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/string = ".*?"/, `string = "${newString}"`)
      );
    } else if (language === "Java") {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/str = ".*?"/, `str = "${newString}"`)
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/str\[\]\s*=\s*".*?"/, `str[] = "${newString}"`)
      );
     }

    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setLogs([]);
    setVariables({});
    setArrayVariables({});
    setStringVariables({});
    setHighlightedVariables([]);
    setHighlightedStringVariables([]);
    setHighlightedStringIndices([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber, customLog=null) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    
    const logMessageText = customLog 
    ? `Line ${lineNumber + 1}: ${customLog}` 
    : `Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`;

    await logMessage(logMessageText);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runStringPalindromePython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(14);
    setStringVariables({ string: { variable_name: 'string', value: string } });
    setHighlightedStringVariables(['string']);
    await customSleep(14);
    setHighlightedStringVariables([]);

    await highlightLine(15);
    await customSleep(15);

    await highlightCallingLine(15);
    let isPalindrome = await runPalindromeAlgorithmPython(
        string,
        highlightLine,
        setVariables,
        setHighlightedVariables,
        setStringVariables,
        setHighlightedStringIndices,
        setHighlightedStringVariables,
        customSleep
    );
    await highlightLine(15, "Returning from the is_palindrome method and checking if return value is True.");
    await customSleep(15);

    if (isPalindrome) {
        await highlightLine(16);
        await logMessage(`"${string}" is a palindrome.`);
        await customSleep(16);
    } else {
        await highlightLine(17);
        await customSleep(17);

        await highlightLine(18);
        await logMessage(`"${string}" is not a palindrome.`);
        await customSleep(18);
    }

    await focusEndLine(18);
    setIsRunning(false);
  };

  const runStringPalindromeJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(16);
    setStringVariables({ str: { variable_name: 'str', value: string } });
    setHighlightedStringVariables(['str']);
    await customSleep(16);
    setHighlightedStringVariables([]);

    await highlightLine(17);
    await customSleep(17);

    await highlightCallingLine(17);
    let isPalindrome = await runPalindromeAlgorithmJava(
        string,
        highlightLine,
        setVariables,
        setHighlightedVariables,
        setStringVariables,
        setHighlightedStringIndices,
        setHighlightedStringVariables,
        customSleep
    );
    await highlightLine(17, "Returning from the isPalindrome method and checking if return value is true.");
    await customSleep(17);

    if (isPalindrome) {
        await highlightLine(18);
        await logMessage(`"${string}" is a palindrome.`);
        await customSleep(18);
    } else {
        await highlightLine(19);
        await customSleep(19);

        await highlightLine(20);
        await logMessage(`"${string}" is not a palindrome.`);
        await customSleep(20);
    }

    await focusEndLine(23);
    setIsRunning(false);
  };

  const runStringPalindromeC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(19);
    setStringVariables({ str: { variable_name: 'str', value: string } });
    setHighlightedStringVariables(['str']);
    await customSleep(19);
    setHighlightedStringVariables([]);

    await highlightLine(20);
    await customSleep(20);

    await highlightCallingLine(20);
    let isPalindrome = await runPalindromeAlgorithmC(
        string,
        highlightLine,
        setVariables,
        setHighlightedVariables,
        setStringVariables,
        setHighlightedStringIndices,
        setHighlightedStringVariables,
        customSleep
    );
    await highlightLine(20, "Returning from the isPalindrome method and checking if return value is 1.");
    await customSleep(20);

    if (isPalindrome) {
        await highlightLine(21);
        await logMessage(`"${string}" is a palindrome.`);
        await customSleep(21);
    } else {
        await highlightLine(22);
        await customSleep(22);

        await highlightLine(23);
        await logMessage(`"${string}" is not a palindrome.`);
        await customSleep(23);
    }

    await highlightLine(25);
    await customSleep(25);

    await focusEndLine(26);
    setIsRunning(false);
  };

  const runStringPalindromeCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(20);
    setStringVariables({ str: { variable_name: 'str', value: string } });
    setHighlightedStringVariables(['str']);
    await customSleep(20);
    setHighlightedStringVariables([]);

    await highlightLine(21);
    await customSleep(21);

    await highlightCallingLine(21);
    let isPalindrome = await runPalindromeAlgorithmCpp(
        string,
        highlightLine,
        setVariables,
        setHighlightedVariables,
        setStringVariables,
        setHighlightedStringIndices,
        setHighlightedStringVariables,
        customSleep
    );
    await highlightLine(21, "Returning from the isPalindrome method and checking if return value is true.");
    await customSleep(21);

    if (isPalindrome) {
        await highlightLine(22);
        await logMessage(`"${string}" is a palindrome.`);
        await customSleep(22);
    } else {
        await highlightLine(23);
        await customSleep(23);

        await highlightLine(24);
        await logMessage(`"${string}" is not a palindrome.`);
        await customSleep(24);
    }

    await highlightLine(26);
    await customSleep(26);

    await focusEndLine(27);
    setIsRunning(false);
  };

  const runStringPalindrome = async () => {
    if (language === 'Python') {
      await runStringPalindromePython();
    } else if (language === 'Java') {
      await runStringPalindromeJava();
    } else if (language === 'C') {
      await runStringPalindromeC();
    } else if (language === 'C++') {
      await runStringPalindromeCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    stringVariables,
    highlightedVariables,
    highlightedStringVariables,
    highlightedStringIndices,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setStringVariables,
    setHighlightedVariables,
    setHighlightedStringVariables,
    setHighlightedStringIndices,
    runStringPalindrome,
  };
};

import { useState, useEffect, useRef } from 'react';
import defaultExplanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useValueInterchange = (speakingLang, translatedExplanations, language, initialA, initialB, breakpoints) => {
  const [explanations, setExplanations] = useState(defaultExplanations);
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef, speakingLang);
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  useEffect(() => {
    if (translatedExplanations && language) {
      setExplanations((prevExplanations) => ({
        ...prevExplanations,
        [language]: Object.keys(prevExplanations[language] || {}).reduce((acc, key, index) => {
          acc[key] = translatedExplanations[index] || prevExplanations[language][key];
          return acc;
        }, {}),
      }));
    } else {
      setExplanations(defaultExplanations);
    }
  }, [translatedExplanations, language]);

  const updateVariables = (newInitialA, newInitialB) => {
    resetState();

    // Dynamically update the code samples
    const updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
      line
        .replace(/a = \d+/, `a = ${newInitialA}`)
        .replace(/b = \d+/, `b = ${newInitialB}`)
    );
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setHighlightedVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null, time = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms;
    if (time === null) {
        ms = delayRef.current;
    } else {
        ms = time;
    }
    
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runValueInterchangePython = async () => {
    await highlightLine(2);
    setVariables((vars) => ({ ...vars, a: { variable_name: 'a', value: initialA } }));
    setHighlightedVariables(['a']);
    await customSleep(2);
    setHighlightedVariables([]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, b: { variable_name: 'b', value: initialB } }));
    setHighlightedVariables(['b']);
    await customSleep(3);
    setHighlightedVariables([]);

    await highlightLine(6);
    await logMessage(`Before swapping: a = ${initialA}, b = ${initialB}`);
    await customSleep(6);

    await highlightLine(9);
    let temp = initialA;
    setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: '' } }));
    setHighlightedVariables(['temp', 'a']);
    await customSleep(null, 1000);
    setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
    await customSleep(9);
    setHighlightedVariables([]);

    await highlightLine(10);
    initialA = initialB;
    setHighlightedVariables(['a', 'b']);
    await customSleep(null, 1000);
    setVariables((vars) => ({ ...vars, a: { variable_name: 'a', value: initialA } }));
    await customSleep(10);
    setHighlightedVariables([]);

    await highlightLine(11);
    initialB = temp
    setHighlightedVariables(['b', 'temp']);
    await customSleep(null, 1000);
    setVariables((vars) => ({ ...vars, b: { variable_name: 'b', value: initialB } }));
    await customSleep(11);
    setHighlightedVariables([]);

    await highlightLine(14);
    await logMessage(`After swapping: a = ${initialA}, b = ${initialB}`);
    await customSleep(14);

    await focusEndLine(14);
    setIsRunning(false);
  };

  const runValueInterchangeJava = async () => {
    await highlightLine(3);
    setVariables((vars) => ({ ...vars, a: { variable_name: 'a', value: initialA } }));
    setHighlightedVariables(['a']);
    await customSleep(3);
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, b: { variable_name: 'b', value: initialB } }));
    setHighlightedVariables(['b']);
    await customSleep(4);
    setHighlightedVariables([]);

    await highlightLine(7);
    await logMessage(`Before swapping: a = ${initialA}, b = ${initialB}`);
    await customSleep(7);

    await highlightLine(10);
    let temp = initialA;
    setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: '' } }));
    setHighlightedVariables(['temp', 'a']);
    await customSleep(null, 1000);
    setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
    await customSleep(10);
    setHighlightedVariables([]);

    await highlightLine(11);
    initialA = initialB;
    setHighlightedVariables(['a', 'b']);
    await customSleep(null, 1000);
    setVariables((vars) => ({ ...vars, a: { variable_name: 'a', value: initialA } }));
    await customSleep(11);
    setHighlightedVariables([]);

    await highlightLine(12);
    initialB = temp
    setHighlightedVariables(['b', 'temp']);
    await customSleep(null, 1000);
    setVariables((vars) => ({ ...vars, b: { variable_name: 'b', value: initialB } }));
    await customSleep(12);
    setHighlightedVariables([]);

    await highlightLine(15);
    await logMessage(`After swapping: a = ${initialA}, b = ${initialB}`);
    await customSleep(15);

    await focusEndLine(17);
    setIsRunning(false);
  };

  const runValueInterchangeC = async () => {
    await highlightLine(4);
    setVariables((vars) => ({ ...vars, a: { variable_name: 'a', value: initialA } }));
    setHighlightedVariables(['a']);
    await customSleep(4);
    setHighlightedVariables([]);

    await highlightLine(5);
    setVariables((vars) => ({ ...vars, b: { variable_name: 'b', value: initialB } }));
    setHighlightedVariables(['b']);
    await customSleep(5);
    setHighlightedVariables([]);

    await highlightLine(8);
    await logMessage(`Before swapping: a = ${initialA}, b = ${initialB}`);
    await customSleep(8);

    await highlightLine(11);
    let temp = initialA;
    setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: '' } }));
    setHighlightedVariables(['temp', 'a']);
    await customSleep(null, 1000);
    setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
    await customSleep(11);
    setHighlightedVariables([]);

    await highlightLine(12);
    initialA = initialB;
    setHighlightedVariables(['a', 'b']);
    await customSleep(null, 1000);
    setVariables((vars) => ({ ...vars, a: { variable_name: 'a', value: initialA } }));
    await customSleep(12);
    setHighlightedVariables([]);

    await highlightLine(13);
    initialB = temp
    setHighlightedVariables(['b', 'temp']);
    await customSleep(null, 1000);
    setVariables((vars) => ({ ...vars, b: { variable_name: 'b', value: initialB } }));
    await customSleep(13);
    setHighlightedVariables([]);

    await highlightLine(16);
    await logMessage(`After swapping: a = ${initialA}, b = ${initialB}`);
    await customSleep(16);

    await highlightLine(17);
    await customSleep(17);

    await focusEndLine(18);
    setIsRunning(false);
  };

  const runValueInterchangeCpp = async () => {
    await highlightLine(5);
    setVariables((vars) => ({ ...vars, a: { variable_name: 'a', value: initialA } }));
    setHighlightedVariables(['a']);
    await customSleep(5);
    setHighlightedVariables([]);

    await highlightLine(6);
    setVariables((vars) => ({ ...vars, b: { variable_name: 'b', value: initialB } }));
    setHighlightedVariables(['b']);
    await customSleep(6);
    setHighlightedVariables([]);

    await highlightLine(9);
    await logMessage(`Before swapping: a = ${initialA}, b = ${initialB}`);
    await customSleep(9);

    await highlightLine(12);
    let temp = initialA;
    setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: '' } }));
    setHighlightedVariables(['temp', 'a']);
    await customSleep(null, 1000);
    setVariables((vars) => ({ ...vars, temp: { variable_name: 'temp', value: temp } }));
    await customSleep(12);
    setHighlightedVariables([]);

    await highlightLine(13);
    initialA = initialB;
    setHighlightedVariables(['a', 'b']);
    await customSleep(null, 1000);
    setVariables((vars) => ({ ...vars, a: { variable_name: 'a', value: initialA } }));
    await customSleep(13);
    setHighlightedVariables([]);

    await highlightLine(14);
    initialB = temp
    setHighlightedVariables(['b', 'temp']);
    await customSleep(null, 1000);
    setVariables((vars) => ({ ...vars, b: { variable_name: 'b', value: initialB } }));
    await customSleep(14);
    setHighlightedVariables([]);

    await highlightLine(17);
    await logMessage(`After swapping: a = ${initialA}, b = ${initialB}`);
    await customSleep(17);

    await highlightLine(18);
    await customSleep(18);

    await focusEndLine(19);
    setIsRunning(false);
  };

  const runValueInterchange = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    if (language === 'Python') {
      await runValueInterchangePython();
    } else if (language === 'Java') {
      await runValueInterchangeJava();
    } else if (language === 'C') {
      await runValueInterchangeC();
    } else if (language === 'C++') {
      await runValueInterchangeCpp();
    }

    setIsRunning(false);
  };

  return {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    runValueInterchange
  };
};

import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This implementation constructs a Binary Tree by adding nodes level by level, filling each level from left to right:
        <ul style={{paddingLeft: '15px'}}>
            <li><b>Node Class:</b> Each Node contains data, a left child, and a right child.</li>
            <li>
                <b>BinarySearchTree Class:</b>
                <ul style={{paddingLeft: '15px'}}>
                    <li>
                        Maintains the root of the tree.
                    </li>
                    <li>
                        The add method uses a breadth-first traversal to find the next available position:
                        <ul style={{paddingLeft: '15px'}}>
                            <li>
                                If the tree is empty, the new node becomes the root.
                            </li>
                            <li>
                                Otherwise, use a queue to traverse the tree level by level:
                                <ul style={{paddingLeft: '15px'}}>
                                    <li>If the current node has no left child, assign the new node to current.left .</li>
                                    <li>If the current node has no right child, assign the new node to current.right .</li>
                                    <li>If both children exist, enqueue them for further traversal.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
      </div>
      <div id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Scheduling Systems:</b> Represent tasks in scheduling problems, ensuring fairness in allocation by filling each level before proceeding to the next.</li>
            <li><b>Data Organization:</b> Store hierarchical data like organizational charts, family trees, or directory structures.</li>
            <li><b>Network Simulations:</b> Model systems like computer networks or communication hierarchies.</li>
            <li><b>Gaming:</b> Represent decision trees in games, especially for AI decision-making or pathfinding.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

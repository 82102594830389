import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <p id="algorithmDescription">
        This code snippet initializes two variables with specific values and displays their 
        values using a formatted output statement.
      </p>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Configuration Settings:</b> Initialize and display key parameters in applications (e.g., setting initial values for a program).</li>
            <li><b>Basic Debugging:</b> Track the values of variables during execution to ensure correctness.</li>
            <li><b>Educational Demonstrations:</b> Teach the basics of variable declaration, initialization, and formatted string output in programming courses.</li>
            <li><b>Data Entry Systems:</b> Store and display input values provided by users or configuration scripts.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

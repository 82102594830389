import { useState, useEffect, useRef } from 'react';
import { bubbleSortPython, bubbleSortJava, bubbleSortC, bubbleSortCpp } from './bubbleSortAlgorithms';
import defaultExplanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';


export const useBubbleSort = (speakingLang, translatedExplanations, language, list, breakpoints) => {
  const [explanations, setExplanations] = useState(defaultExplanations);
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef, speakingLang);
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);
  
  
  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  useEffect(() => {
    if (translatedExplanations && language) {
      setExplanations((prevExplanations) => ({
        ...prevExplanations,
        [language]: Object.keys(prevExplanations[language] || {}).reduce((acc, key, index) => {
          acc[key] = translatedExplanations[index] || prevExplanations[language][key];
          return acc;
        }, {}),
      }));
    } else {
      setExplanations(defaultExplanations);
    }
  }, [translatedExplanations, language]);

  const updateVariables = (newList) => {
    resetState();

    // Dynamically update the code samples
    let updatedCodeSamples = "";
    if (language === 'Python') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/list = \[.*?\]/, `list = [${newList.join(", ")}]`)
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(
            /int list\[\] = \{.*?\};/,
            `int list[] = {${newList.join(", ")}};`
          )
      );
    }
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedArrayVariables([]);
    setHighlightedIndices([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber, customLog=null) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    
    const logMessageText = customLog 
    ? `Line ${lineNumber + 1}: ${customLog}` 
    : `Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`;

    await logMessage(logMessageText);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
    await customSleep(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runBubbleSortPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(8);
    setArrayVariables({ list: { variable_name: 'list', value: list } });
    setHighlightedArrayVariables(['list']);
    await customSleep(8);
    setHighlightedArrayVariables([]);

    await highlightLine(9);
    await customSleep(9);

    await highlightCallingLine(9);
    let sortedList = await bubbleSortPython(list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep);
    setArrayVariables((vars) => ({ ...vars, sorted_list: { variable_name: 'sorted_list', value: sortedList } }));
    setHighlightedArrayVariables(['sorted_list']);
    let customLog = "Returning from the bubble_sort method and storing the returned value in the sorted_list variable.";
    await highlightLine(9, customLog);
    await customSleep(9);
    setHighlightedArrayVariables([]);

    await highlightLine(10);
    await logMessage(`Sorted list: ${sortedList}`);
    await customSleep(10);

    await focusEndLine(12);
    setIsRunning(false);
  };

  const runBubbleSortJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(16);
    await customSleep(16);

    await highlightLine(17);
    setArrayVariables({ list: { variable_name: 'list', value: list } });
    setHighlightedArrayVariables(['list']);
    await customSleep(17);
    setHighlightedArrayVariables([]);

    await highlightLine(18);
    await customSleep(18);

    await highlightCallingLine(18);
    let sortedList = await bubbleSortJava(list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep);
    setArrayVariables((vars) => ({ ...vars, sortedList: { variable_name: 'sortedList', value: sortedList } }));
    setHighlightedArrayVariables(['sortedList']);
    let customLog = "Returning from the bubbleSort method and storing the returned value in the sortedList variable.";
    await highlightLine(18, customLog);
    await customSleep(18);
    setHighlightedArrayVariables([]);

    await highlightLine(19);
    await logMessage(`Sorted list:`);
    await customSleep(19);

    for(let itr = 0; itr < sortedList.length; itr++) {
      await highlightLine(20);
      setVariables((vars) => ({ ...vars, itr: { variable_name: 'itr', value: itr } }));
      setHighlightedVariables(['itr']);
      await customSleep(20);
      setHighlightedVariables([]);

      await highlightLine(21);
      setHighlightedIndices([{ arrayName: 'sortedList', index: itr, iteratorName: 'itr' }]);
      await logMessage(sortedList[itr]);
      await customSleep(21);
      setHighlightedIndices([]);
    }

    await focusEndLine(20);
    setIsRunning(false);
  };

  const runBubbleSortC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(16);
    setArrayVariables({ list: { variable_name: 'list', value: list } });
    setHighlightedArrayVariables(['list']);
    await customSleep(16);
    setHighlightedArrayVariables([]);

    await highlightLine(17);
    let n = list.length;
    setVariables((vars) => ({ ...vars, n: { variable_name: 'n', value: n } }));
    setHighlightedVariables(['n']);
    await customSleep(17);
    setHighlightedVariables([]);

    await highlightLine(18);
    await customSleep(18);

    await highlightCallingLine(18);
    let sortedList = await bubbleSortC(list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep);
    setArrayVariables((vars) => ({ ...vars, sortedList: { variable_name: 'sortedList', value: sortedList } }));
    setHighlightedArrayVariables(['sortedList']);
    let customLog = "Returning from the bubbleSort function and storing the returned value in the sortedList variable."
    await highlightLine(18, customLog);
    await customSleep(18);
    setHighlightedArrayVariables([]);

    await highlightLine(19);
    await logMessage(`Sorted list: `);
    await customSleep(19);

    for(let itr = 0; itr < n; itr++) {
      setHighlightedIndices([{ arrayName: 'sortedList', index: itr, iteratorName: 'itr' }]);

      await highlightLine(20);
      setVariables((vars) => ({ ...vars, itr: { variable_name: 'itr', value: itr } }));
      setHighlightedVariables(['itr']);
      await customSleep(20);
      setHighlightedVariables([]);
      
      await highlightLine(21);
      await logMessage(`${sortedList[itr]}`);
      await customSleep(21);
    }
    setHighlightedIndices([]);

    await highlightLine(22);
    await customSleep(22);

    await focusEndLine(23);
    setIsRunning(false);
  };

  const runBubbleSortCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(17);
    setArrayVariables({ list: { variable_name: 'list', value: list } });
    setHighlightedArrayVariables(['list']);
    await customSleep(17);
    setHighlightedArrayVariables([]);

    await highlightLine(18);
    let n = list.length;
    setVariables((vars) => ({ ...vars, n: { variable_name: 'n', value: n } }));
    setHighlightedVariables(['n']);
    await customSleep(18);
    setHighlightedVariables([]);

    await highlightLine(19);
    await customSleep(19);

    await highlightCallingLine(19);
    let sortedList = await bubbleSortCpp(list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep);
    setArrayVariables((vars) => ({ ...vars, sortedList: { variable_name: 'sortedList', value: sortedList } }));
    setHighlightedArrayVariables(['sortedList']);
    let customLog = "Returning from the bubbleSort method and storing the returned value in the sortedList variable."
    await highlightLine(19, customLog);
    await customSleep(19);
    setHighlightedArrayVariables([]);

    await highlightLine(20);
    await logMessage(`Sorted list: ${sortedList}`);
    await customSleep(20);

    for(let itr = 0; itr < sortedList.length; itr++) {
      await highlightLine(21);
      setVariables((vars) => ({ ...vars, itr: { variable_name: 'itr', value: itr } }));
      setHighlightedVariables(['itr']);
      await customSleep(21);
      setHighlightedVariables([]);

      await highlightLine(22);
      setHighlightedIndices([{ arrayName: 'sortedList', index: itr, iteratorName: 'itr' }]);
      await logMessage(sortedList[itr]);
      await customSleep(22);
      setHighlightedIndices([]);
    }

    await highlightLine(23);
    await customSleep(23);
    
    await focusEndLine(24);
    setIsRunning(false);
  };

  const runBubbleSort = async () => {
    if (language === 'Python') {
      await runBubbleSortPython();
    } else if (language === 'Java') {
      await runBubbleSortJava();
    } else if (language === 'C') {
      await runBubbleSortC();
    } else if (language === 'C++') {
      await runBubbleSortCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedIndices,
    runBubbleSort
  };
};

import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This program demonstrates the use of the <b>set data type</b>, a collection of unique, 
        unordered items. It showcases basic operations like adding, removing, 
        and checking membership, as well as set-specific operations such as <b>union</b>, 
        <b>intersection</b>, and <b>difference</b>.
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Unique Data Storage:</b> Ensuring no duplicates, such as storing unique email addresses or user IDs.</li>
            <li><b>Tag or Keyword Systems:</b> Managing unique tags or keywords for categorization.</li>
            <li><b>Set Operations:</b> Comparing data, such as finding common interests (intersection) or differences between user preferences.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

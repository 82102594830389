import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import RotateScreenModal from './RotateScreenModal';
import UserProgressModal from './UserProgressModal';

const Editor = ({
  codeLines, highlightedLine, highlightedMultipleLines,
  focusedEndLine, topic, callingLine, secondCallingLine,
  thirdCallingLine, subTopicId, language, onOpenModal,
  isRunning, breakpoints, setBreakpoints,
}) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [copyStatus, setCopyStatus] = useState('Copy');
  const [hoveredLine, setHoveredLine] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalMessageType, setModalMessageType] = useState('Success');
  const [loading, setLoading] = useState(false);
  const [fontSize, setFontSize] = useState(0.7);
  const preRef = useRef(null);
  const editorRef = useRef(null);

  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) return;

    const url = `${apiUrl}/api/user-progress/${subTopicId}/`;
    axios.get(url, {
      headers: {
        Authorization: `Token ${token}`,
      }
    })
    .then(response => {
      setIsCompleted(response.data.is_completed);
    })
    .catch(error => {
      console.error('Error fetching completion status:', error);
    });
  }, [subTopicId, apiUrl]);

  useEffect(() => {
    const checkAccessAndNavigate = async () => {
      const token = localStorage.getItem('userToken');
      let isPrime = false;
      let isFree = true;

      try {
        // Fetch subscription details
        await axios.get(`${apiUrl}/api/subscription/`, {
          headers: { Authorization: `Token ${token}` },
        });
        isPrime = true;
      } catch (error) {
        console.error('Error checking access:', error);
      }

      try {
        // Fetch subtopic details
        const subTopicResponse = await axios.get(`${apiUrl}/api/subtopics/${subTopicId}/`);
        isFree = subTopicResponse.data.is_free;
      } catch (error) {
        navigate('/');
      }

      if (!isFree && !isPrime) {
        navigate('/');
      }
    };

    checkAccessAndNavigate();
  }, [subTopicId, apiUrl, navigate]);

  useEffect(() => {
    // Clear and re-render content in the <pre> tag
    if (preRef.current) {
      preRef.current.innerHTML = ""; // Clear previous content

      codeLines.forEach((line, index) => {
        // Create a <code> element for each line
        const codeElement = document.createElement("code");
        codeElement.className = `language-${language.toLowerCase()}`;
        codeElement.textContent = line; // Add sanitized line content

        // Wrap the line number and the code in a div
        const lineElement = document.createElement("div");
        lineElement.className = "code-line";

        const lineNumberElement = document.createElement("span");
        lineNumberElement.className = "line-number";
        lineNumberElement.textContent = index + 1;

        lineElement.appendChild(lineNumberElement);
        lineElement.appendChild(codeElement);

        preRef.current.appendChild(lineElement);

        // Apply Highlight.js to the new <code> element
        hljs.highlightElement(codeElement);
      });
    }
  }, [codeLines, language]);

  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current;
      const activeLineElement = editor.querySelector(`.code-line .highlight`);
      const extraPadding = 70;

      if (activeLineElement) {
        const editorTop = editor.scrollTop;
        const editorBottom = editorTop + editor.clientHeight;
        const lineTop = activeLineElement.offsetTop;
        const lineBottom = lineTop + activeLineElement.clientHeight;

        if (lineTop < editorTop) {
          editor.scrollTop = lineTop - extraPadding; // Scroll up if above viewport
        } else if (lineBottom > editorBottom) {
          editor.scrollTop = (lineBottom + extraPadding) - editor.clientHeight; // Scroll down if below viewport
        }
      }
    }
  }, [highlightedLine, highlightedMultipleLines]);

  const handleCopy = () => {
    const editorContent = document.getElementById('editor').innerText;
    const cleanedCode = editorContent
      .split('\n')
      .filter(element => !/^\d+$/.test(element))
      .slice(0, -1)
      .join('\n');
    
    navigator.clipboard.writeText(cleanedCode).then(() => {
      setCopyStatus('Copied!');
      setTimeout(() => setCopyStatus('Copy'), 2000);
    });
  };

  const handleZoomIn = () => {
    setFontSize(prevSize => Math.min(prevSize + 0.1, 2)); // Max 2rem
  };

  const handleZoomOut = () => {
    setFontSize(prevSize => Math.max(prevSize - 0.1, 0.5)); // Min 0.5rem
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleToggle = async () => {
    setModalMessage('');
    setLoading(true);
    setShowModal(true);
    await delay(200);

    const token = localStorage.getItem('userToken');
    if (!token) {
      setLoading(false);
      setModalMessageType('Fail');
      setModalMessage('VIP access only—log in to unlock the magic!');
      return;
    }

    const url = `${apiUrl}/api/user-progress/${subTopicId}/toggle-complete/`;
    axios.post(url, {}, {
      headers: {
        Authorization: `Token ${token}`,
      }
    })
    .then(response => {
      setModalMessage(response.data.message);
      setModalMessageType('Success');
      setLoading(false);
      setIsCompleted(prevState => !prevState);
    })
    .catch(error => {
      setLoading(false);
      setModalMessageType('Fail');
      setModalMessage('Try Again!');
    });
  };

  // Dynamic styles based on fontSize
  const dynamicStyles = {
    fontSize: `${fontSize}rem`,
    lineHeight: `${fontSize * 1.3}rem`,
    padding: `${fontSize * 1.1}rem`,
  };

  // Toggle breakpoint on click
  const toggleBreakpoint = (lineIndex) => {
    setBreakpoints((prev) =>
      prev.includes(lineIndex)
        ? prev.filter((index) => index !== lineIndex)
        : [...prev, lineIndex]
    );
  };

  return (
    <div className="editor-card shadow-lg p-3 position-relative">
      <button 
        className={`btn btn-lg toggle-btn position-absolute ${isCompleted ? 'btn-toggle-on' : 'btn-toggle-off'}`} 
        style={{right: 0}}
        onClick={handleToggle}
      >
        <div className="toggle-switch">
          <div className={`toggle-knob ${isCompleted ? 'toggle-knob-on' : 'toggle-knob-off'}`}></div>
        </div>
        <span className="toggle-label">{isCompleted ? 'Done' : 'Done?'}</span>
      </button>

      <h3 className="section-title">Code</h3>
      <div className='row'>
        <div className='col-md-6' style={{paddingTop: '15px'}}>
          <h5 style={{fontWeight: 'bold'}}>{topic}</h5>
        </div>
        <div className='col-md-6 d-flex' style={{paddingBottom: '15px', paddingTop: '5px'}}>
          <button
            className="modern-btn ms-auto"
            onClick={onOpenModal}
            disabled={isRunning}
          >
            Change Inputs
          </button>
        </div>
      </div>
      <div className='editor-scrollbar'>
        <pre
          id="editor"
          ref={editorRef}
          style={dynamicStyles}
          className="position-relative"
        >
          {codeLines.map((line, index) => (
            <div
              key={index}
              className="pre-div code-line d-flex align-items-center"
            >
              <div className="line-container d-flex align-items-center">
                <span
                  className={`breakpoint-placeholder ${hoveredLine === index || breakpoints?.includes(index) ? 'show-icon' : ''}`}
                  onClick={() => toggleBreakpoint(index)}
                  onMouseEnter={() => setHoveredLine(index)}
                  onMouseLeave={() => setHoveredLine(null)}
                >
                  {hoveredLine === index || breakpoints?.includes(index) ? (
                    <span className={`breakpoint-icon ${breakpoints?.includes(index) ? 'active' : ''}`}></span>
                  ) : null}
                </span>
                <span
                  className="line-number"
                  onClick={() => toggleBreakpoint(index)}
                  onMouseEnter={() => setHoveredLine(index)}
                  onMouseLeave={() => setHoveredLine(null)}
                >
                  {index + 1}
                </span>
              </div>
              <code
                className={`language-${language.toLowerCase() === "c++" ? "cpp" : language.toLowerCase()} code-content 
                  ${highlightedLine === index && !focusedEndLine ? 'highlight' : ''}
                  ${highlightedMultipleLines && highlightedMultipleLines.includes(index) ? 'highlight' : ''}
                  ${callingLine === index || secondCallingLine === index || thirdCallingLine === index ? 'calling-highlight' : ''}`}
                dangerouslySetInnerHTML={{
                  __html: hljs.highlight(line, { language }).value,
                }}
              ></code>
            </div>
          ))}
          <button
            onClick={handleCopy}
            id="copyBtn"
            className="btn btn-dark btn-sm copy-btn position-absolute"
          >
            <i className="fa fa-copy"></i> {copyStatus}
          </button>
          <button
            onClick={handleZoomIn}
            className="zoom-btn position-absolute"
            style={{ top: '50px', right: '10px'}}
            title="Zoom In"
          >
            <div className="circle-button plus-circle"></div>
          </button>
          <button
            onClick={handleZoomOut}
            className="zoom-btn position-absolute"
            style={{ top: '50px', right: '35px'}}
            title="Zoom Out"
          >
            <div className="circle-button minus-circle"></div>
          </button>
        </pre>
      </div>

      <RotateScreenModal />

      <UserProgressModal
        showModal={showModal}
        loading={loading}
        modalMessage={modalMessage}
        modalMessageType={modalMessageType}
        onClose={() => {
          setModalMessage('');
          setShowModal(false);
        }}
      />
    </div>
  );
};

export default Editor;

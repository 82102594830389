import { useState, useEffect, useRef } from 'react';
import defaultExplanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useUnionType = (speakingLang, translatedExplanations, language, initialUnion, number, letter, candy, breakpoints) => {
  const [explanations, setExplanations] = useState(defaultExplanations);
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [unionVariables, setUnionVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedUnionVariables, setHighlightedUnionVariables] = useState([]);
  const [stringVariables, setStringVariables] = useState({});
  const [highlightedStringVariables, setHighlightedStringVariables] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef, speakingLang);
  const [isMuted, setLocalIsMuted] = useState(false);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  useEffect(() => {
    if (translatedExplanations && language) {
      setExplanations((prevExplanations) => ({
        ...prevExplanations,
        [language]: Object.keys(prevExplanations[language] || {}).reduce((acc, key, index) => {
          acc[key] = translatedExplanations[index] || prevExplanations[language][key];
          return acc;
        }, {}),
      }));
    } else {
      setExplanations(defaultExplanations);
    }
  }, [translatedExplanations, language]);

  const updateVariables = (newNumber, newLetter, newCandy) => {
    resetState();

    // Dynamically update the code samples
    const updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
      line
        .replace(/number = \d+/, `number = ${newNumber}`)
        .replace(/letter\s*=\s*['"][A-Za-z]['"]\s*;/, `letter = '${newLetter}';`)
        .replace(/candy\s*=\s*["'][^"']*["']\s*;/, `candy = "${newCandy}";`)
    );
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setCallingLine(null);
    setFocusedEndLine(null);
    setVariables({});
    setUnionVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedUnionVariables([]);
    setStringVariables({});
    setHighlightedStringVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);    
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null, time = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms;
    if (time === null) {
        ms = delayRef.current;
    } else {
        ms = time;
    }
    
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runUnionTypeC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(9);
    let unionAttributes = [
      {
        name: 'number',
        dataType: 'int'
      },
      {
        name: 'letter',
        dataType: 'char'
      },
      {
        name: 'candy',
        dataType: 'char*'
      }
    ];

    setUnionVariables((vars) => ({
      ...vars,
      box: { objectName: 'box', unionName: 'MagicalBox', value: {attributes: unionAttributes} },
    }));
    setHighlightedUnionVariables(['box']);
    await customSleep(9);
    setHighlightedUnionVariables([]);

    await highlightLine(12);
    initialUnion = {
      number: number,
      letter: null,
      candy: null,
    };
    setVariables((vars) => ({ ...vars, number: { variable_name: 'number', value: initialUnion.number } }));
    setHighlightedVariables(['number']);
    await customSleep(12);
    setHighlightedVariables([]);

    await highlightLine(13);
    setHighlightedVariables(['number']);
    await logMessage(`The box contains the number: ${number}`);
    await customSleep(13);
    setHighlightedVariables([]);

    await highlightLine(16);
    initialUnion = {
      number: null,
      letter: letter,
      candy: null,
    };
    setHighlightedVariables(['number']);
    setVariables((vars) => ({ ...vars, number: { variable_name: 'number', value: initialUnion.number } }));
    setStringVariables((vars) => ({ ...vars, letter: { variable_name: 'letter', value: initialUnion.letter } }));
    setHighlightedStringVariables(['letter']);
    await customSleep(16);
    setHighlightedStringVariables([]);
    setHighlightedVariables([]);

    await highlightLine(17);
    setHighlightedStringVariables(['letter']);
    await logMessage(`The box now contains the letter: ${letter}`);
    await customSleep(17);
    setHighlightedStringVariables([]);


    await highlightLine(20);
    initialUnion = {
      number: null,
      letter: null,
      candy: candy,
    };
    setHighlightedVariables(['number']);
    setVariables((vars) => ({ ...vars, number: { variable_name: 'number', value: initialUnion.number } }));
    setHighlightedStringVariables(['letter']);
    setStringVariables((vars) => ({ ...vars, letter: { variable_name: 'letter', value: " " } }));
    setStringVariables((vars) => ({ ...vars, candy: { variable_name: 'candy', value: initialUnion.candy } }));
    setHighlightedStringVariables(['letter', 'candy']);
    await customSleep(20);
    setHighlightedStringVariables([]);
    setHighlightedVariables([]);
    setHighlightedStringVariables([]);

    await highlightLine(21);
    setHighlightedStringVariables(['candy']);
    await logMessage(`The box now contains: ${candy}`);
    await customSleep(21);
    setHighlightedStringVariables([]);

    await highlightLine(24);
    setHighlightedVariables(['number']);
    await logMessage(`Oops! The number is now: ${initialUnion.number}`);
    await customSleep(24);
    setHighlightedVariables([]);

    await highlightLine(25);
    setHighlightedStringVariables(['letter']);
    await logMessage(`And the letter is now: ${initialUnion.letter}`);
    await customSleep(25);
    setHighlightedStringVariables([]);

    await highlightLine(27);
    await customSleep(27);

    await focusEndLine(28);
    setIsRunning(false);
  };

  const runUnionTypeCpp = async () => {
    await runUnionTypeC(); // Since the logic is similar for C and C++
  };

  const runUnionType = async () => {
    if (language === 'C') {
      await runUnionTypeC();
    } else if (language === 'C++') {
      await runUnionTypeCpp();
    }
  };

  return {
    highlightedLine,
    callingLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    unionVariables,
    stringVariables,
    highlightedVariables,
    highlightedUnionVariables,
    highlightedStringVariables,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setCallingLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setUnionVariables,
    setStringVariables,
    setHighlightedVariables,
    setHighlightedUnionVariables,
    setHighlightedStringVariables,
    runUnionType,
  };
};

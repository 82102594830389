import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This implementation performs an iterative pre-order traversal of a binary tree using a stack:
        <ul style={{paddingLeft: '15px'}}>
            <li>
                <b>Pre-order Traversal Order:</b>
                <ul style={{paddingLeft: '15px'}}>
                    <li>
                    Visit the <b>root node</b>, then the <b>left subtree</b>, followed by the <b>right subtree</b>.
                    </li>
                </ul>
            </li>
        </ul>
        <div><b>Steps:</b></div>
        <ul style={{paddingLeft: '15px'}}>
            <li>
                <div><b>Initialization</b></div>
                <ul style={{paddingLeft: '15px'}}>
                    <li>If the tree is empty (self.root = None), terminate the traversal.</li>
                    <li>Initialize a stack with the root node.</li>
                </ul>
            </li>
            <li>
                <div><b>Traversal:</b></div>
                <ul style={{paddingLeft: '15px'}}>
                    <li>Pop a node from the stack and process it (print its data).</li>
                    <li>Push the right child (if exists) onto the stack.</li>
                    <li>Push the left child (if exists) onto the stack.</li>
                    <li>Repeat until the stack is empty.</li>
                </ul>
            </li>
            <li>
                <div><b>Output:</b></div>
                <ul style={{paddingLeft: '15px'}}>
                    <li>Print the data of each node in pre-order.</li>
                </ul>
            </li>
        </ul>
      </div>
      <div id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Path Exploration:</b> Used in graph-like structures to explore all paths, especially in DFS applications.</li>
            <li><b>Expression Trees:</b> Generate prefix expressions for computation.</li>
            <li><b>Hierarchical Structures:</b> Process tree-like structures, such as XML/JSON parsing or hierarchical file systems.</li>
            <li><b>Data Export:</b> Serialize binary trees for storage or communication.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

import React, { useState, useRef } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from './AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';
import LanguageDropdown from '../LanguageDropdown';
import defaultExplanations from './explanations';

import { useStringConcatenation } from './useStringConcatenation';

const StringConcatenation = ({ language, subTopicId }) => {
    const [speakingLang, setSpeakingLang] = useState("en-US");
    const [translatedExplanations, setTranslatedExplanations] = useState(null);
    const [isTranslating, setIsTranslating] = useState(false);

    const [modalVisible, setModalVisible] = useState(false);
    const [str1, setStr1] = useState("Code");
    const [str2, setStr2] = useState("Sleep");

    const [inputStr1, setInputStr1] = useState(str1);
    const [inputStr2, setInputStr2] = useState(str2);

    const [breakpoints, setBreakpoints] = useState([]);

    const editorCardRef = useRef(null);

    const {
        highlightedLine,
        focusedEndLine,
        isRunning,
        isPaused,
        delay,
        logs,
        variables,
        stringVariables,
        highlightedVariables,
        highlightedStringVariables,
        highlightedStringIndices,
        isMuted,
        codeSamples,
        setDelay,
        setIsPaused,
        setIsMuted,
        updateVariables,
        runStringConcatenation,
    } = useStringConcatenation(speakingLang, translatedExplanations, language, str1, str2, breakpoints);


    const handleUpdateVariables = () => {
        const sanitizedStr1 = (inputStr1 || str1).slice(0, 15);
        const sanitizedStr2 = (inputStr2 || str2).slice(0, 15);
        
        setStr1(sanitizedStr1);
        setStr2(sanitizedStr2);

        updateVariables(sanitizedStr1, sanitizedStr2);
        setModalVisible(false);
    };

    return (
        <div className="container-fluid main-container">
            <div className="content-area d-flex">
                <div className="col-lg-6" ref={editorCardRef}>
                <CodeDisplay
                    codeLines={codeSamples || []}
                    highlightedLine={highlightedLine}
                    focusedEndLine={focusedEndLine}
                    topic={"String Concatenation"}
                    subTopicId={subTopicId}
                    language={language}
                    isRunning={isRunning}
                    breakpoints={breakpoints}
                    setBreakpoints={setBreakpoints}
                    onOpenModal={() => {
                        setInputStr1(str1);
                        setInputStr2(str2);
                        setModalVisible(true);
                    }}
                />
                </div>
                <div className="col-lg-6">
                <div className="visualize-card shadow-lg p-3">
                    <div className="d-flex justify-content-center position-relative">
                    <h3 className="section-title text-center flex-grow-1">Canvas</h3>
                    <LanguageDropdown
                        onLanguageChange={setSpeakingLang}
                        onTranslate={setTranslatedExplanations}
                        selectedProgrammingLang={language}
                        defaultExplanations={defaultExplanations}
                        isRunning={isRunning} 
                        setIsTranslating={setIsTranslating} 
                    />
                    </div>

                    <Controls
                        isRunning={isRunning}
                        isPaused={isPaused}
                        delay={delay}
                        setDelay={setDelay}
                        runVisualization={runStringConcatenation}
                        setIsPaused={setIsPaused}
                        isMuted={isMuted}
                        setIsMuted={setIsMuted}
                        isTranslating={isTranslating}
                        editorCardRef={editorCardRef}
                    />
                    <VisualizationCanvas
                        variables={variables}
                        stringVariables={stringVariables}
                        highlightedVariables={highlightedVariables}
                        highlightedStringVariables={highlightedStringVariables}
                        highlightedStringIndices={highlightedStringIndices}
                    />
                    <LoggerDrawer logs={logs} />
                </div>
                </div>
            </div>
            <div className="col-lg-12 mt-4">
                <AlgorithmDescription />
            </div>
            <UpdateVariablesModal
                isVisible={modalVisible}
                str1={inputStr1}
                str2={inputStr2}
                setStr1={setInputStr1}
                setStr2={setInputStr2}
                onSave={handleUpdateVariables}
                onClose={() => setModalVisible(false)}
            />
        </div>
    );
};

export default StringConcatenation;

import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This program demonstrates <b>value interchange (swapping)</b> by exchanging the values 
        of two variables, a and b, using a temporary variable.
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Sorting Algorithms:</b> Swapping elements is a key operation in algorithms like Bubble Sort and Selection Sort.</li>
            <li><b>Game Development:</b> Swapping positions of game objects or characters in a grid or array.</li>
            <li><b>Data Manipulation:</b> Exchanging values in arrays or lists during data processing tasks.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

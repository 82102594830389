// Helper function to generate memory addresses (for visualization)
function generateMemoryAddress() {
    return '0x' + Math.floor(Math.random() * 0xFFF).toString(16);
}

class BinaryTreeNode {
    constructor(data, address) {
      this.data = data;
      this.left = null;
      this.right = null;
      this.address = address;
    }
}
  
class BinaryTreeForPython {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    ) => {
        await highlightLine(12);
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        setHighlightedVariables(['data']);
        await customSleep(12);
        setHighlightedVariables([]);

        await highlightLine(13);
        await customSleep(13);

        await highlightSecondCallingLine(13);
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);
        await highlightLine(3);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        setHighlightedSingleNodeVariables([`${newNode.address}`]);
        await customSleep(3);
        setHighlightedSingleNodeVariables([]);

        await highlightLine(4);
        setHighlightedVariables(['data']);
        setHighlightSingleNodeDataPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(4);
        setHighlightedVariables([]);
        setHighlightSingleNodeDataPart([]);

        await highlightLine(5);
        setHighlightSingleNodeLeftPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(5);
        setHighlightSingleNodeLeftPart([]);

        await highlightLine(6);
        setHighlightSingleNodeRightPart([`${newNode.address}`]);
        await customSleep(6, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: 'None'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'None', right: 'None'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        setHighlightedNodeVariables([`${newNode.address}`]);
        await highlightLine(13, "Returning from the Node constructor method.");
        await customSleep(13);
        setHighlightSingleNodeRightPart([]);
        setHighlightedNodeVariables([]);
    
        await highlightLine(14);
        setHighlightedRootVariables(['root']);
        await customSleep(14);
        setHighlightedRootVariables([]);
        if (this.root === null) {
            await highlightLine(15);
            setHighlightedRootVariables(['root']);
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'None' }
            }));
            setHighlightNodeAddress([`${newNode.address}`]);

            await customSleep(null, 1200);
            // Delete single node variable
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));
            await customSleep(15);
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            await highlightLine(16);
            await customSleep(16);

            await highlightLine(17);
            setHighlightedRootVariables(['root']);
            let queue = [this.root];
            let _queue = [this.root.address];
            setStackVariables({ queue: { variable_name: 'queue', value: [" "] } });
            setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
            await customSleep(null, 1200);
            setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
            await customSleep(17, 1200);
            setHighlightedStackIndex([]);
            setHighlightedRootVariables([]);

            while (queue.length > 0) {
                await highlightLine(18);
                setHighlightedStackVariables(['queue']);
                await customSleep(18);
                setHighlightedStackVariables([]);

                await highlightLine(19);
                setHighlightedRootVariables(['current']);
                setHighlightedStackIndex([{ stackName: 'queue', index: 0 }]);
                setRootVariables((vars) => ({ ...vars, 
                    current: { variable_name: 'current', value: ''}
                }));
                await customSleep();
                setHighlightedStackIndex([]);
                let current = queue.shift();
                setRootVariables((vars) => ({ ...vars, 
                    current: { variable_name: 'current', value: current ? current.address : 'None'}
                }));
                _queue.shift();
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                setHighlightNodeAddress([`${current.address}`]);
                await customSleep(19);
                setHighlightedRootVariables([]);
                setHighlightNodeAddress([]);

                await highlightLine(20);
                setHighlightLeftPart([`${current.address}`]);
                await customSleep(20);
                setHighlightLeftPart([]);
                if (current.left === null) {
                    setSingleNodeVariable(() => ({}));

                    await highlightLine(21);
                    setHighlightedRootVariables(['current']);
                    setHighlightLeftPart([`${current.address}`]);
                    current.left = newNode;
                    setHighlightNodeAddress([`${newNode.address}`]);
                    setNodeVariables((vars) => ({ ...vars, 
                        [current.address]: { 
                            variable_name: current.address,
                            value: {data: current.data, left: newNode.address, right: current.right ? current.right.address : 'None'},
                            address: current.address,
                            parent: current.parent
                        }
                    }));
                    await customSleep(21);
                    setHighlightLeftPart();
                    setHighlightNodeAddress([]);
                    setHighlightedRootVariables([]);

                    await highlightLine(22);
                    await customSleep(22);

                    // Emptying stack vaiables
                    setStackVariables({});

                    // Delete current from headVariables
                    setRootVariables(() => ({
                        root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                    }));

                    // Emptying vaiables
                    setVariables(() => ({}));

                    return;
                } else {
                    await highlightLine(23);
                    await customSleep(23);

                    await highlightLine(24);
                    setHighlightedRootVariables(['current']);
                    setHighlightLeftPart([`${current.address}`]);
                    _queue.push("");
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                    await customSleep(null, 1200);
                    queue.push(current.left);
                    _queue[_queue.length - 1] = current.left ? current.left.address : 'None';
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    await customSleep(24);
                    setHighlightedRootVariables([]);
                    setHighlightedStackIndex([]);
                    setHighlightLeftPart([]);
                }

                await highlightLine(25);
                setHighlightRightPart([`${current.address}`]);
                await customSleep(25);
                setHighlightRightPart([]);
                if (current.right === null) {
                    setSingleNodeVariable(() => ({}));

                    await highlightLine(26);
                    setHighlightedRootVariables(['current']);
                    setHighlightRightPart([`${current.address}`]);
                    current.right = newNode;
                    setHighlightNodeAddress([`${newNode.address}`]);
                    setNodeVariables((vars) => ({ ...vars, 
                        [current.address]: { 
                            variable_name: current.address,
                            value: {data: current.data, left: current.left ? current.left.address : 'None', right: newNode.address},
                            address: current.address,
                            parent: current.parent
                        }
                    }));
                    await customSleep(26);
                    setHighlightRightPart([]);
                    setHighlightNodeAddress([]);
                    setHighlightedRootVariables([]);

                    await highlightLine(27);
                    await customSleep(27);

                    // Emptying stack vaiables
                    setStackVariables({});

                    // Delete current from headVariables
                    setRootVariables(() => ({
                        root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                    }));

                    // Emptying vaiables
                    setVariables(() => ({}));

                    return;
                } else {
                    await highlightLine(28);
                    await customSleep(28);

                    await highlightLine(29);
                    setHighlightedRootVariables(['current']);
                    setHighlightRightPart([`${current.address}`]);
                    _queue.push("");
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                    await customSleep(null, 1200);
                    queue.push(current.right);
                    _queue[_queue.length - 1] = current.right ? current.right.address : 'None';
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    await customSleep(29);
                    setHighlightedRootVariables([]);
                    setHighlightedStackIndex([]);
                    setHighlightRightPart([]);
                }

                if (!(queue.length > 0)) {
                    await highlightLine(28);
                    setHighlightedStackVariables(['queue']);
                    await customSleep(28);
                    setHighlightedStackVariables([]);
                }
            }
        }
    };

}

export const binaryTreePython = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables,
    setHighlightedVariables,
    setHighlightedRootVariables,
    setHighlightedNodeVariables,
    setHighlightedSingleNodeVariables,
    setHighlightSingleNodeLeftPart,
    setHighlightSingleNodeDataPart,
    setHighlightSingleNodeRightPart,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    customSleep
  ) => {
    let binaryTree = new BinaryTreeForPython();
  
    await highlightLine(33);
    await customSleep(33);

    await highlightCallingLine(33);
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(33, "Returning from the add method.");
    await customSleep(33);
    
    await highlightLine(34);
    await customSleep(34);

    await highlightCallingLine(34);
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(34, "Returning from the add method.");
    await customSleep(34);
    
    await highlightLine(35);
    await customSleep(35);

    await highlightCallingLine(35);
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(35, "Returning from the add method.");
    await customSleep(35);

    await highlightLine(36);
    await customSleep(36);

    await highlightCallingLine(36);
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(36, "Returning from the add method.");
    await customSleep(36);

    await highlightLine(37);
    await customSleep(37);

    await highlightCallingLine(37);
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(37, "Returning from the add method.");
    await customSleep(37);

    await highlightLine(38);
    await customSleep(38);

    await highlightCallingLine(38);
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(38, "Returning from the add method.");
    await customSleep(38);

    await highlightLine(39);
    await customSleep(39);

    await highlightCallingLine(39);
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(39, "Returning from the add method.");
    await customSleep(39);
};


class BinaryTreeForJava {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    ) => {
        await highlightLine(20);
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        setHighlightedVariables(['data']);
        await customSleep(20);
        setHighlightedVariables([]);

        await highlightLine(21);
        await customSleep(21);

        await highlightSecondCallingLine(21);
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);
        await highlightLine(7);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        setHighlightedSingleNodeVariables([`${newNode.address}`]);
        await customSleep(7);
        setHighlightedSingleNodeVariables([]);

        await highlightLine(8);
        setHighlightedVariables(['data']);
        setHighlightSingleNodeDataPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(8);
        setHighlightedVariables([]);
        setHighlightSingleNodeDataPart([]);

        await highlightLine(9);
        setHighlightSingleNodeLeftPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(null, 1200);
        setHighlightSingleNodeLeftPart([]);
        setHighlightSingleNodeRightPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: 'null'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'null', right: 'null'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        setHighlightedNodeVariables([`${newNode.address}`]);
        await customSleep(9, 1200);
        setHighlightSingleNodeRightPart([]);
        await highlightLine(21, "Returning from the Node constructor method.");
        await customSleep(21);
        setHighlightedNodeVariables([]);
    
        await highlightLine(22);
        setHighlightedRootVariables(['root']);
        await customSleep(22);
        setHighlightedRootVariables([]);
        if (this.root === null) {
            await highlightLine(23);
            setHighlightedRootVariables(['root']);
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'null' }
            }));
            setHighlightNodeAddress([`${newNode.address}`]);

            await customSleep(null, 1200);
            // Delete single node variable
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));
            await customSleep(23);
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            await highlightLine(24);
            await customSleep(24);

            await highlightLine(25);
            setHighlightedRootVariables(['root']);
            let queue = [];
            let _queue = [];
            setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
            setHighlightedStackVariables(['queue']);
            await customSleep(25);
            setHighlightedStackVariables([]);

            await highlightLine(26);
            setHighlightedRootVariables(['root']);
            queue = [this.root];
            _queue = [this.root.address];
            setStackVariables({ queue: { variable_name: 'queue', value: [" "] } });
            setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
            await customSleep(null, 1200);
            setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
            await customSleep(26, 1200);
            setHighlightedStackIndex([]);
            setHighlightedRootVariables([]);

            while (queue.length > 0) {
                await highlightLine(28);
                setHighlightedStackVariables(['queue']);
                await customSleep(28);
                setHighlightedStackVariables([]);

                await highlightLine(29);
                setHighlightedRootVariables(['current']);
                setHighlightedStackIndex([{ stackName: 'queue', index: 0 }]);
                setRootVariables((vars) => ({ ...vars, 
                    current: { variable_name: 'current', value: ''}
                }));
                await customSleep();
                setHighlightedStackIndex([]);
                let current = queue.shift();
                setRootVariables((vars) => ({ ...vars, 
                    current: { variable_name: 'current', value: current ? current.address : 'null'}
                }));
                _queue.shift();
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                setHighlightNodeAddress([`${current.address}`]);
                await customSleep(29);
                setHighlightedRootVariables([]);
                setHighlightNodeAddress([]);

                await highlightLine(31);
                setHighlightLeftPart([`${current.address}`]);
                await customSleep(31);
                setHighlightLeftPart([]);
                if (current.left === null) {
                    setSingleNodeVariable(() => ({}));

                    await highlightLine(32);
                    setHighlightedRootVariables(['current']);
                    setHighlightLeftPart([`${current.address}`]);
                    current.left = newNode;
                    setHighlightNodeAddress([`${newNode.address}`]);
                    setNodeVariables((vars) => ({ ...vars, 
                        [current.address]: { 
                            variable_name: current.address,
                            value: {data: current.data, left: newNode.address, right: current.right ? current.right.address : 'null'},
                            address: current.address,
                            parent: current.parent
                        }
                    }));
                    await customSleep(32);
                    setHighlightLeftPart();
                    setHighlightNodeAddress([]);
                    setHighlightedRootVariables([]);

                    await highlightLine(33);
                    await customSleep(33);

                    // Emptying stack vaiables
                    setStackVariables({});

                    // Delete current from headVariables
                    setRootVariables(() => ({
                        root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                    }));

                    // Emptying vaiables
                    setVariables(() => ({}));

                    return;
                } else {
                    await highlightLine(34);
                    await customSleep(34);

                    await highlightLine(35);
                    setHighlightedRootVariables(['current']);
                    setHighlightLeftPart([`${current.address}`]);
                    _queue.push("");
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                    await customSleep(null, 1200);
                    queue.push(current.left);
                    _queue[_queue.length - 1] = current.left ? current.left.address : 'null';
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    await customSleep(35);
                    setHighlightedRootVariables([]);
                    setHighlightedStackIndex([]);
                    setHighlightLeftPart([]);
                }

                await highlightLine(38);
                setHighlightRightPart([`${current.address}`]);
                await customSleep(38);
                setHighlightRightPart([]);
                if (current.right === null) {
                    setSingleNodeVariable(() => ({}));

                    await highlightLine(39);
                    setHighlightedRootVariables(['current']);
                    setHighlightRightPart([`${current.address}`]);
                    current.right = newNode;
                    setHighlightNodeAddress([`${newNode.address}`]);
                    setNodeVariables((vars) => ({ ...vars, 
                        [current.address]: { 
                            variable_name: current.address,
                            value: {data: current.data, left: current.left ? current.left.address : 'null', right: newNode.address},
                            address: current.address,
                            parent: current.parent
                        }
                    }));
                    await customSleep(39);
                    setHighlightRightPart([]);
                    setHighlightNodeAddress([]);
                    setHighlightedRootVariables([]);

                    await highlightLine(40);
                    await customSleep(40);

                    setStackVariables({});

                    // Emptying stack vaiables
                    setStackVariables({});

                    // Delete current from headVariables
                    setRootVariables(() => ({
                        root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                    }));

                    // Emptying vaiables
                    setVariables(() => ({}));

                    return;
                } else {
                    await highlightLine(41);
                    await customSleep(41);

                    await highlightLine(42);
                    setHighlightedRootVariables(['current']);
                    setHighlightRightPart([`${current.address}`]);
                    _queue.push("");
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                    await customSleep(null, 1200);
                    queue.push(current.right);
                    _queue[_queue.length - 1] = current.right ? current.right.address : 'null';
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    await customSleep(42);
                    setHighlightedRootVariables([]);
                    setHighlightedStackIndex([]);
                    setHighlightRightPart([]);
                }

                if (!(queue.length > 0)) {
                    await highlightLine(28);
                    setHighlightedStackVariables(['queue']);
                    await customSleep(28);
                    setHighlightedStackVariables([]);
                }
            }
        }
    };

}

export const binaryTreeJava = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables,
    setHighlightedVariables,
    setHighlightedRootVariables,
    setHighlightedNodeVariables,
    setHighlightedSingleNodeVariables,
    setHighlightSingleNodeLeftPart,
    setHighlightSingleNodeDataPart,
    setHighlightSingleNodeRightPart,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    customSleep
  ) => {
    let binaryTree = new BinaryTreeForJava();
  
    await highlightLine(52);
    await customSleep(52);

    await highlightCallingLine(52);
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(52, "Returning from the add method.");
    await customSleep(52);
    
    await highlightLine(53);
    await customSleep(53);

    await highlightCallingLine(53);
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(53, "Returning from the add method.");
    await customSleep(53);
    
    await highlightLine(54);
    await customSleep(54);

    await highlightCallingLine(54);
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(54, "Returning from the add method.");
    await customSleep(54);

    await highlightLine(55);
    await customSleep(55);

    await highlightCallingLine(55);
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(55, "Returning from the add method.");
    await customSleep(55);

    await highlightLine(56);
    await customSleep(56);

    await highlightCallingLine(56);
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(56, "Returning from the add method.");
    await customSleep(56);

    await highlightLine(57);
    await customSleep(57);

    await highlightCallingLine(57);
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(57, "Returning from the add method.");
    await customSleep(57);

    await highlightLine(58);
    await customSleep(58);

    await highlightCallingLine(58);
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(58, "Returning from the add method.");
    await customSleep(58);
};


class BinaryTreeForCpp {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    ) => {
        await highlightLine(21);
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        setHighlightedVariables(['data']);
        await customSleep(21);
        setHighlightedVariables([]);

        await highlightLine(22);
        await customSleep(22);

        await highlightSecondCallingLine(22);
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);
        await highlightLine(8);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        setHighlightedSingleNodeVariables([`${newNode.address}`]);
        await customSleep(8);
        setHighlightedSingleNodeVariables([]);

        await highlightLine(9);
        setHighlightedVariables(['data']);
        setHighlightSingleNodeDataPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(9);
        setHighlightedVariables([]);
        setHighlightSingleNodeDataPart([]);

        await highlightLine(10);
        setHighlightSingleNodeLeftPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(null, 1200);
        setHighlightSingleNodeLeftPart([]);
        setHighlightSingleNodeRightPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'nullptr', right: 'nullptr'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        setHighlightedNodeVariables([`${newNode.address}`]);
        await customSleep(10, 1200);
        setHighlightSingleNodeRightPart([]);
        await highlightLine(22, "Returning from the Node constructor method.");
        await customSleep(22);
        setHighlightedNodeVariables([]);
    
        await highlightLine(23);
        setHighlightedRootVariables(['root']);
        await customSleep(23);
        setHighlightedRootVariables([]);
        if (this.root === null) {
            await highlightLine(24);
            setHighlightedRootVariables(['root']);
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'nullptr' }
            }));
            setHighlightNodeAddress([`${newNode.address}`]);

            await customSleep(null, 1200);
            // Delete single node variable
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));
            await customSleep(24);
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            await highlightLine(25);
            await customSleep(25);

            await highlightLine(26);
            setHighlightedRootVariables(['root']);
            let queue = [];
            let _queue = [];
            setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
            setHighlightedStackVariables(['queue']);
            await customSleep(26);
            setHighlightedStackVariables([]);

            await highlightLine(27);
            setHighlightedRootVariables(['root']);
            queue = [this.root];
            _queue = [this.root.address];
            setStackVariables({ queue: { variable_name: 'queue', value: [" "] } });
            setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
            await customSleep(null, 1200);
            setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
            await customSleep(27, 1200);
            setHighlightedStackIndex([]);
            setHighlightedRootVariables([]);

            while (queue.length > 0) {
                await highlightLine(29);
                setHighlightedStackVariables(['queue']);
                await customSleep(29);
                setHighlightedStackVariables([]);

                await highlightLine(30);
                setHighlightedRootVariables(['current']);
                setHighlightedStackIndex([{ stackName: 'queue', index: 0 }]);
                setRootVariables((vars) => ({ ...vars, 
                    current: { variable_name: 'current', value: ''}
                }));
                await customSleep();
                setHighlightedStackIndex([]);
                let current = queue.shift();
                setRootVariables((vars) => ({ ...vars, 
                    current: { variable_name: 'current', value: current ? current.address : 'nullptr'}
                }));
                setHighlightNodeAddress([`${current.address}`]);
                await customSleep(30);
                setHighlightedRootVariables([]);
                setHighlightNodeAddress([]);

                await highlightLine(31);
                setHighlightedStackIndex([{ stackName: 'queue', index: 0 }]);
                await customSleep(null, 1200);
                _queue.shift();
                setHighlightedStackIndex([]);
                setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                await customSleep(31);

                await highlightLine(33);
                setHighlightLeftPart([`${current.address}`]);
                await customSleep(33);
                setHighlightLeftPart([]);
                if (current.left === null) {
                    setSingleNodeVariable(() => ({}));

                    await highlightLine(34);
                    setHighlightedRootVariables(['current']);
                    setHighlightLeftPart([`${current.address}`]);
                    current.left = newNode;
                    setHighlightNodeAddress([`${newNode.address}`]);
                    setNodeVariables((vars) => ({ ...vars, 
                        [current.address]: { 
                            variable_name: current.address,
                            value: {data: current.data, left: newNode.address, right: current.right ? current.right.address : 'nullptr'},
                            address: current.address,
                            parent: current.parent
                        }
                    }));
                    await customSleep(34);
                    setHighlightLeftPart();
                    setHighlightNodeAddress([]);
                    setHighlightedRootVariables([]);

                    await highlightLine(35);
                    await customSleep(35);

                    // Emptying stack vaiables
                    setStackVariables({});

                    // Delete current from headVariables
                    setRootVariables(() => ({
                        root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                    }));

                    // Emptying vaiables
                    setVariables(() => ({}));

                    return;
                } else {
                    await highlightLine(36);
                    await customSleep(36);

                    await highlightLine(37);
                    setHighlightedRootVariables(['current']);
                    setHighlightLeftPart([`${current.address}`]);
                    _queue.push("");
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                    await customSleep(null, 1200);
                    queue.push(current.left);
                    _queue[_queue.length - 1] = current.left ? current.left.address : 'nullptr';
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    await customSleep(37);
                    setHighlightedRootVariables([]);
                    setHighlightedStackIndex([]);
                    setHighlightLeftPart([]);
                }

                await highlightLine(40);
                setHighlightRightPart([`${current.address}`]);
                await customSleep(40);
                setHighlightRightPart([]);
                if (current.right === null) {
                    setSingleNodeVariable(() => ({}));

                    await highlightLine(41);
                    setHighlightedRootVariables(['current']);
                    setHighlightRightPart([`${current.address}`]);
                    current.right = newNode;
                    setHighlightNodeAddress([`${newNode.address}`]);
                    setNodeVariables((vars) => ({ ...vars, 
                        [current.address]: { 
                            variable_name: current.address,
                            value: {data: current.data, left: current.left ? current.left.address : 'nullptr', right: newNode.address},
                            address: current.address,
                            parent: current.parent
                        }
                    }));
                    await customSleep(41);
                    setHighlightRightPart([]);
                    setHighlightNodeAddress([]);
                    setHighlightedRootVariables([]);

                    await highlightLine(42);
                    await customSleep(42);

                    setStackVariables({});

                    // Emptying stack vaiables
                    setStackVariables({});

                    // Delete current from headVariables
                    setRootVariables(() => ({
                        root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                    }));

                    // Emptying vaiables
                    setVariables(() => ({}));

                    return;
                } else {
                    await highlightLine(43);
                    await customSleep(43);

                    await highlightLine(44);
                    setHighlightedRootVariables(['current']);
                    setHighlightRightPart([`${current.address}`]);
                    _queue.push("");
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                    await customSleep(null, 1200);
                    queue.push(current.right);
                    _queue[_queue.length - 1] = current.right ? current.right.address : 'nullptr';
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    await customSleep(44);
                    setHighlightedRootVariables([]);
                    setHighlightedStackIndex([]);
                    setHighlightRightPart([]);
                }

                if (!(queue.length > 0)) {
                    await highlightLine(29);
                    setHighlightedStackVariables(['queue']);
                    await customSleep(29);
                    setHighlightedStackVariables([]);
                }
            }
        }
    };

}

export const binaryTreeCpp = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables,
    setHighlightedVariables,
    setHighlightedRootVariables,
    setHighlightedNodeVariables,
    setHighlightedSingleNodeVariables,
    setHighlightSingleNodeLeftPart,
    setHighlightSingleNodeDataPart,
    setHighlightSingleNodeRightPart,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    customSleep
  ) => {
    let binaryTree = new BinaryTreeForCpp();
  
    await highlightLine(53);
    await customSleep(53);

    await highlightCallingLine(53);
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(53, "Returning from the add method.");
    await customSleep(53);
    
    await highlightLine(54);
    await customSleep(54);

    await highlightCallingLine(54);
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(54, "Returning from the add method.");
    await customSleep(54);
    
    await highlightLine(55);
    await customSleep(55);

    await highlightCallingLine(55);
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(55, "Returning from the add method.");
    await customSleep(55);

    await highlightLine(56);
    await customSleep(56);

    await highlightCallingLine(56);
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(56, "Returning from the add method.");
    await customSleep(56);

    await highlightLine(57);
    await customSleep(57);

    await highlightCallingLine(57);
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(57, "Returning from the add method.");
    await customSleep(57);

    await highlightLine(58);
    await customSleep(58);

    await highlightCallingLine(58);
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(58, "Returning from the add method.");
    await customSleep(58);

    await highlightLine(59);
    await customSleep(59);

    await highlightCallingLine(59);
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        customSleep
    );
    await highlightLine(59, "Returning from the add method.");
    await customSleep(59);
};


class BinaryTreeForC {
    constructor() {
      this.root = null;
    }
  
    // Method to add a new node to the tree
    add = async (
        data,
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        customSleep
    ) => {
        await highlightLine(17);
        setVariables((vars) => ({ ...vars, 
            data: { variable_name: 'data', value: data }
        }));
        setHighlightedVariables(['data']);
        await customSleep(17);
        setHighlightedVariables([]);

        await highlightLine(18);
        await customSleep(18);

        await highlightSecondCallingLine(18);
        await highlightLine(9);
        await customSleep(9);
        
        await highlightLine(10);
        let nodeAddress = generateMemoryAddress();
        let newNode = new BinaryTreeNode(data, nodeAddress);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: '', left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        setHighlightedSingleNodeVariables([`${newNode.address}`]);
        await customSleep(10);
        setHighlightedSingleNodeVariables([]);

        await highlightLine(11);
        setHighlightedVariables(['data']);
        setHighlightSingleNodeDataPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: '', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(11);
        setHighlightedVariables([]);
        setHighlightSingleNodeDataPart([]);

        await highlightLine(12);
        setHighlightSingleNodeLeftPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: ''},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(12);
        setHighlightSingleNodeLeftPart([]);

        await highlightLine(13);
        setHighlightSingleNodeRightPart([`${newNode.address}`]);
        await customSleep(null, 1200);

        setSingleNodeVariable((vars) => ({ ...vars, 
            [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
            }
        }));
        await customSleep(13, 1200);
        setHighlightSingleNodeRightPart([]);

        await highlightLine(14);
        setHighlightedSingleNodeVariables([`${newNode.address}`]);
        await customSleep();

        setNodeVariables((vars) => ({ ...vars, 
        [newNode.address]: { 
            variable_name: newNode.address,
            value: {data: newNode.data, left: 'NULL', right: 'NULL'},
            address: newNode.address,
            parent: newNode.parent
        }
        }));
        setHighlightedNodeVariables([`${newNode.address}`]);
        await customSleep(14, 1200);
        await highlightLine(18, "Returning from the createNode function and storing the returned node in the newNode variable.");
        await customSleep(18);
        setHighlightedNodeVariables([]);
        setHighlightedSingleNodeVariables([]);
    
        await highlightLine(19);
        setHighlightedRootVariables(['root']);
        await customSleep(19);
        setHighlightedRootVariables([]);
        if (this.root === null) {
            await highlightLine(20);
            setHighlightedRootVariables(['root']);
            this.root = newNode;
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: 'NULL' }
            }));
            setHighlightNodeAddress([`${newNode.address}`]);

            await customSleep(null, 1200);
            // Delete single node variable
            setSingleNodeVariable(() => ({}));
            
            setRootVariables((vars) => ({ ...vars, 
                root: { variable_name: 'root', value: newNode.address, child: newNode.address }
            }));
            await customSleep(20);
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);

            // Emptying vaiables
            setVariables(() => ({}));
        } else {
            await highlightLine(21);
            await customSleep(21);

            await highlightLine(22);
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: ''}
            }));
            setHighlightedRootVariables(['current']);
            await customSleep();
            let current = this.root;
            setRootVariables((vars) => ({ ...vars, 
                current: { variable_name: 'current', value: current ? current.address : 'NULL'}
            }));
            setHighlightNodeAddress([`${current.address}`]);
            await customSleep(22);
            setHighlightedRootVariables([]);
            setHighlightNodeAddress([]);

            await highlightLine(23);
            let queue = [];
            let _queue = [];
            setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
            setHighlightedStackVariables(['queue']);
            await customSleep(23);
            setHighlightedStackVariables([]);

            await highlightLine(24);
            let front = 0;
            let rear = 0;
            setVariables((vars) => ({ ...vars, 
                front: { variable_name: 'front', value: front },
                rear: { variable_name: 'rear', value: rear }
            }));
            setHighlightedVariables(['front', 'rear']);
            await customSleep(24);
            setHighlightedVariables([]);

            await highlightLine(25);
            setHighlightedRootVariables(['current']);
            setHighlightedVariables(['rear']);
            setStrikeThroughStackIndex([]);
            queue[rear] = current;
            _queue[rear] = current.address;
            setStackVariables({ queue: { variable_name: 'queue', value: [" "] } });
            setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
            await customSleep(null, 1200);
            setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
            await customSleep(null, 1200);
            setHighlightedRootVariables([]);
            rear += 1;
            setVariables((vars) => ({ ...vars, 
                rear: { variable_name: 'rear', value: rear }
            }));
            await customSleep(25);
            setHighlightedVariables([]);
            setHighlightedStackIndex([]);

            while (front < rear) {
                await highlightLine(27);
                setHighlightedVariables(['front', 'rear']);
                await customSleep(27);
                setHighlightedVariables([]);

                await highlightLine(28);
                current = queue[front];
                setHighlightedVariables(['front']);
                setHighlightedStackIndex([{ stackName: 'queue', index: front }]);
                setRootVariables((vars) => ({ ...vars, 
                    current: { variable_name: 'current', value: ''}
                }));
                await customSleep(null, 1200);
                setRootVariables((vars) => ({ ...vars, 
                    current: { variable_name: 'current', value: current ? current.address : 'NULL'}
                }));
                setHighlightedStackIndex([]);
                let strikeIndex = front;
                setStrikeThroughStackIndex((prevState) => [
                    ...prevState, 
                    { stackName: 'queue', index: strikeIndex }
                ]);
                await customSleep(null, 1200);
                front += 1;
                setVariables((vars) => ({ ...vars, 
                    front: { variable_name: 'front', value: front }
                }));
                await customSleep(28);
                setHighlightedVariables([]);

                await highlightLine(30);
                setHighlightedRootVariables(['current']);
                setHighlightLeftPart([`${current.address}`]);
                await customSleep(30);
                setHighlightedRootVariables([]);
                setHighlightLeftPart([]);
                if (current.left === null) {
                    setSingleNodeVariable(() => ({}));

                    await highlightLine(31);
                    setHighlightedRootVariables(['current']);
                    setHighlightLeftPart([`${current.address}`]);
                    current.left = newNode;
                    setHighlightNodeAddress([`${newNode.address}`]);
                    setNodeVariables((vars) => ({ ...vars, 
                        [current.address]: { 
                            variable_name: current.address,
                            value: {data: current.data, left: newNode.address, right: current.right ? current.right.address : 'nullptr'},
                            address: current.address,
                            parent: current.parent
                        }
                    }));
                    await customSleep(31);
                    setHighlightLeftPart();
                    setHighlightNodeAddress([]);
                    setHighlightedRootVariables([]);

                    await highlightLine(32);
                    await customSleep(32);

                    // Emptying stack vaiables
                    setStackVariables({});

                    // Delete current from headVariables
                    setRootVariables(() => ({
                        root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                    }));

                    // Emptying vaiables
                    setVariables(() => ({}));

                    return;
                } else {
                    await highlightLine(33);
                    await customSleep(33);

                    await highlightLine(34);
                    setHighlightedRootVariables(['current']);
                    setHighlightedVariables(['rear']);
                    setHighlightLeftPart([`${current.address}`]);
                    _queue[rear] = " ";
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                    await customSleep(null, 1200);
                    queue[rear] = current.left;
                    _queue[rear] = current.left ? current.left.address : 'NULL';
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    await customSleep(null, 1200);
                    setHighlightedRootVariables([]);
                    rear += 1;
                    setVariables((vars) => ({ ...vars, 
                        rear: { variable_name: 'rear', value: rear }
                    }));
                    await customSleep(34);
                    setHighlightedVariables([]);
                    setHighlightLeftPart([]);
                    setHighlightedStackIndex([]);
                }

                await highlightLine(37);
                setHighlightedRootVariables(['current']);
                setHighlightRightPart([`${current.address}`]);
                await customSleep(37);
                setHighlightedRootVariables([]);
                setHighlightRightPart([]);
                if (current.right === null) {
                    setSingleNodeVariable(() => ({}));

                    await highlightLine(38);
                    setHighlightedRootVariables(['current']);
                    setHighlightRightPart([`${current.address}`]);
                    current.right = newNode;
                    setHighlightNodeAddress([`${newNode.address}`]);
                    setNodeVariables((vars) => ({ ...vars, 
                        [current.address]: { 
                            variable_name: current.address,
                            value: {data: current.data, left: current.left ? current.left.address : 'nullptr', right: newNode.address},
                            address: current.address,
                            parent: current.parent
                        }
                    }));
                    await customSleep(38);
                    setHighlightRightPart([]);
                    setHighlightNodeAddress([]);
                    setHighlightedRootVariables([]);

                    await highlightLine(39);
                    await customSleep(39);

                    setStackVariables({});

                    // Emptying stack vaiables
                    setStackVariables({});

                    // Delete current from headVariables
                    setRootVariables(() => ({
                        root: { variable_name: 'root', value: this.root.address, child: this.root.address }
                    }));

                    // Emptying vaiables
                    setVariables(() => ({}));

                    return;
                } else {
                    await highlightLine(40);
                    await customSleep(40);

                    await highlightLine(41);
                    setHighlightedRootVariables(['current']);
                    setHighlightedVariables(['rear']);
                    setHighlightRightPart([`${current.address}`]);
                    _queue[rear] = " ";
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    setHighlightedStackIndex([{ stackName: 'queue', index: _queue.length - 1 }]);
                    await customSleep(null, 1200);
                    queue[rear] = current.right;
                    _queue[rear] = current.right ? current.right.address : 'NULL';
                    setStackVariables({ queue: { variable_name: 'queue', value: _queue } });
                    await customSleep(null, 1200);
                    setHighlightedRootVariables([]);
                    rear += 1;
                    setVariables((vars) => ({ ...vars, 
                        rear: { variable_name: 'rear', value: rear }
                    }));
                    await customSleep(41);
                    setHighlightedVariables([]);
                    setHighlightRightPart([]);
                    setHighlightedStackIndex([]);
                }

                if (!(front < rear)) {
                    await highlightLine(27);
                    setHighlightedVariables(['front', 'rear']);
                    await customSleep(27);
                    setHighlightedVariables([]);
                }
            }
        }
    };

}

export const binaryTreeC = async (
    data,
    setNodeVariables,
    setSingleNodeVariable,
    setVariables,
    setRootVariables,
    setHighlightedVariables,
    setHighlightedRootVariables,
    setHighlightedNodeVariables,
    setHighlightedSingleNodeVariables,
    setHighlightSingleNodeLeftPart,
    setHighlightSingleNodeDataPart,
    setHighlightSingleNodeRightPart,
    setHighlightLeftPart,
    setHighlightDataPart,
    setHighlightRightPart,
    setHighlightNodeAddress,
    highlightLine,
    highlightCallingLine,
    highlightSecondCallingLine,
    setStackVariables,
    setHighlightedStackVariables,
    setHighlightedStackIndex,
    setStrikeThroughStackIndex,
    customSleep
  ) => {
    let binaryTree = new BinaryTreeForC();
  
    await highlightLine(49);
    await customSleep(49);

    await highlightCallingLine(49);
    await binaryTree.add(
        data[0],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        customSleep
    );
    await highlightLine(49, "Returning from the addNode function.");
    await customSleep(49);
    
    await highlightLine(50);
    await customSleep(50);

    await highlightCallingLine(50);
    await binaryTree.add(
        data[1],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        customSleep
    );
    await highlightLine(50, "Returning from the addNode function.");
    await customSleep(50);
    
    await highlightLine(51);
    await customSleep(51);

    await highlightCallingLine(51);
    await binaryTree.add(
        data[2],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        customSleep
    );
    await highlightLine(51, "Returning from the addNode function.");
    await customSleep(51);

    await highlightLine(52);
    await customSleep(52);

    await highlightCallingLine(52);
    await binaryTree.add(
        data[3],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        customSleep
    );
    await highlightLine(52, "Returning from the addNode function.");
    await customSleep(52);

    await highlightLine(53);
    await customSleep(53);

    await highlightCallingLine(53);
    await binaryTree.add(
        data[4],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        customSleep
    );
    await highlightLine(53, "Returning from the addNode function.");
    await customSleep(53);

    await highlightLine(54);
    await customSleep(54);

    await highlightCallingLine(54);
    await binaryTree.add(
        data[5],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        customSleep
    );
    await highlightLine(54, "Returning from the addNode function.");
    await customSleep(54);

    await highlightLine(55);
    await customSleep(55);

    await highlightCallingLine(55);
    await binaryTree.add(
        data[6],
        setNodeVariables,
        setSingleNodeVariable,
        setVariables,
        setRootVariables,
        setHighlightedVariables,
        setHighlightedRootVariables,
        setHighlightedNodeVariables,
        setHighlightedSingleNodeVariables,
        setHighlightSingleNodeLeftPart,
        setHighlightSingleNodeDataPart,
        setHighlightSingleNodeRightPart,
        setHighlightLeftPart,
        setHighlightDataPart,
        setHighlightRightPart,
        setHighlightNodeAddress,
        highlightLine,
        highlightSecondCallingLine,
        setStackVariables,
        setHighlightedStackVariables,
        setHighlightedStackIndex,
        setStrikeThroughStackIndex,
        customSleep
    );
    await highlightLine(55, "Returning from the addNode function.");
    await customSleep(55);
};

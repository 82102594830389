import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
        This implementation performs an iterative post-order traversal of a binary tree using two stacks:
        <ul style={{paddingLeft: '15px'}}>
            <li>
                <b>Post-order Traversal Order:</b>
                <ul style={{paddingLeft: '15px'}}>
                    <li>
                        Visit the <b>left subtree</b>, then the <b>right subtree</b>, and finally the <b>root</b> node.
                    </li>
                </ul>
            </li>
        </ul>
        <div><b>Steps:</b></div>
        <ul style={{paddingLeft: '15px'}}>
            <li>
                <div><b>Initialization</b></div>
                <ul style={{paddingLeft: '15px'}}>
                    <li>If the tree is empty (self.root is None), terminate the traversal.</li>
                    <li>Initialize stack1 with the root node and an empty stack2.</li>
                </ul>
            </li>
            <li>
                <div><b>Reverse Post-order:</b></div>
                <ul style={{paddingLeft: '15px'}}>
                    <li>Pop nodes from stack1, push them onto stack2, and push their left and right children onto stack1.</li>
                    <li>This step reverses the natural post-order traversal (root-right-left).</li>
                </ul>
            </li>
            <li>
                <div><b>Post-order:</b></div>
                <ul style={{paddingLeft: '15px'}}>
                    <li>Pop nodes from stack2 and process them, restoring the post-order traversal (left-right-root).</li>
                </ul>
            </li>
            <li>
                <div><b>Output:</b></div>
                <ul style={{paddingLeft: '15px'}}>
                    <li>Print the data of each node in post-order.</li>
                </ul>
            </li>
        </ul>
      </div>
      <div id="timeComplexity">
        <div>
          <b>Time Complxity:</b>&nbsp;&nbsp;
          <span style={{fontSize: '20px'}}>O</span>
          <span style={{fontSize: '18px'}}>(</span>
          <span style={{fontSize: '15px'}}>n</span>
          <span style={{fontSize: '18px'}}>)</span>
        </div>
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Tree Deletion:</b> Post-order traversal is useful for safely deleting nodes in a tree structure.</li>
            <li><b>Expression Evaluation:</b> Used in evaluating postfix expressions in expression trees.</li>
            <li><b>Data Serialization:</b> Save or reconstruct trees for databases or configuration files.</li>
            <li><b>Algorithm Design:</b> Explore nodes recursively or implement cleanup tasks in hierarchical structures.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

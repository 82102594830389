import React from 'react';

const AlgorithmDescription = () => {
  return (
    <div className="description-card shadow-lg p-3">
      <h3 className="section-title">Algorithm Description</h3>
      <div id="algorithmDescription">
      This program demonstrates the use of the <b>long data type</b>, which is designed to handle 
      very large integer values.
      </div>
      <h3 className="section-title mt-4">Real-Life Use Cases</h3>
      <div id="realLifeUse">
        <ul>
            <li><b>Population Tracking:</b> Storing and managing the population count of countries or the world, which can exceed the range of smaller integer types.</li>
            <li><b>Astronomical Calculations:</b> Representing large distances, such as the number of kilometers between celestial bodies in space.</li>
            <li><b>Financial Systems:</b> Handling large monetary values or transaction IDs in banking and e-commerce applications.</li>
        </ul>
      </div>
    </div>
  );
};

export default AlgorithmDescription;

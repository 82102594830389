import { useState, useEffect, useRef } from 'react';
import defaultExplanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useBasicCode = (speakingLang, translatedExplanations, language, a, b, breakpoints) => {
  const [explanations, setExplanations] = useState(defaultExplanations);
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const breakpointsRef = useRef(breakpoints);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const { speak, setIsMuted } = useSpeechSynthesis(isPausedRef, speakingLang);
  const [isMuted, setLocalIsMuted] = useState(false);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const isMutedRef = useRef(isMuted);

  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  useEffect(() => {
    breakpointsRef.current = breakpoints;
  }, [breakpoints]);

  useEffect(() => {
    if (translatedExplanations && language) {
      setExplanations((prevExplanations) => ({
        ...prevExplanations,
        [language]: Object.keys(prevExplanations[language] || {}).reduce((acc, key, index) => {
          acc[key] = translatedExplanations[index] || prevExplanations[language][key];
          return acc;
        }, {}),
      }));
    } else {
      setExplanations(defaultExplanations);
    }
  }, [translatedExplanations, language]);

  const updateVariables = (newA, newB) => {
    resetState();

     // Dynamically update the code samples
     const updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
      line
        .replace(/a = \d+/, `a = ${newA}`) // Replace variable 'a'
        .replace(/b = \d+/, `b = ${newB}`) // Replace variable 'b'
    );
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setHighlightedVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (lineNumber = null) => {
    // Check if the current line matches a breakpoint
    if (lineNumber !== null && breakpointsRef.current.includes(lineNumber)) {
      setIsPaused(true); // Pause execution
      isPausedRef.current = true;

      // Wait until `isPaused` is set to false
      await new Promise((resolve) => {
          const interval = setInterval(() => {
              if (!isPausedRef.current) {
                  clearInterval(interval);
                  resolve();
              }
          }, 100);
      });

      return;
    }

    // Handle regular sleep with pause checks
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runBasicCodePython = async () => {
    await highlightLine(3);
    setVariables((vars) => ({ ...vars, a: { variable_name: 'a', value: a } }));
    setHighlightedVariables(['a']);
    await customSleep(3);
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, b: { variable_name: 'b', value: b } }));
    setHighlightedVariables(['b']);
    await customSleep(4);
    setHighlightedVariables([]);

    await highlightLine(6);
    await logMessage(`a = ${a}, b = ${b}`);
    await customSleep(6);

    await focusEndLine(7);
    setIsRunning(false);
  };

  const runBasicCodeJava = async () => {
    await highlightLine(4);
    setVariables((vars) => ({ ...vars, a: { variable_name: 'a', value: a } }));
    setHighlightedVariables(['a']);
    await customSleep(4);
    setHighlightedVariables([]);

    await highlightLine(5);
    setVariables((vars) => ({ ...vars, b: { variable_name: 'b', value: b } }));
    setHighlightedVariables(['b']);
    await customSleep(5);
    setHighlightedVariables([]);

    await highlightLine(7);
    await logMessage(`a = ${a}, b = ${b}`);
    await customSleep(7);

    await focusEndLine(9);
    setIsRunning(false);
  };

  const runBasicCodeC = async () => {
    await highlightLine(5);
    setVariables((vars) => ({ ...vars, a: { variable_name: 'a', value: a } }));
    setHighlightedVariables(['a']);
    await customSleep(5);
    setHighlightedVariables([]);

    await highlightLine(6);
    setVariables((vars) => ({ ...vars, b: { variable_name: 'b', value: b } }));
    setHighlightedVariables(['b']);
    await customSleep(6);
    setHighlightedVariables([]);

    await highlightLine(8);
    await logMessage(`a = ${a}, b = ${b}`);
    await customSleep(8);

    await highlightLine(9);
    await customSleep(9);

    await focusEndLine(10);
    setIsRunning(false);
  };

  const runBasicCodeCpp = async () => {
    await highlightLine(5);
    setVariables((vars) => ({ ...vars, a: { variable_name: 'a', value: a } }));
    setHighlightedVariables(['a']);
    await customSleep(5);
    setHighlightedVariables([]);

    await highlightLine(6);
    setVariables((vars) => ({ ...vars, b: { variable_name: 'b', value: b } }));
    setHighlightedVariables(['b']);
    await customSleep(6);
    setHighlightedVariables([]);

    await highlightLine(8);
    await logMessage(`a = ${a}, b = ${b}`);
    await customSleep(8);

    await highlightLine(9);
    await customSleep(9);

    await focusEndLine(14);
    setIsRunning(false);
  };

  const runBasicCode = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    if (language === 'Python') {
      await runBasicCodePython();
    } else if (language === 'Java') {
      await runBasicCodeJava();
    } else if (language === 'C') {
      await runBasicCodeC();
    } else if (language === 'C++') {
      await runBasicCodeCpp();
    }

    setIsRunning(false);
  };

  return {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    runBasicCode
  };
};
